<template>
  <div>

    <div class="w-full items-start justify-between flex flex-row mt-3">
      <div class="w-full flex flex-col items-start justify-start gap-8">

        <div class="w-full flex flex-row items-center justify-between">

          <div class="w-10/12">
            <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer" @click="$router.go(-1)">Ga terug</span>
            <h1 class="text-3xl font-semibold">{{ total }} Gebruikers</h1>
            <div class="text-sm text-gray-600">We aggregeren gebruikers op basis van emailadres. <br/> Daarom kan het voorkomen dat je geen 25 resultaten krijgt.</div>
          </div>
          <div class="ml-auto w-4/12 flex flex-row">
            <input type="text" @keyup="find" class="w-9/12 border-2 border-gray-200 rounded-lg px-4 py-2 text-sm mx-5" placeholder="Zoek een bepaalde gebruiker..." v-model="q">
          </div>
          <button @click="downloadCSV()" class="text-white py-2 px-4 rounded flex-2 block" style="background-color: rgb(32,23,71); width: 25%;">Download CSV</button>

        </div>

        <div class="fixed z-10 inset-0 overflow-y-auto" v-if="popup">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity cursor-pointer" @click="closePopup()">
              <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">Add new restaurant</h3>

                    <div class="mt-2">
                      <p v-if="object._id" class="text-sm leading-5 text-gray-500">You can use this form to update an
                        existing record in the database.</p>
                      <p v-else class="text-sm leading-5 text-gray-500">You can use this form to insert a new record into
                        the database.</p>

                    </div>

                    <div class="mt-5">
                      <div>
                        <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Name</p>

                        <!-- if text -->
                        <input type="text" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%;" v-model="object.name">

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                  <button type="button" @click="create()" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">Create restaurant</button>
                </span>

                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                  <button @click="popup=false;" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full flex flex-col items-stretch justify-start">
          <!--transaction-->
          <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="overflow-hidden border border-gray-200 sm:rounded-lg shadow-md mb-8">
                  <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                    <div class="flex-1 flex justify-between sm:hidden">
                      <a @click="setPage(page - 1)" v-if="page > 0" href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                        Previous
                      </a>
                      <a @click="setPage(page + 1)" href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                        Next
                      </a>
                    </div>
                    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <div>
                        <p class="text-sm text-gray-700">
                          van
                          <span class="font-medium">{{start}}</span>
                          tot
                          <span class="font-medium">{{start + pageSize}}</span>
                          van de
                          <span class="font-medium">{{total}}</span>
                          resultaten getoond
                        </p>
                      </div>
                      <div>
                        <nav class="relative z-0 inline-flex shadow-sm -space-x-px" aria-label="Pagination">
                          <a @click="setPage(0)" v-if="page > 0" class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                            <span class="sr-only">First</span>
                            <!-- Heroicon name: chevron-double-left -->
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                            </svg>
                          </a>
                          <span class="cursor-pointer" v-for="n in totalPages" :key="n" @click="setPage(n-1)" :class="page === n-1 ? 'relative inline-flex items-center px-4 py-2 border border-gray-300 bg-indigo-200 text-sm font-medium text-gray-700' : 'hidden md:inline-flex relative items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50'">
                            {{n}}
                          </span>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-100">
                      <tr>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                          Wie
                        </th>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                          Plekjes
                        </th>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                          Matches
                        </th>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                          Options
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <!--LOOP THIS-->
                      <tr v-for="plekje in data" v-bind:key="plekje._id">
                        <td class="px-6 py-4 whitespace-no-wrap">
                          <div class="flex items-center">
                            <div class="ml-4">
                              <div class="text-sm leading-5 font-medium text-gray-900">
                                {{plekje.firstname}} {{plekje.lastname}}
                              </div>
                              <div class="text-sm leading-5 text-gray-500">
                                {{plekje.email || plekje.owner}}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 capitalize">
                          {{plekje.plekjes.length}}
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 capitalize">
                          {{plekje.matches.length}}
                        </td>

                        <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 capitalize">

                          <router-link :to="'/gebruikers/'+(plekje.email || plekje.owner)+'?firstname='+plekje.firstname+'&lastname='+plekje.lastname" class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded">
                            Bekijken
                          </router-link>
                        </td>

                      </tr>

                    </tbody>
                  </table>
                  <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                    <div class="flex-1 flex justify-between sm:hidden">
                      <a @click="setPage(page - 1)" v-if="page > 0" href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                        Previous
                      </a>
                      <a @click="setPage(page + 1)" href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                        Next
                      </a>
                    </div>
                    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <div>
                        <p class="text-sm text-gray-700">
                          van
                          <span class="font-medium">{{start}}</span>
                          tot
                          <span class="font-medium">{{start + pageSize}}</span>
                          van de
                          <span class="font-medium">{{total}}</span>
                          resultaten getoond
                        </p>
                      </div>
                      <div>
                        <nav class="relative z-0 inline-flex shadow-sm -space-x-px" aria-label="Pagination">
                          <a @click="setPage(0)" v-if="page > 0" class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                            <span class="sr-only">First</span>
                            <!-- Heroicon name: chevron-double-left -->
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                            </svg>
                          </a>
                          <span class="cursor-pointer" v-for="n in totalPages" :key="n" @click="setPage(n-1)" :class="page === n-1 ? 'relative inline-flex items-center px-4 py-2 border border-gray-300 bg-indigo-200 text-sm font-medium text-gray-700' : 'hidden md:inline-flex relative items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50'">
                            {{n}}
                          </span>
                        </nav>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import API from '@/helpers/api'
import Keep from '@/helpers/keep'

import env from "@/../env.js";
import Cookies from "@/helpers/cookies";

const axios = require("axios");

export default {
  name: "home",
  data: function() {
    return {
      data: [],
      popup: false,
      object: {},
      total: 0,
      totalPages: 0,
      q: "",
      page: 0,
      start: 0,
      pageSize: 25,
      searchTimeout: false,
    };
  },
  watch:{
    page: function(now){ Keep.set('usersPage', now) },
    q: function(now){ Keep.set('usersQ', now) },
    start: function(now){ Keep.set('usersStart', now) },
    pageSize: function(now){ Keep.set('usersPageSize', now) },
  },
  methods: {
    find: async function(){
      const _self = this;
      if(this.searchTimeout) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(async () => {
        await _self.load()
      }, 500);
    },
    load: async function() {
      const data = await API.get("/users?q=" + this.q + "&start=" + this.start + "&pageSize=" + this.pageSize);
      this.data = data.results;
      for (var i = 0; i < data.length; i++) {
        this.data[i].nr = this.start + i + 1;
      }
      this.total = data.metrics.count;
      this.totalPages = Math.ceil(data.metrics.count / this.pageSize);
      this.total = data.results.length;
    },
    setPage: function(page){
      this.page = page;
      this.start = this.pageSize * this.page;
      this.load();
    },
    async downloadCSV() {
      const { data } = await axios.get(`${env.apiURL}/users/csv`, {
        responseType: "blob",
        headers: {
          authorization: "Bearer " + Cookies.get("session")
        }
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "gebruikers.csv");
      document.body.appendChild(link);
      link.click();
    }
  },
  computed: {},
  async mounted() {

    this.page = Number(Keep.load('usersPage', 0));
    this.q = Keep.load('usersQ', '');
    this.start = Number(Keep.load('usersStart', 0));
    this.pageSize = Number(Keep.load('usersPageSize', 25));
    window.addEventListener('beforeunload', function() {
       Keep.reset('usersPage');
       Keep.reset('usersQ');
       Keep.reset('usersStart');
       Keep.reset('usersPageSize');
    });



    await this.load();
  }
};
</script>
