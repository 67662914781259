<template>
  <div class="w-full items-start justify-between flex flex-row mt-3">
    <div class="w-full flex flex-col items-stretch justify-start">
      <!--transaction-->
      <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer" @click="$router.go(-1)">Ga terug</span>
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden border border-gray-200 sm:rounded-lg shadow-md mb-8">
              <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <div>
                    <p class="text-sm text-gray-700">
                      Showing
                      <span class="font-medium">{{total}}</span>
                      results
                    </p>
                  </div>
                </div>
                <button class="text-white py-2 px-4 rounded float-right ml-5 cursor-pointer" style="background-color: rgb(32, 23, 71);" @click="openPopup()">Toevoegen</button>
              </div>


              <div class="fixed z-10 inset-0 overflow-y-auto" v-if="popup">
                <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <div class="fixed inset-0 transition-opacity cursor-pointer" @click="popup = false">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                  </div>
                  <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                  <div style="width: 40vw;" class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

                    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div class="">
                        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <h3 class="text-lg leading-6 font-medium text-gray-900">Voeg een nieuwe vraag toe</h3>

                          <div class="mt-5">
                            <div>
                              <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Vraag</p>
                              <textarea rows="2" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%;" v-model="object.question"></textarea>
                            </div>

                            <div>
                              <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Antwoord</p>
                              <textarea rows="2" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%;" v-model="object.answer"></textarea>
                            </div>

                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Type</label>
                            <select class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" v-model="object.category">
                              <option value="algemeen">Algemeen</option>
                              <option value="praktisch">Praktisch</option>
                            </select>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                        <button type="button" @click="create()" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">Voeg vraag toe</button>
                      </span>

                      <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                        <button @click="popup=false;" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                          Cancel
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>


              <table class="min-w-full divide-y divide-gray-200 table-fixed">
                <thead class="bg-gray-100">
                  <tr>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                      Status
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                      Category
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                      Vraag
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                      Opties
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">

                  <!--LOOP THIS-->
                  <tr v-for="question in data" v-bind:key="question._id">
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="flex items-center">
                        <div class="">
                          <div class="text-sm leading-5 font-medium text-gray-900">

                            <div class="bg-green-500 px-2 py-1 rounded text-xs" v-if="question.active">Actief</div>
                            <div class="bg-yellow-500 px-2 py-1 rounded text-xs" v-else>Gepauzeerd</div>

                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="flex items-center">
                        <div class="">
                          <div class="text-sm leading-5 font-medium text-gray-900">
                            {{question.category}}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="flex items-center">
                        <div class="">
                          <div class="text-sm leading-5 font-medium text-gray-900">
                            {{question.question}}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td class="px-4 py-4 whitespace-no-wrap flex flex-row items-end justify-center">

                      <router-link :to="'/questions/'+question._id" class="bg-blue-500 hover:bg-blue-700 text-white py-1 px-3 rounded">
                        Bekijken
                      </router-link>

                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/helpers/api'

export default {
  name: "Texts",
  data: function() {
    return {
      data: [],
      total: 0,
      popup: false,
      uploaded: false,
      object: {
        category: 'algemeen',
      },
    };
  },
  methods: {
    getData: async function() {
      const data = await API.get('/questions');
      this.data = data.data;
    },
    openPopup: function() {
      this.popup = true;
    },
    create: async function() {
      const q = await API.post('/questions', this.object);
      this.data.push(q);
      this.popup = false;
      this.object = {category: 'algemeen'}

      //this.$router.push({ path: '/questions/'+data._id})
    }
  },
  async mounted() {
    await this.getData();
  }
};
</script>

<style scoped>
</style>
