import Vue from 'vue';
import App from './App.vue';

Vue.config.productionTip = false;

import './assets/styles/style.css';
import router from './router'

import VueTailwind from 'vue-tailwind'
import TDatepicker from 'vue-tailwind/dist/t-datepicker'
import Dutch from 'vue-tailwind/dist/l10n/nl'

const settings = {
  // ...,
  't-datepicker': {
    component: TDatepicker,
    props: {
      // classes: {...},
      // fixedClasses: {...},
      locale: Dutch,
    }
  }
}

Vue.use(VueTailwind, settings)


import moment from 'moment'
Vue.prototype.moment = moment

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
