<template>
  <nav class="overflow-auto">
    <router-link to="/" class="group h-32 w-full flex flex-row items-center justify-between p-6 border-b border-gray-300 duration-150 text-gray-500">
      <div class="flex flex-col flex-shrink-0 flex-grow-0 w-10/12 ">
        <h3 class="font-semibold text-2xl truncate text-black">{{project.name}}</h3>
        <h5 class="text-gray-500 font-semibold text-sm lowercase capitalize">{{ user.firstname }} {{user.lastname}}</h5>
       </div>
    </router-link>
    <div class="text-gray-500">
      <router-link to="/" class="router-link mt-2">
        <div class="wrapper transform transition duration-150 flex flex-row items-center justify-start w-full">
          <div class="mr-4">
            <svg class="w-5 h-5" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g fill="none"><path d="M0,0h24v24h-24Z"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4,8.6v12.4h16v-12.4"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M-32,-344l10,-7l10,7" transform="matrix(1,0,0,1,34,354)"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15,21v-6c0,-1.105 -0.895,-2 -2,-2h-2c-1.105,0 -2,0.895 -2,2v6"></path></g></svg>
          </div>
          <p class="">Overzicht</p>
        </div>
      </router-link>

      <router-link to="/plekjes" class="router-link">
        <div class="wrapper transform transition duration-150 flex flex-row items-center justify-start w-full">
          <div class="mr-4">

<svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"></path></svg>
          </div>
        <p>Plekjes</p>
        </div>
      </router-link>


      <router-link to="/matches" class="router-link">
        <div class="wrapper transform transition duration-150 flex flex-row items-center justify-start w-full">
          <div class="mr-3 ">
<svg version="1.1" class="w-6 h-6" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round"><circle cx="8" cy="8.51" r="3.49"></circle><circle cx="17" cy="9.5" r="2.5"></circle><path d="M2 20v-1.017c0-2.2 1.783-3.983 3.983-3.983h4.034c2.2 0 3.983 1.783 3.983 3.983v1.017"></path><path d="M17 15h1.102c2.2 0 3.983 1.783 3.983 3.983v1.017"></path></g><rect width="24" height="24" fill="none"></rect></svg>
          </div>
        <p>Matches</p>
        </div>
      </router-link>


      <router-link to="/gebruikers" class="router-link">
              <div class="wrapper transform transition duration-150 flex flex-row items-center justify-start w-full">
                <div class="mr-4">

<svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>                </div>
              <p>Alle gebruikers</p>
              </div>
            </router-link>
        <router-link to="/admins" class="router-link">
            <div class="wrapper transform transition duration-150 flex flex-row items-center justify-start w-full">
                <div class="mr-4">

                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>                </div>
                <p>Alle admins</p>
            </div>
        </router-link>

        <router-link to="/nieuwsbrief" class="router-link">
            <div class="wrapper transform transition duration-150 flex flex-row items-center justify-start w-full">
                <div class="mr-3 ">
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
                </div>
                <p>Nieuwsbrief</p>
            </div>
        </router-link>
        <router-link to="/stats" class="router-link">
            <div class="wrapper transform transition duration-150 flex flex-row items-center justify-start w-full">
                <div class="mr-3 ">
                    <svg version="1.1" class="w-5 h-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round"><path d="M21 7v10c0 2.209-1.791 4-4 4h-10c-2.209 0-4-1.791-4-4v-10c0-2.209 1.791-4 4-4h10c2.209 0 4 1.791 4 4Z"></path><path d="M10.333 16.167v-3.333c0-.461-.373-.834-.833-.834h-1.667c-.46 0-.833.373-.833.833v3.227"></path><path d="M13.667 16.167v-7.5c0-.46-.373-.833-.833-.833h-1.667c-.46 0-.833.373-.833.833v7.5"></path><path d="M17 16.061v-5.311c0-.46-.373-.833-.833-.833h-1.667c-.46 0-.833.373-.833.833v5.417"></path><line x1="17" x2="7" y1="16.17" y2="16.17"></line></g><path fill="none" d="M0 0h24v24h-24v-24Z"></path></svg>
                </div>
                <p>Statistieken</p>
            </div>
        </router-link>


        <router-link to="/nieuwsbrief-genereuzen" class="router-link">
            <div class="wrapper transform transition duration-150 flex flex-row items-center justify-start w-full">
                <div class="mr-3 ">
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
                </div>
                <p>Nieuwsbrief Genereuzen</p>
            </div>
        </router-link>


        <router-link v-if="project.hasFestivals" to="/festivals" class="router-link">
            <div class="wrapper transform transition duration-150 flex flex-row items-center justify-start w-full">
                <div class="mr-3 ">

<svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="5"></circle><line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line></svg>

                </div>
                <p>Festivals</p>
            </div>
        </router-link>


        <router-link  to="/questions" class="router-link">
            <div class="wrapper transform transition duration-150 flex flex-row items-center justify-start w-full">
                <div class="mr-3 ">

<svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="5"></circle><line x1="12" y1="1" x2="12" y2="3"></line><line x1="12" y1="21" x2="12" y2="23"></line><line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line><line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line><line x1="1" y1="12" x2="3" y2="12"></line><line x1="21" y1="12" x2="23" y2="12"></line><line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line><line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line></svg>

                </div>
                <p>FAQ</p>
            </div>
        </router-link>


        <router-link to="/texts" class="router-link">
            <div class="wrapper transform transition duration-150 flex flex-row items-center justify-start w-full">
                <div class="mr-3 ">
                  <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="4 7 4 4 20 4 20 7"></polyline><line x1="9" y1="20" x2="15" y2="20"></line><line x1="12" y1="4" x2="12" y2="20"></line></svg>
                </div>
                <p>Teksten</p>
            </div>
        </router-link>


        <router-link to="/settings" class="router-link">
            <div class="wrapper transform transition duration-150 flex flex-row items-center justify-start w-full">
                <div class="mr-4">


                  <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="4" y1="21" x2="4" y2="14"></line><line x1="4" y1="10" x2="4" y2="3"></line><line x1="12" y1="21" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="3"></line><line x1="20" y1="21" x2="20" y2="16"></line><line x1="20" y1="12" x2="20" y2="3"></line><line x1="1" y1="14" x2="7" y2="14"></line><line x1="9" y1="8" x2="15" y2="8"></line><line x1="17" y1="16" x2="23" y2="16"></line></svg>
                </div>

                <p>Settings</p>
            </div>
        </router-link>

      <div tag="a" class="router-link cursor-pointer" @click="logout()">
        <div class="wrapper transform transition duration-150 flex flex-row items-center justify-start w-full">
          <div class="mr-4">

<svg version="1.1" class="w-5 h-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><!--Generated by IJSVG (https://github.com/iconjar/IJSVG)--><g fill="none"><path d="M0,0h24v24h-24Z"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18.364,5.63604c3.51472,3.51472 3.51472,9.2132 0,12.7279c-3.51472,3.51472 -9.2132,3.51472 -12.7279,0c-3.51472,-3.51472 -3.51472,-9.2132 -1.77636e-15,-12.7279c3.51472,-3.51472 9.2132,-3.51472 12.7279,-1.77636e-15"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12,7v5"></path><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.534,8.466c1.952,1.952 1.952,5.117 0,7.069c-1.952,1.952 -5.117,1.952 -7.069,0c-1.952,-1.952 -1.952,-5.117 0,-7.069"></path></g></svg>

          </div>
        <p>Afmelden</p>
        </div>
      </div>


    </div>
  </nav>
</template>

<script>
import auth from '@/helpers/auth';
import API from '@/helpers/api'

export default {
  name: 'navigtion',
  props: ['user'],
  data(){
    return{
      opentab: '',
        project: {}
    }
  },
  methods: {
    openTab(tab){
      if(tab === this.opentab){
        this.opentab = ""
      } else{
        this.opentab = tab;
      }
    },
    logout: async function(){
      await auth.logout();
      document.location.reload();
    }
  },
  async created() {
    this.project = await API.get("/settings");
    document.title = (this.project.name || '') + ' Admin dashboard';
  }
}
</script>

<style>

</style>
