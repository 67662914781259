<template>
  <div>


    <div class="w-full items-start justify-between flex flex-row mt-3">
        <div class="w-full flex flex-col items-start justify-start gap-8">


          <div class="w-full flex flex-row items-center justify-between">

          <div class="w-10/12">
            <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer" @click="$router.go(-1)">Ga terug</span>
            <h1 class="text-3xl font-semibold">{{ total }} Nieuwsbrief subscribers</h1>
          </div>
            <button @click="downloadCSV()" class="text-white py-2 px-4 rounded flex-2 block" style="background-color: rgb(32,23,71); width: 25%;">Download CSV</button>
          </div>

        <div class="fixed z-10 inset-0 overflow-y-auto" v-if="popup">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity cursor-pointer" @click="closePopup()">
              <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div
              class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
              role="dialog" aria-modal="true" aria-labelledby="modal-headline">

              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">Add new restaurant</h3>

                    <div class="mt-2">
                      <p v-if="object._id" class="text-sm leading-5 text-gray-500">You can use this form to update an
                        existing record in the database.</p>
                      <p v-else class="text-sm leading-5 text-gray-500">You can use this form to insert a new record into
                        the database.</p>

                    </div>

                    <div class="mt-5">
                      <div>
                        <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Name</p>

                        <!-- if text -->
                        <input type="text" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%;" v-model="object.name">


                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                  <button type="button" @click="create()"
                    class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">Create restaurant</button>
                </span>


                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                  <button @click="popup=false;" type="button"
                    class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>



          <div class="w-full flex flex-col items-stretch justify-start">
              <!--transaction-->
              <div class="flex flex-col">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="overflow-hidden border border-gray-200 sm:rounded-lg shadow-md mb-8">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-100">
                        <tr>
                          <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                            Wie
                          </th>
                          <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                            Wanneer ingeschreven
                          </th>
                          <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                            MailChimp (doet nog niets)
                          </th>
                                                 </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">

                      <!--LOOP THIS-->
                        <tr v-for="user in data" v-bind:key="user._id">
                          <td class="px-6 py-4 whitespace-no-wrap">
                            <div class="flex items-center">
                              <div class="ml-4">
                                <div class="text-sm leading-5 font-medium text-gray-900">
                                  {{user.firstname}} {{user.lastname}}
                                </div>
                                <div class="text-sm leading-5 text-gray-500">
                                  {{user.email}}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="px-6 py-4 whitespace-no-wrap">
                            <div class="flex items-center">
                              <div class="ml-4">
                                <div class="text-sm leading-5 font-medium text-gray-900">
                                  {{moment(user.createdAt).fromNow()}}
                                </div>
                                <div class="text-sm leading-5 text-gray-500">
                                  {{moment(user.createdAt).format('DD/MM/YYYY h:mm')}}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="px-6 py-4 whitespace-no-wrap">
                            <div class="flex items-center">
                              <div class="ml-4">
                                <input type="checkbox">
                              </div>
                            </div>
                          </td>
                          <td class="px-6 py-4 whitespace-no-wrap flex flex-row items-end justify-center gap-4 ">
                            
                            <router-link :to="'/blijfopdehoogte/'+user._id" class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded">
                              Bekijk gebruiker
                            </router-link>
                            <div class="w-8 text-red-500" @click="deleteRecord(user)">
                              <svg  xmlns="http://www.w3.org/2000/svg"    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x "><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>  
                            </div>
                          </td>
                          
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


    </div>


  </div>
</template>

<script>
import env from '@/../env.js';
import Cookies from '@/helpers/cookies'

const axios = require('axios');

export default {
  name: 'home',
  data: function () {
    return {
      data: [],
      popup: false,
      object: {},
      total: 0,
      category: '',
      q: '',

      page: 0,
      start: 0,
      pageSize: 25,
    }
  },
  methods: {
    async deleteRecord(user){
      const result = confirm(`Ben je zeker dat je ${user.email} wilt verwijderen`);
      if(result){
        await axios.delete(env.apiURL + '/newsletter/'+user._id)
        this.load();
      }
      
    },
    load: function () {
      const _self = this;
      axios.get(env.apiURL + '/newsletter/nieuwsbrieven', {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('session')
        }
      }).then(function (res) {
        console.log('Data:', res.data.results)

       
        _self.data = res.data.results.filter((currentValue)=>{ return /\S+@\S+\.\S+/.test(currentValue.email)});
        _self.total = _self.data.length;

        _self.data = _self.data.sort((a,b)=> {
          if(a.createdAt < b.createdAt) return 1;
          else if(a.createdAt > b.createdAt) return -1;
          else return 0
        });
      });
    },
    async downloadCSV() {
      const {data} = await axios.get(`${env.apiURL}/newsletter/nieuwsbrieven/csv`, {
        responseType: 'blob',
        headers: {
          'authorization': 'Bearer ' + Cookies.get('session'),
        }
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'nieuwsbrieven.csv');
      document.body.appendChild(link);
      link.click();
    },
  },
  computed: {
  },
  mounted() {
    this.load();
  }
}
</script>
