<template>
    <div>
    <div class="w-full flex flex-row items-center justify-between">
        <div class="w-8/12">
                <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer"
                      @click="goback()">Ga terug</span>
            <h1 class="text-3xl font-semibold">Test omgeving</h1>

        </div>
        <div class="w-4/12">
            <button @click="save()" class="text-white py-2 px-4 rounded float-right ml-5 cursor-pointer" style="background-color: rgb(32, 23, 71);">Opslaan</button>
        </div>
    </div>
    <div class="w-full items-start justify-start flex flex-row">
        <div class="w-full flex flex-col border-b border-gray-300 pb-10">
            <div class="w-full mt-5 flex flex-row">
                <!--FORM-->
                <div class="w-6/12 pt-5 pr-10">

                    <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center">1. Basis informatie</h3>
                    <div class="flex flex-row">
                        <div class="w-6/12 pr-5">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Project naam</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="Circuitsortie" v-model="project.name" @change="change()">
                                <!--"-->
                            </div>
                        </div>
                        <div class="w-6/12">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Project homepage</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="circuitsortie.be" v-model="project.dashboardURL" @change="change()">
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="w-6/12 pr-5">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Main color</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="#0000" v-model="project.color" @change="change()">
                                <!--"-->
                            </div>
                        </div>
                        <div class="w-6/12">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Admin login banner</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="https://admin.circuitsortie.be/img/login-bg.65ba375d.jpg" v-model="project.banner" @change="change()">
                            </div>
                        </div>
                    </div>




                  <h3 class="mt-10 text-2xl font-semibold leading-5 flex flex-row items-center">2. Projecten</h3>
                  <div class="flex flex-row">
                      <div class="w-6/12 pr-5">
                          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Festivals?</label>
                          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                              <select class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                     v-model="project.hasFestivals" @change="change()">
                                     <option :value="true">True</option>
                                     <option :value="false">False</option>
                              </select>
                              <!--"-->
                          </div>
                      </div>

                  </div>


                    <h3 class="mt-10 text-2xl font-semibold leading-5 flex flex-row items-center">3. Email settings</h3>
                    <div class="flex flex-row">
                        <div class="w-6/12 pr-5">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Emails komen van</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="circuit@circuitsortie.be" v-model="project.fromEmail" @change="change()">
                                <!--"-->
                            </div>
                        </div>
                        <div class="w-6/12">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Email in CC (kan niet hetzelfde zijn als van)</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="cc@circuitsortie.be" v-model="project.ccEmail" @change="change()">
                            </div>
                        </div>
                    </div>


                    <h3 class="mt-10 text-2xl font-semibold leading-5 flex flex-row items-center">4. Email templates</h3>
                    <div class="flex flex-row">
                      <div class="w-6/12 pr-5">
                          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Template voor emailLoginLink</label>
                          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                              <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                     placeholder="d-xxxxxx" v-model="project.emailLoginLink" @change="change()">
                              <!--"-->
                          </div>
                      </div>
                        <div class="w-6/12">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Template voor emailPlekjeAccepted</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="d-xxxxxx" v-model="project.emailPlekjeAccepted" @change="change()">
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="w-6/12 pr-5">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Template voor emailSamenOpStapPlekje Plekje</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="d-xxxxxx" v-model="project.emailSamenOpStapPlekje" @change="change()">
                                <!--"-->
                            </div>
                        </div>
                        <div class="w-6/12">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Template voor emailSamenOpStapMatch Match</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="d-xxxxxx" v-model="project.emailSamenOpStapMatch" @change="change()">
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="w-6/12 pr-5">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Template voor emailOngelezenBerichtenChat</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="d-xxxxxx" v-model="project.emailOngelezenBerichtenChat" @change="change()">
                            </div>
                        </div>
                        <div class="w-6/12">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Template voor emailNewPlekjeToegevoegd (enkel admin)</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="d-xxxxxx" v-model="project.emailNewPlekjeToegevoegd" @change="change()">
                                <!--"-->
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="w-6/12 pr-5">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Template voor emailNewAdminAccount (enkel admin)</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="d-xxxxxx" v-model="project.emailNewAdminAccount" @change="change()">
                                <!--"-->
                            </div>
                        </div>
                        <div class="w-6/12">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Template voor emailNewContactRequest (enkel admin)</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="d-xxxxxx" v-model="project.emailNewContactRequest" @change="change()">
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-row">
                        <div class="w-6/12 pr-5">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Template voor emailNewProposal (enkel admin)</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="d-xxxxxx" v-model="project.emailNewProposal" @change="change()">
                                <!--"-->
                            </div>
                        </div>
                        <div class="w-6/12">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Template voor emailFeedback (enkel admin)</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="d-xxxxxx" v-model="project.emailFeedback" @change="change()">
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-row">

                        <div class="w-6/12">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Template voor emailPlekjeVerwijderen (enkel admin)</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="d-xxxxxx" v-model="project.emailPlekjeVerwijderen" @change="change()">
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
  import env from '@/../env.js';
  import Cookies from '@/helpers/cookies'

  const axios = require('axios');
  export default {
      name: "project",
      data: function () {
          return {
              project: {},
              env,
              changed: false,
          }
      },
      methods: {
          load: function () {
              const _self = this;
              axios.get(env.apiURL + '/settings', {
                  headers: {
                      'Authorization': 'Bearer ' + Cookies.get('session')
                  }
              }).then(function (res) {
                  console.log('Data:', res.data);
                  _self.project = res.data;
              });
          },
          save: async function () {
              const _self = this;
              axios.put(env.apiURL + '/settings', this.project, {
                  headers: {
                      'Authorization': 'Bearer ' + Cookies.get('session')
                  }
              }).then(function () {
                  _self.changed = false;
                  _self.changesButNotSaved = false;
                  alert('Saved');
              });
          },
          goback: function () {
              const _self = this;
              if (!_self.changed) {
                  this.$router.go(-1);
              } else {
                  const c = confirm('Weet u zeker dat u de pagina wilt verlaten? Uw wijzigingen zijn nog niet opgeslagen');
                  if (c === true) {
                      this.$router.go(-1);
                  }
              }
          },
          change: function () {
              const _self = this;
              _self.changed = true;
          },
      },
      mounted() {
          this.load();
      }
  }
</script>

<style scoped>

</style>
