<template>
    <div>
    <div class="w-full flex flex-row items-center justify-between">
        <div class="w-8/12">
                <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer"
                      @click="goback()">Ga terug</span>
            <h1 class="text-3xl font-semibold">{{ object.firstname }} {{object.lastname}}</h1>

        </div>
        <div class="w-4/12">
            <button @click="save()" class="text-white py-2 px-4 rounded float-right ml-5 cursor-pointer" style="background-color: rgb(32, 23, 71);">Opslaan</button>
        </div>
        <div class="mt-2 mb-2">
            <button @click="deleteAdmin(object._id)" class="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded float-right ml-5 cursor-pointer">
                Verwijder
            </button>
        </div>
    </div>
    <div class="w-full items-start justify-start flex flex-row">
        <div class="w-full flex flex-col border-b border-gray-300 pb-10">
            <div class="w-full mt-5 flex flex-row">
                <!--FORM-->
                <div class="w-6/12 pt-5 pr-10">

                    <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center">1. Basis informatie</h3>
                    <div class="flex flex-row">
                        <div class="w-6/12 pr-5">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Voornaam</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="Voornaam" v-model="object.firstname" @change="change()">
                                <!--"-->
                            </div>
                        </div>
                        <div class="w-6/12">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Achternaam</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="Achternaam" v-model="object.lastname" @change="change()">
                            </div>
                        </div>
                    </div>


                    <div class="flex flex-row">
                        <div class="w-6/12 pr-5">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Email</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="Email" v-model="object.email" @change="change()">
                            </div>
                        </div>
                        <div class="w-6/12">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Wachtwoord</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input type="password" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                       placeholder="Wachtwoord" v-model="object.newPassword" @change="change()">
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row">
                        <div class="w-6/12">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Geblokkeerd</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input type="checkbox" class="form-checkbox" v-model="object.blocked" @change="change()">
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row">
                        <div class="w-6/12">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Is Developer</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                <input type="checkbox" class="form-checkbox" v-model="object.isDeveloper" @change="change()">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    import env from '@/../env.js';
    import Cookies from '@/helpers/cookies'
    const bcrypt = require('bcryptjs');

    const axios = require('axios');
    export default {
        name: "admin",
        data: function () {
            return {
                object: {},
                env,
                changed: false,
            }
        },
        methods: {
            load: function () {
                const _self = this;
                axios.get(env.apiURL + '/admins/getadmin/' + _self.$route.params.id, {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('session')
                    }
                }).then(function (res) {
                    console.log('Data:', res.data);
                    _self.object = res.data;
                });
            },
            save: async function () {
                const _self = this;
                if (this.object.newPassword.length > 0) this.object.hash = await hashIt(this.object.newPassword);
                this.object.newPassword = '';
                axios.put(env.apiURL + '/admins/' + this.object._id, this.object, {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('session')
                    }
                }).then(function () {
                    _self.changed = false;
                    _self.changesButNotSaved = false;
                    alert('Saved');
                });
            },
            deleteAdmin: function(adminId){
                const _self = this;
                axios.delete(env.apiURL+'/admins/'+adminId, {
                    headers: {
                        'Authorization': 'Bearer '+Cookies.get('session')
                    }
                }).then(function () {
                    _self.$router.go(-1);
                });
            },
            goback: function () {
                const _self = this;
                if (!_self.changed) {
                    this.$router.go(-1);
                } else {
                    const c = confirm('Weet u zeker dat u de pagina wilt verlaten? Uw wijzigingen zijn nog niet opgeslagen');
                    if (c === true) {
                        this.$router.go(-1);
                    }
                }
            },
            change: function () {
                const _self = this;
                _self.changed = true;
            },
        },
        mounted() {
            this.load();
        }
    }
    const hashIt = password => new Promise((resolve) => {
        bcrypt.genSalt(3, (err, salt) => {
            bcrypt.hash(password, salt, (err, hash) => {
                resolve(hash);
            });
        });
    });
</script>

<style scoped>

</style>
