<template>
    <div class="w-full items-start justify-between flex flex-row mt-3">
        <div class="w-full flex flex-col items-stretch justify-start">
            <!--transaction-->
            <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer" @click="$router.go(-1)">Ga terug</span>
            <div class="flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="overflow-hidden border border-gray-200 sm:rounded-lg shadow-md mb-8">
                            <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                                <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                                    <h1 class="text-3xl font-semibold">{{ total }} Evenementen</h1>
                                </div>
                            </div>
                            <table class="min-w-full divide-y divide-gray-200 table-fixed">
                                <thead class="bg-gray-100">
                                <tr>
                                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500" >
                                        Naam
                                    </th>
                                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                                        Intro
                                    </th>
                                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500" style="width: 800px">
                                        Categorie
                                    </th>
                                </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">

                                <!--LOOP THIS-->
                                <tr v-for="project in data" v-bind:key="project._id">
                                    <td class="px-6 py-4 whitespace-no-wrap">
                                        <div class="flex items-center">
                                            <div class="ml-4">
                                                <div v-if="project.name.toString().length <= 30" class="text-sm leading-5 font-medium text-gray-900">
                                                    {{project.name}}
                                                </div>
                                                <div v-else class="text-sm leading-5 font-medium text-gray-900">
                                                    {{project.name.toString().substr(0, 30)}}...
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4"  style="width: 500px">
                                        <div class="flex items-center">
                                            <div class="ml-4">
                                                <div class="text-sm leading-5 font-medium text-gray-700">
                                                    {{project.intro}}
                                                </div>
                                            </div>
                                        </div>
                                    </td>


                                    <td class="px-6 py-4 whitespace-no-wrap">
                                        <div class="flex items-center">
                                            <div class="ml-4">
                                                <div class="text-sm leading-5 font-medium text-gray-900">
                                                    {{project.category}}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="px-4 py-4 whitespace-no-wrap flex flex-row items-end justify-center">

                                        <router-link :to="'/evenementen/'+project._id" class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded">
                                            Bekijken
                                        </router-link>

                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import env from '@/../env.js';
    import Cookies from '@/helpers/cookies'

    const axios = require('axios');
    export default {
        name: "events",
        data: function () {
            return {
                data: [],
                total: 0,
                env
            }
        },
        methods: {
            getEvents: function () {
                const _self = this;
                axios.get(env.apiURL + '/events', {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('session')
                    }
                }).then(function(res) {
                    _self.data = res.data.results;
                    console.log(_self.data);
                    _self.total = res.data.metrics.count;
                });
            }
        },
        mounted() {
            this.getEvents();
        }
    }
</script>

<style scoped>

</style>
