<template>
  <div class="w-full items-start justify-start flex flex-row">
    <div class="w-full flex flex-col pb-10">
      <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer" @click="$router.go(-1)">Ga terug</span>
      <h1 class="text-3xl font-semibold">{{object.question}}</h1>

      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Vraag</label>
      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
        <textarea rows="2" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Vraag" v-model="object.question"></textarea>
      </div>


      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Antwoord</label>
      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
        <textarea rows="2" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="e.g. Antwoord" v-model="object.answer"></textarea>
      </div>


      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Type</label>
      <select class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" v-model="object.category">
        <option value="algemeen">Algemeen</option>
        <option value="praktisch">Praktisch</option>
      </select>

      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Actief (tonen op de website)</label>
      <select class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" v-model="object.active">
        <option :value="true">Actief</option>
        <option :value="false">Niet actief (wordt niet getoond)</option>
      </select>

      <div class="flex flex-row mt-4">
        <button @click="save()" class="text-white py-2 rounded float-right cursor-pointer" style="background-color: rgb(32, 23, 71); width: 250px;">Opslaan</button>
        <button @click="del()" class="text-white py-2 px-4 rounded float-right ml-5 cursor-pointer" style="background-color: red; width: 250px;">Verwijder</button>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/helpers/api'

export default {
  name: "faq",
  data: function() {
    return {
      object: {},
      uploaded: false,
    };
  },
  methods: {
    load: async function() {
      this.object = await API.get("/questions/" + this.$route.params.id);
    },
    save: async function() {
      await API.put("/questions/" + this.object._id, this.object);
      alert("Opgeslagen");
      this.$router.push({ path: '/questions'})
    },
    del: async function() {
      if(confirm('Ben je zeker dat je deze vraag wilt verwijderen?')){
        await API.delete("/questions/" + this.object._id);
        this.$router.push({ path: '/questions'})
      }
    },
  },
  async mounted() {
    await this.load();
  }
};
</script>

<style scoped>
</style>
