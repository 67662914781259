/*

  Keep js is just a very simple JS library that keeps track of certain variables.
  If you go you in history, it try's to show/set your variables back.

*/

const set = (name, value) => {
  window.localStorage.setItem(name, value);
};

const get = (name) => {
  return window.localStorage.getItem(name);
};

const reset = (name) => {
  window.localStorage.removeItem(name);
};

const load = (name, ifNotDefined) => {
  const val = get(name);
  if(val && val !== null && val !== 'null' && typeof val !== 'undefined'){
    console.log(name, val)
    return val;
  } else {
    return ifNotDefined;
  }
}

export default {
  set,
  get,
  reset,
  load
}
