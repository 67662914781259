<template>
        <div class="w-full items-start justify-between flex flex-row mt-3">
            <div class="w-full flex flex-col items-start justify-start gap-8">


                <div class="w-full flex flex-row items-center justify-between">

                    <div class="w-10/12">
                        <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer" @click="$router.go(-1)">Ga terug</span>
                        <h1 class="text-3xl font-semibold">{{ total }} Admins</h1>
                    </div>
                    <button @click="openPopup()" class="text-white py-2 px-4 rounded flex-2 block ml-5 bg-green-500" style="width: 25%;">Admin Toevoegen</button>
                </div>

                <div class="fixed z-10 inset-0 overflow-y-auto" v-if="popup">
                    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div class="fixed inset-0 transition-opacity cursor-pointer" @click="closePopup()">
                            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                        <div
                                class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
                                role="dialog" aria-modal="true" aria-labelledby="modal-headline">

                            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div class="sm:flex sm:items-start">
                                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">Voeg admin toe</h3>

                                        <div class="mt-5">
                                            <div class="flex flex-row">
                                                <div class="w-6/12 pr-5">
                                                    <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Voornaam</label>
                                                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                                        <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                                               placeholder="Voornaam" v-model="object.firstname">
                                                        <!--"-->
                                                    </div>
                                                </div>
                                                <div class="w-6/12">
                                                    <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Achternaam</label>
                                                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                                        <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                                               placeholder="Achternaam" v-model="object.lastname">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex flex-row">
                                                <div class="w-6/12 pr-5">
                                                    <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">E-mailadres</label>
                                                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                                        <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                                               placeholder="Email" v-model="object.email">
                                                        <!--"-->
                                                    </div>
                                                </div>
                                                <div class="w-6/12">
                                                    <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Wachtwoord</label>
                                                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                                        <input type="password" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                                               placeholder="Wachtwoord" v-model="object.password">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                  <button type="button" @click="createAdmin()"
                          class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">Admin opslaan</button>
                </span>


                                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                  <button @click="popup=false;" type="button"
                          class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    Cancel
                  </button>
                </span>
                            </div>
                            <p v-if="error" class="absolute text-red-500 mt-5 font-medium text-sm outline-none">{{error}}</p>
                        </div>
                    </div>
                </div>



                <div class="w-full flex flex-col items-stretch justify-start">
                    <!--transaction-->
                    <div class="flex flex-col">
                        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div class="overflow-hidden border border-gray-200 sm:rounded-lg shadow-md mb-8">
                                    <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                                        <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                                            <div>
                                                <p class="text-sm text-gray-700">
                                                    Showing
                                                    <span class="font-medium">{{total}}</span>
                                                    results
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <table class="min-w-full divide-y divide-gray-200 table-fixed">
                                        <thead class="bg-gray-100">
                                        <tr>
                                            <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500" >
                                                Avatar
                                            </th>
                                            <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500" >
                                                Voornaam
                                            </th>
                                            <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                                                Achternaam
                                            </th>
                                            <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500" style="width: 800px">
                                                E-mail
                                            </th>
                                            <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500" style="width: 800px">
                                                Aangemaakt en geüpdatet op
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-200">

                                        <!--LOOP THIS-->
                                        <tr v-for="admin in data" v-bind:key="admin._id">
                                            <td class="px-6 py-4 whitespace-no-wrap">
                                                <div class="flex items-center">
                                                    <div>
                                                        <img :src="admin.avatar" alt="img plekje">
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="px-6 py-4 whitespace-no-wrap">
                                                <div class="flex items-center">
                                                    <div class="ml-4">
                                                        <div class="text-sm leading-5 font-medium text-gray-900">
                                                            {{admin.firstname}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="px-6 py-4"  style="width: 500px">
                                                <div class="flex items-center">
                                                    <div class="ml-4">
                                                        <div class="text-sm leading-5 font-medium text-gray-700">
                                                            {{admin.lastname}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>

                                            <td class="px-6 py-4 whitespace-no-wrap">
                                                <div class="flex items-center">
                                                    <div class="ml-4">
                                                        <div class="text-sm leading-5 font-medium text-gray-900">
                                                            {{admin.email}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="px-6 py-4 whitespace-no-wrap">
                                                <div class="flex items-center">
                                                    <div class="ml-4">
                                                        <div class="text-sm leading-5 font-medium text-gray-900">
                                                            {{formatDate(admin.createdAt)}}
                                                        </div>
                                                        <div class="text-sm leading-5 text-gray-500">
                                                            {{formatDate(admin.updatedAt)}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>

                                            <td class="px-4 py-4 whitespace-no-wrap flex flex-row items-end justify-center">

                                                <router-link :to="'/admins/'+admin._id" class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded">
                                                    Bekijken
                                                </router-link>

                                            </td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
</template>

<script>
    import env from '@/../env.js';
    import Cookies from '@/helpers/cookies'
    import moment from 'moment'
    const bcrypt = require('bcryptjs');

    const axios = require('axios');
    export default {
        name: "admins",
        data: function () {
            return {
                data: [],
                popup: false,
                object: {
                    email: "",
                    password: "",
                    firstname: "",
                    lastname: "",
                    hash: "",
                },
                total: 0,
                q: '',
                error: false,
                env,
            }
        },
        methods: {
            load: function () {
                const _self = this;
                axios.get(env.apiURL + '/admins/', {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('session')
                    }
                }).then(function (res) {
                    _self.data = res.data.results;
                    console.log('Data:', res.data);
                    _self.total = res.data.metrics.count;
                    console.log('Total:', res.data);
                });
            },
            createAdmin: async function() {
                const _self = this;
                if (!this.object.email || this.object.email.indexOf('@') == -1 || this.object.email.indexOf('.') == -1 || !this.object.password || !this.object.firstname || !this.object.lastname) {
                    this.error = 'Niet alle velden zijn correct ingevuld';
                } else {
                    this.object.hash = await hashIt(this.object.password);
                    this.object.password = '';
                    axios.post(env.apiURL + '/admins/signup', this.object, {
                        headers: {
                            'Authorization': 'Bearer ' + Cookies.get('session')
                        }
                    }).then(function (res) {
                        console.log(res);
                        _self.sendconfirmMail();
                        _self.error = 'Admin succesvol aangemaakt!';
                        _self.popup = false;
                        document.location.reload();
                    });
                }
            },
            sendconfirmMail: function() {
                console.log('send confirm mail');
                axios.post(env.apiURL + '/mails/accountcreated', this.object, {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('session')
                    }
                }).then(res => {
                    console.log(res);
                })
            },
            formatDate: function (date) {
                const newDate = new Date(date);
                return moment(newDate).format('DD/MM/YYYY HH:mm');
            },
            openPopup: function () {
                this.popup = true;
            },
        }, mounted() {
            console.log('GET ADMINS');
            this.load();
        }
    }
    const hashIt = password => new Promise((resolve) => {
        bcrypt.genSalt(3, (err, salt) => {
            bcrypt.hash(password, salt, (err, hash) => {
                resolve(hash);
            });
        });
    });
</script>

<style scoped>

</style>
