<template>
    <div class="w-full items-start justify-between flex flex-row mt-3">
        <div class="w-full flex flex-col items-start justify-start gap-8">
            <div class="w-full flex flex-row items-center justify-between">
                <div class="w-10/12">
                    <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer" @click="$router.go(-1)">Ga terug</span>
                    <h1 class="text-3xl font-semibold">Statistieken</h1>
                    <span class="w-6/12 text-gray-500">Dit is een eerste implementatie van ons analytics systeem. <br/> Feedback/andere widgets kan je altijd aanvragen bij Kjenta@webfaster.com.</span>
                </div>
            </div>
            <div>
                <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                    <div class="bg-white overflow-hidden shadow rounded-lg">
                        <div class="px-4 py-5 sm:p-6">
                            <dt class="text-sm font-medium text-gray-500 truncate">
                                Avg pageviews/uur
                            </dt>
                            <dd class="mt-1 text-3xl font-semibold text-gray-900">
                                {{data.avgHour}} views
                            </dd>
                        </div>
                    </div>

                    <div class="bg-white overflow-hidden shadow rounded-lg">
                        <div class="px-4 py-5 sm:p-6">
                            <dt class="text-sm font-medium text-gray-500 truncate">
                                Pageviews in laatste 24 uur
                            </dt>
                            <dd class="mt-1 text-3xl font-semibold text-gray-900">
                                {{data.total}} views
                            </dd>
                        </div>
                    </div>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>
import API from '@/helpers/api'

export default {
    name: "stats",
    data: function () {
        return {
            data: [],
        }
    },
    methods: {
      load: async function () {
        const data = await API.get('/stats');
        this.data = data;
      }
    },
    async mounted() {
      await this.load();
    }
}
</script>

<style scoped>

</style>
