<template>
  <div>
    <div class="w-full flex flex-row items-center justify-between">
      <div class="w-10/12">
        <h1 class="text-3xl font-semibold">Hallo 👋</h1>
        <h3 class="text-gray-500 text-sm">Dit is het {{project.name}} admin dashboard. </h3>
      </div>
      <div class="w-2/12">

      </div>
    </div>
    <div class="w-full items-start justify-between flex flex-row mt-8">
      <div class="w-full">

        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            1. Stand van zaken
          </h3>
          <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div class="bg-white overflow-hidden shadow rounded-lg">
              <div class="px-4 py-5 sm:p-6">
                <dt class="text-sm font-medium text-gray-500 truncate">
                  Totaal aantal plekjes
                </dt>
                <dd class="mt-1 text-3xl font-semibold text-gray-900">
                  {{data.PlekjesTotal}}
                </dd>
              </div>
            </div>

            <div class="bg-white overflow-hidden shadow rounded-lg">
              <div class="px-4 py-5 sm:p-6">
                <dt class="text-sm font-medium text-gray-500 truncate">
                  Plekjes met match
                </dt>
                <dd class="mt-1 text-3xl font-semibold text-gray-900">
                {{data.PlekjesWithmatch}}
                </dd>
              </div>
            </div>

            <div class="bg-white overflow-hidden shadow rounded-lg">
              <div class="px-4 py-5 sm:p-6">
                <dt class="text-sm font-medium text-gray-500 truncate">
                  Gematcht aantal
                </dt>
                <dd class="mt-1 text-3xl font-semibold text-gray-900">
                  {{data.PlekjesWithmatchPerc}}%
                </dd>
              </div>
            </div>
          </dl>
        </div>

        <div class="mt-10">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            2. Plekjes die we nog moeten goedkeuren
          </h3>

          <div class="flex flex-col mt-5">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="overflow-hidden border border-gray-200 sm:rounded-lg shadow-md mb-8">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-100">
                      <tr>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                          Wie
                        </th>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                          Waar
                        </th>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                          Aangemaakt
                        </th>
                        <th class="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500">
                          Options
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">

                      <!--LOOP THIS-->
                      <tr v-for="plekje in plekjes" v-bind:key="plekje._id">
                        <td class="w-3/12 px-6 py-4 whitespace-no-wrap">
                          <div class="flex items-center">
                            <div class="ml-4">
                              <div class="text-sm leading-5 font-medium text-gray-900">
                                {{plekje.firstname}} {{plekje.lastname}}
                              </div>
                              <div class="text-sm leading-5 text-gray-500">
                                {{plekje.owner}}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="w-3/12 px-6 py-4 break-words">
                          <div class="flex items-center">
                            <div class="ml-4">
                              <div class="text-sm leading-5 font-medium text-gray-900 break-words">
                                {{plekje.what}}
                              </div>
                              <div class="text-sm leading-5 text-gray-500 break-words">
                                {{plekje.where}}
                              </div>
                            </div>
                          </div>
                        </td>

                        <td class="w-2/12  px-6 py-4 whitespace-no-wrap">
                          <div class="flex items-center">
                            <div class="ml-4">
                              <div v-if="plekje.createdAt" class="text-sm leading-5 text-gray-500">
                                {{formatDate(plekje.createdAt)}}
                              </div>
                              <div v-else class="text-sm leading-5 text-gray-500">
                                Geen datum beschikbaar
                              </div>
                            </div>
                          </div>
                        </td>

                        <td class="w-3/12  px-6 py-4 whitespace-no-wrap">
                          <button @click="acceptPlekje(plekje)" class="bg-green-500 hover:bg-green-700 text-white  py-1 px-3 mx-1 rounded">
                            Goedkeuren
                          </button>

                          <button @click="declinePlekje(plekje)" class="bg-red-500 hover:bg-red-700 text-white  py-1 px-3 mx-1 rounded">
                            Afkeuren
                          </button>

                          <router-link :to="'/plekjes/'+plekje._id" class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 mx-1 rounded">
                            Bekijken
                          </router-link>
                        </td>
                      </tr>

                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="mt-10">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            3. Matches die we nog moeten goedkeuren
          </h3>

          <div class="flex flex-col mt-5">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="overflow-hidden border border-gray-200 sm:rounded-lg shadow-md mb-8">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-100">
                      <tr>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                          Name
                        </th>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                          Plekje
                        </th>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                          Aangemaakt
                        </th>
                        <th class="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500">
                          Options
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">

                      <!--LOOP THIS-->
                      <tr v-for="match in matches" v-bind:key="match._id">
                        <td class="px-6 py-4 whitespace-no-wrap">
                          <div class="flex items-center">
                            <div class="ml-4">
                              <div class="text-sm leading-5 font-medium text-gray-900">
                                {{match.firstname}} {{match.lastname}}
                              </div>
                              <div class="text-sm leading-5 text-gray-500">
                                {{match.email}}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 capitalize">
                          <div class="flex items-center">
                            <div class="ml-4">
                              <div class="text-sm leading-5 font-medium text-gray-900">
                                {{match.plekje.what}}
                              </div>
                              <div class="text-sm leading-5 text-gray-500">
                                {{match.plekje.where}}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap">
                          <div class="flex items-center">
                            <div class="ml-4">
                              <div v-if="match.createdAt" class="text-sm leading-5 text-gray-500">
                                {{formatDate(match.createdAt)}}
                              </div>
                              <div v-else class="text-sm leading-5 text-gray-500">
                                Geen datum beschikbaar
                              </div>
                            </div>
                          </div>
                        </td>

                        <td class="px-6 py-4 whitespace-no-wrap flex flex-row justify-center">

                          <button @click="acceptMatch(match)" class="bg-green-500 hover:bg-green-700 text-white  py-1 px-3 mx-1 rounded">
                            Goedkeuren
                          </button>

                          <button @click="declineMatch(match)" class="bg-red-500 hover:bg-red-700 text-white  py-1 px-3 mx-1 rounded">
                            Afkeuren
                          </button>

                          <router-link :to="'/matches/'+match._id" class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 mx-1 rounded">
                            Bekijken
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- RIGHTS side-->
      <div class="w-3/12 flex flex-col itemsstart justify-start gap-8">

      </div>
    </div>

  </div>
</template>

<script>
//import env from "@/../env.js";
//import Cookies from "@/helpers/cookies";
import moment from "moment";
import API from '@/helpers/api'

//const axios = require("axios");

export default {
  name: "home",
  data: function() {
    return {
      data: {},
      plekjes: [],
      matches: [],
      matchPlekje: {},
      project: {},
      moment
    };
  },
  methods: {

    acceptPlekje: async function(plekje){
      await API.post("/update", { plekje: plekje._id, event: 'accept'});
      this.plekjes.splice(this.plekjes.indexOf(plekje), 1);
      this.loadMetrics();
    },
    declinePlekje: async function(plekje){
      await API.post("/update", { plekje: plekje._id, event: 'decline'});
      this.plekjes.splice(this.plekjes.indexOf(plekje), 1);
      this.loadMetrics();
    },
    acceptMatch: async function(match){
      await API.post("/update", { match: match._id, event: 'accept'});
      this.matches.splice(this.matches.indexOf(match), 1);
      this.loadMetrics();
    },
    declineMatch: async function(match){
      await API.post("/update", { match: match._id, event: 'decline'});
      this.matches.splice(this.matches.indexOf(match), 1);
      this.loadMetrics();
    },

    /*
    react: function(type, object, active, index) {
      const _self = this;
      object.checked = true;
      object.matchAllowed = active;
      if (object.plekje) object.plekje.matchId = object._id;
      let body = object;
      // let body = {checked: true, matchAllowed: active};
      if (type === "plekjes") {
        body = { checked: true, active: active };
        this.plekjes.splice(index, 1);
      } else {
        if (!active) {
          axios
            .get(env.apiURL + "/plekjes/plekjeMatch/" + object.plekjeId, {
              headers: {
                Authorization: "Bearer " + Cookies.get("session")
              }
            })
            .then(function(res) {
              res.data.active = true;
              res.data.removed = false;
              res.data.hasMatch = false;
              res.data.hasReply = false;
              _self.matchPlekje = res.data;
              axios
                .put(
                  env.apiURL + "/plekjes/" + _self.matchPlekje._id,
                  _self.matchPlekje,
                  {
                    headers: {
                      Authorization: "Bearer " + Cookies.get("session")
                    }
                  }
                )
                .then(function(res) {
                  _self.data = res.data;
                });
            });
        }
        this.matches.splice(index, 1);
      }
      axios.put(env.apiURL + "/" + type + "/" + object._id, body, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        }).then(function(res) {
          _self.data = res.data;
          console.log(_self.data);
          if (active) {
            object.plekje.hasMatch = true;
            object.plekje.active = false;
            axios.put(env.apiURL + "/plekjes/" + object.plekje._id,
                object.plekje,
                {
                  headers: {
                    Authorization: "Bearer " + Cookies.get("session")
                  }
                }
              )
              .then(function(res) {
                console.log(res.data);
                _self.changesButNotSaved = false;
                alert("Saved");
              });
          }
          _self.loadMetrics();
        });
    },

    */

    loadMetrics: async function() {
      const data = await API.get('/analytics');
      this.data = data;
    },
    loadTasks: async function() {
      const data = await API.get('/tasks');
      this.plekjes = data.plekjes;
      this.matches = data.matches;
    },
    formatDate: function(date) {
      const newDate = new Date(date);
      return moment(newDate).format("DD/MM/YYYY, h:mm");
    }
  },
  computed: {},
  async mounted() {

    const project = await API.get('/settings');
    this.project = project;

    await this.loadMetrics();
    await this.loadTasks();

    const _self = this;
    setInterval(async () => {
      await _self.loadMetrics();
      await _self.loadTasks();
    }, 60000);

  }
};
</script>
