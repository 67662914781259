<template>
  <div>
    <div class="w-full items-start justify-between flex flex-row mt-3">
        <div class="w-full flex flex-col items-start justify-start gap-8">
          <div class="w-full flex flex-row items-center justify-between">
          <div class="w-4/12">
            <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer" @click="$router.go(-1)">Ga terug</span>
            <h1 class="text-3xl font-semibold">{{ total }} Matches</h1>
          </div>
          <div class="ml-auto w-8/12 flex flex-row mt-6">
            <div class="flex flex-row gap-2 w-3/12 justify-center items-center">
              <img @click="setColors('green')"  src="@/../public/green-dot.png" class="w-6 h-6" alt="">
              <img @click="setColors('yellow')"  src="@/../public/yellow-dot.png" class="w-6 h-6" alt="">
              <img @click="setColors('red')"  src="@/../public/red-dot.png" class="w-6 h-6" alt="">
              <img @click="setColors('remove')"  src="@/../public/cross.png" class="w-8 h-8" alt="">
            </div>
            <input type="text" @keyup="load()" class="w-6/12 border-2 border-gray-200 rounded-lg px-4 py-2  text-sm mx-5" placeholder="Zoek een bepaalde match..." v-model="q">


            <select @change="load()" v-model="category" class="form-input flex-1 block w-4/12 rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="België">
              <option value="">ALL</option>
              <option value="good">Gematcht</option>
              <option value="toValidate">Te valideren</option>
              <option value="bad">Afgewezen</option>
              <option value="expired">Afgerond</option>

            </select>

          </div>
            <button @click="downloadCSV()" class="text-white py-2 px-4 rounded flex-2 block ml-5 mt-5" style="background-color: rgb(32,23,71); width: 25%;">Download CSV</button>
            <!--<button @click="openPopup()" class="text-white py-2 px-4 rounded flex-2 block ml-5" style="background-color: rgb(32,23,71); width: 25%;">Bericht iedereen</button>-->
          </div>

        <div class="fixed z-10 inset-0 overflow-y-auto" v-if="popup">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity cursor-pointer" @click="closePopup()">
              <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div
              class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle"
              role="dialog" aria-modal="true" aria-labelledby="modal-headline">

              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">Het bericht</h3>
                    <p class="text-sm leading-5 text-gray-500">Dit bericht wordt automatisch naar alle matches verzonden.</p>
                    <div class="mt-5">
                      <div>
                        <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Bericht</p>
                        <!-- if text -->
                        <textarea rows="3" cols="50" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%;" v-model="message"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                  <button type="button" @click="sendMessage()"
                    class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">Verzend bericht</button>
                </span>


                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                  <button @click="popup=false;" type="button"
                    class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>



          <div class="w-full flex flex-col items-stretch justify-start">
              <!--transaction-->
              <div class="flex flex-col">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="overflow-hidden border border-gray-200 sm:rounded-lg shadow-md mb-8">
                    <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                      <div class="flex-1 flex justify-between sm:hidden">
                        <a @click="setPage(page - 1)" v-if="page > 0" href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                          Previous
                        </a>
                        <a @click="setPage(page + 1)" href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                          Next
                        </a>
                      </div>
                      <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div>
                          <p class="text-sm text-gray-700">
                            van
                            <span class="font-medium">{{start}}</span>
                            tot
                            <span class="font-medium">{{start + pageSize}}</span>
                            van de
                            <span class="font-medium">{{total}}</span>
                            resultaten getoond
                          </p>
                        </div>
                        <div>
                          <nav class="relative z-0 inline-flex shadow-sm -space-x-px" aria-label="Pagination">
                            <a @click="setPage(0)" v-if="page > 0" class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                              <span class="sr-only">First</span>
                              <!-- Heroicon name: chevron-double-left -->
                              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                              </svg>
                            </a>
                            <span class="cursor-pointer" v-for="n in totalPages" :key="n" @click="setPage(n-1)" :class="page === n-1 ? 'relative inline-flex items-center px-4 py-2 border border-gray-300 bg-indigo-200 text-sm font-medium text-gray-700' : 'hidden md:inline-flex relative items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50'">
                              {{n}}
                            </span>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-100">
                        <tr>
                          <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                            Status
                          </th>
                          <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                            Name
                          </th>
                          <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                            Plekje
                          </th>
                          <th v-if="category !== 'toValidate'" class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                            Contact gehad?
                          </th>
                          <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                            Aangemaakt/Geüpdate op
                          </th>
                          <th class="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500">
                            Options
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200 border-separate  ">

                        <!--LOOP THIS-->
                        <tr   v-for="match in data" v-bind:key="match._id" @click="selectRow(match._id)" :class="{'bg-gray-300':selected.includes(match._id)}">
                          <!--<p>{{match}}</p>-->
                          <td class="px-6 py-4 whitespace-no-wrap flex flex-col items-center justify-center  ">
                            <img v-if="match.statusColor === 'red'" src="@/../public/red-dot.png" class="w-4" alt="">
                            <img v-if="match.statusColor === 'green'" src="@/../public/green-dot.png" class="w-4" alt="">
                            <img v-if="match.statusColor === 'yellow'" src="@/../public/yellow-dot.png" class="w-4" alt="">
                            <p class="text-xs leading-5 font-medium text-gray-900" v-if="match.statusTimeStamp !== null && match.statusTimeStamp !== undefined">{{formatDate(match.statusTimeStamp)}}</p>
                          </td>
                          <td class="px-6 py-4 whitespace-no-wrap " >
                            <div class="flex items-center ">
                              <div class="ml-4">
                                <div class="text-sm leading-5 font-medium text-gray-900">
                                 {{match.nr}} {{match.firstname}} {{match.lastname}}
                                </div>
                                <div class="text-sm leading-5 text-gray-500">
                                  {{match.email}}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 capitalize">
                            <div v-if="match.plekje" class="flex items-center">
                              <div class="ml-4">
                                <div class="text-sm leading-5 font-medium text-gray-900">
                                  <p>{{match.plekje.firstname}} {{match.plekje.lastname}}</p>
                                  <div v-if="match.plekje && match.plekje._id">
                                    <p class="text-gray-500" v-if="match.plekje.festival && match.plekje.festival._id">{{match.plekje.festival.name}} op {{match.plekje.festivalDate}}</p>
                                    <p class="text-gray-500" v-else-if="match.plekje.where !== undefined">{{match.plekje.where.substring(0,70)}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>

                          <td v-if="category !== 'toValidate'" class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 capitalize">
                            {{match.chatting}}
                          </td>
                          <td class="px-6 py-4 whitespace-no-wrap">
                            <div class="flex items-center">
                              <div class="ml-4">
                                <div v-if="match.createdAt" class="text-sm leading-5 font-medium text-gray-900">
                                  {{formatDate(match.createdAt)}}
                                </div>
                                <div v-else class="text-sm leading-5 font-medium text-gray-900">
                                  Geen datum beschikbaar
                                </div>
                                <div v-if="match.updatedAt" class="text-sm leading-5 text-gray-500">
                                  {{formatDate(match.updatedAt)}}
                                </div>
                                <div v-else class="text-sm leading-5 font-medium text-gray-900">
                                  Geen datum beschikbaar
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="px-6 py-4 whitespace-no-wrap flex flex-row items-end justify-center">
                            <router-link :to="'/matches/'+match._id" class="bg-blue-500 hover:bg-blue-700 text-white  py-1 px-3 rounded">
                              Bekijken
                            </router-link>

                          </td>
                        </tr>

                      </tbody>
                    </table>

                    <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                      <div class="flex-1 flex justify-between sm:hidden">
                        <a @click="setPage(page - 1)" v-if="page > 0" href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                          Previous
                        </a>
                        <a @click="setPage(page + 1)" href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                          Next
                        </a>
                      </div>
                      <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div>
                          <p class="text-sm text-gray-700">
                            Van
                            <span class="font-medium">{{start}}</span>
                            tot
                            <span class="font-medium">{{start + pageSize}}</span>
                            van de
                            <span class="font-medium">{{total}}</span>
                            resultaten getoond
                          </p>
                        </div>
                        <div>
                          <nav class="relative z-0 inline-flex shadow-sm -space-x-px" aria-label="Pagination">
                            <a @click="setPage(0)" v-if="page > 0" class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                              <span class="sr-only">First</span>
                              <!-- Heroicon name: chevron-double-left -->
                              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                              </svg>
                            </a>
                            <span class="cursor-pointer" v-for="n in totalPages" :key="n" @click="setPage(n-1)" :class="page === n-1 ? 'relative inline-flex items-center px-4 py-2 border border-gray-300 bg-indigo-200 text-sm font-medium text-gray-700' : 'hidden md:inline-flex relative items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50'">
                              {{n}}
                            </span>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import env from '@/../env.js';
import Cookies from '@/helpers/cookies'
import moment from 'moment'

import Keep from '@/helpers/keep'
import API from '@/helpers/api'

const axios = require('axios');

export default {
  name: 'home',
  data: function () {
    return {
      data: [],
      popup: false,
      object: {},
      message: '',
      total: 0,
      totalPages: 0,
      category: 'good',
      q: '',

      page: 0,
      start: 0,
      pageSize: 25,
      selected:[],
      selectedColor:''
    }
  },
  watch:{
    page: function(now){ Keep.set('plekjesPage', now) },
    q: function(now){ Keep.set('plekjesQ', now) },
    category: function(now){ Keep.set('plekjesCategory', now) },
    start: function(now){ Keep.set('plekjesStart', now) },
    pageSize: function(now){ Keep.set('plekjesPageSize', now) },
    selectedColor: function(now){ Keep.set('selectedColor', now) },
  },
  methods: {

    async setColors(color){
      if(this.selected.length === 0){
        this.selectedColor = color
      }
      else{
        await axios.post(env.apiURL + '/matches/colors',{items:this.selected,color:color});
        this.selected = [];
      }
     this.load();

    },
    selectRow(id){
      if(this.selected.includes(id)){
        this.selected.splice(this.selected.indexOf(id),1);
      }
      else{
        this.selected.push(id);
      }
    },
    add: function () {
      this.object = {};
      this.popup = true;
    },
    setPage: function(page){
      this.page = page;
      this.start = this.pageSize * this.page;
      console.log(this.pageSize * this.page)
      // TODO: api call
      this.load();
    },

    load: async function() {
      const data = await API.get("/matches?q=" + this.q + "&category=" + this.category + "&start=" + this.start + "&pageSize=" + this.pageSize+'&color='+this.selectedColor);
      console.log('Matches:', data)
      this.data = data.results;
      for (var i = 0; i < data.length; i++) {
        this.data[i].nr = this.start + i + 1;
      }
      this.total = data.metrics.count;
      this.totalPages = Math.ceil(data.metrics.count / this.pageSize);
    },

    approveMatch: function(match){
      const _self = this;
      match.checked = true; match.matchAllowed = true;
      axios.put(env.apiURL+'/matches/'+match._id, match, {
        headers: {
          'Authorization': 'Bearer '+Cookies.get('session')
        }
      }).then(function () {
        _self.changesButNotSaved = false;
        alert('Saved');
      });
    },
    dissaproveMatch: function(match) {
      const _self = this;
      match.checked = true; match.matchAllowed = false;
      match.plekje.active = false;
      match.plekje.removed = true;
      console.log(match);
      axios.put(env.apiURL+'/matches/'+match._id, match, {
        headers: {
          'Authorization': 'Bearer '+Cookies.get('session')
        }
      }).then(function (res) {
        console.log(res);
      });
      axios.put(env.apiURL+'/plekjes/'+match.plekje._id, match.plekje, {
        headers: {
          'Authorization': 'Bearer '+Cookies.get('session')
        }
      }).then(function () {
        _self.popup = false;
        _self.changesButNotSaved = false;
        alert('Match ongedaan gemaakt');
      });
    },
    copyMatch: function(match) {
      const _self = this;
      axios.post(env.apiURL + '/matches', match, {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('session')
        }
      }).then(function (res) {
        _self.data.unshift(res.data);
        _self.popup = false;
        alert('Aangemaakt');
      });
    },
    deleteMatch: function(matchId){
      const _self = this;
      if(confirm('Ben je zeker dat je deze match wilt verwijderen? Je kan deze actie niet ongedaan maken')){
        axios.delete(env.apiURL+'/matches/'+matchId, {
          headers: {
            'Authorization': 'Bearer '+Cookies.get('session')
          }
        }).then(function () {
          _self.changesButNotSaved = false;
          _self.load();
          alert('Saved');
        });
      }
    },
    create: function () {
      const _self = this;
      axios.post(env.apiURL + '/matches', this.object, {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('session')
        }
      }).then(function (res) {
        console.log(res.data);
        _self.data.push(res.data)
        _self.popup = false;
      });
    },
    sendMessage: function () {
      const _self = this;
      axios.post(env.apiURL + '/messages/sendAll', {message: this.message}, {
        headers: {
          'Authorization': 'Bearer ' + Cookies.get('session')
        }
      }).then(function (res) {
        console.log(res.data);
        _self.popup = false;
      });
    },
    formatDate: function (date) {
      if (date) {
        const newDate = new Date(date);
        return moment(newDate).format('DD/MM/YYYY HH:mm');
      } else {
        return 'Geen datum gevonden'
      }
    },
    openPopup: function () {
      this.popup = true;
    },
    async downloadCSV() {
      const {data} = await axios.get(env.apiURL + '/matches/csv?q=' + this.q +'&category=' + this.category, {
        responseType: 'blob',
        headers: {
          'authorization': 'Bearer ' + Cookies.get('session'),
        }
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'matches.csv');
      document.body.appendChild(link);
      link.click();
    },
  },
  computed: {
  },
  async mounted() {

    this.page = Number(Keep.load('plekjesPage', 0));
    this.q = Keep.load('plekjesQ', '');
    this.category = Keep.load('plekjesCategory', 'good');
    this.start = Number(Keep.load('plekjesStart', 0));
    this.pageSize = Number(Keep.load('plekjesPageSize', 25));
    this.selectedColor = Keep.load('selectedColor', '')
    window.addEventListener('beforeunload', function() {
       Keep.reset('plekjesPage');
       Keep.reset('plekjesQ');
       Keep.reset('plekjesCategory');
       Keep.reset('plekjesStart');
       Keep.reset('plekjesPageSize');
       Keep.reset('selectedColor');
    });

    await this.load();
  }
}
</script>
<style scoped>

</style>
