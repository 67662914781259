import Vue from 'vue';
import VueRouter from 'vue-router';


import Matches from '../pages/matches/overview.vue';
import Match from '../pages/matches/detail.vue';
import Admins from  "../pages/admins/overview.vue"
import Admin from "../pages/admins/detail.vue"
import Gebruikers from '../pages/users/overview.vue';
import Gebruiker from '../pages/users/detail.vue';
import Plekjes from '../pages/plekjes/overview.vue';
import Plekje from '../pages/plekjes/detail.vue';
import Login from '../pages/auth/login.vue';
import Home from '../pages/homepage/index.vue';
import Stats from "../pages/stats/index"

import Festivals from '../pages/festivals/overview.vue';
import Festival from '../pages/festivals/detail.vue';
import Texts from '../pages/texts/overview.vue';
import Text from '../pages/texts/detail.vue';
import Questions from '../pages/questions/overview.vue';
import Question from '../pages/questions/detail.vue';

// De Genereuzen
import HvhRegistrations from '../pages/degenereuzen/hvhregistrations';
import HvhRegistration from '../pages/degenereuzen/hvhregistration';
import Events from "../pages/degenereuzen/events"
import Event from "../pages/degenereuzen/event"
import Projecten from "../pages/degenereuzen/projecten"
import Project from "../pages/degenereuzen/project"
import NieuwsbriefGenereuzen from "../pages/degenereuzen/nieuwsbrief";


import Nieuwsbrief from '../pages/misc/nieuwsbrief'
import Contact from '../pages/misc/contact.vue';
import BlijfOpDeHoogte from "../pages/misc/blijfopdehoogte";
import Settings from '../pages/settings.vue';



Vue.use(VueRouter);

const routes = [
  { path: '/', name: 'Home', component: Home },

  { path: '/gebruikers', name: 'Gebruikers', component: Gebruikers },
  { path: '/gebruikers/:id', name: 'Gebruiker', component: Gebruiker },
  { path: '/admins', name: 'Admins', component: Admins },
  { path: '/admins/:id', name: 'Admin', component: Admin },

  { path: '/plekjes', name: 'Plekjes', component: Plekjes },
  { path: '/plekjes/:id', name: 'Plekje', component: Plekje },

  { path: '/matches', name: 'Matches', component: Matches },
  { path: '/matches/:id', name: 'Match', component: Match },

  { path: '/festivals', name: 'Festivals', component: Festivals },
  { path: '/festivals/:id', name: 'Festival', component: Festival },

  { path: '/questions', name: 'Questions', component: Questions },
  { path: '/questions/:id', name: 'Question', component: Question },

  { path: '/texts', name: 'Texts', component: Texts },
  { path: '/texts/:id', name: 'Text', component: Text },


  { path: '/settings', name: 'Settings', component: Settings },


  { path: '/nieuwsbrief', name: 'Nieuwsbrief', component: Nieuwsbrief },
  { path: '/blijfopdehoogte/:id', name: 'BlijfOpDeHoogte', component: BlijfOpDeHoogte },

  { path: '/stats', name: 'Statistics', component: Stats },
  { path: '/login', name: 'Login', component: Login },
  { path: '/contact', name: 'Contact', component: Contact },

    // DE GENEREUZEN
  { path: '/hvhregistraties', name: 'HvhRegistraties', component: HvhRegistrations },
  { path: '/hvhregistraties/:id', name: 'HvhRegistratie', component: HvhRegistration },
  { path: '/nieuwsbrief-genereuzen', name: 'NieuwsbriefGenereuzen', component: NieuwsbriefGenereuzen },
  { path: '/projecten', name: 'Projecten', component: Projecten },
  { path: '/projecten/:id', name: 'Project', component: Project },
  { path: '/evenementen', name: 'Evenementen', component: Events },
  { path: '/evenementen/:id', name: 'Evenement', component: Event },
];

const router = new VueRouter({
  routes
});

export default router;
