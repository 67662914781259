<template>
  <div class="w-full items-start justify-between flex flex-row mt-3">
    <div class="w-full flex flex-col items-stretch justify-start">
      <!--transaction-->
      <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer" @click="$router.go(-1)">Ga terug</span>
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden border border-gray-200 sm:rounded-lg shadow-md mb-8">
              <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <div>
                    <p class="text-sm text-gray-700">
                      Showing
                      <span class="font-medium">{{total}}</span>
                      results
                    </p>
                  </div>
                </div>
                <button class="text-white py-2 px-4 rounded float-right ml-5 cursor-pointer" style="background-color: rgb(32, 23, 71);" @click="openPopup()">Toevoegen</button>
              </div>


              <div class="fixed z-10 inset-0 overflow-y-auto" v-if="popup">
                <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                  <div class="fixed inset-0 transition-opacity cursor-pointer" @click="popup = false">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                  </div>
                  <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                  <div style="width: 40vw;" class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

                    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div class="">
                        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">Voeg een nieuwe tekst toe (developer)</h3>

                          <div class="mt-5">
                            <div>
                              <p class="text-sm leading-5 font-medium mb-1 text-gray-500">ID</p>
                              <input type="text" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%;" v-model="object.id">
                            </div>
                            <div>
                              <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Name</p>
                              <input type="text" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%;" v-model="object.name">
                            </div>
                            <div>
                              <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Beschrijving</p>
                              <textarea rows="3" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%;" v-model="object.description"></textarea>
                            </div>
                            <div>
                              <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Tekst</p>
                              <input type="text" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%;" v-model="object.text">
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                      <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                        <button type="button" @click="create()" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">Voeg tekst toe</button>
                      </span>

                      <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                        <button @click="popup=false;" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                          Cancel
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <table class="min-w-full divide-y divide-gray-200 table-fixed">
                <thead class="bg-gray-100">
                  <tr>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                      ID
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                      Naam
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500" style="width: 800px">
                      Huidige tekst
                    </th>
                    <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                      Opties
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">

                  <!--LOOP THIS-->
                  <tr v-for="text in data" v-bind:key="text._id">
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm leading-5 font-medium text-gray-900">
                            {{text.id}}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-no-wrap">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm leading-5 font-medium text-gray-900">
                            {{text.name}}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4" style="width: 500px">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="truncate text-sm leading-5 font-medium text-gray-700">
                            {{text.text}}
                          </div>
                        </div>
                      </div>
                    </td>


                    <td class="px-4 py-4 whitespace-no-wrap flex flex-row items-end justify-center">

                      <router-link :to="'/texts/'+text._id" class="bg-blue-500 hover:bg-blue-700 text-white py-1 px-3 rounded">
                        Aanpassen
                      </router-link>

                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/helpers/api'

export default {
  name: "Texts",
  data: function() {
    return {
      data: [],
      total: 0,
      popup: false,
      uploaded: false,
      object: {},
    };
  },
  methods: {
    getData: async function() {
      const data = await API.get('/texts');
      this.data = data.data;
      console.log('data:', data)
    },
    openPopup: function() {
      this.popup = true;
    },
    create: async function() {
      console.log(this.object)
      const data = await API.post('/texts', this.object);
      this.data.push(data);
      this.object = {};
      this.popup = false;
    }
  },
  mounted() {
    this.getData();
  }
};
</script>

<style scoped>
</style>
