<template>
  <div class="h-screen flex flex-row">
      <!--style="background-image: url('../assets/login-bg.jpg');"-->
    <div class="sm:hidden md:block md:w-4/12  bg-white h-screen relative overflow-hidden bg-no-repeat bg-center bg-cover">
        <img :src="project.banner" class="h-full"/>
    </div>
    <div class="w-8/12 sm:w-full md:w-8/12 bg-white h-screen flex flex-col items-center justify-between py-20">
      <div class="w-8/12 flex flex-row items-center justify-start">
        <svg class="h-4 w-4 mr-2" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke-linecap="round" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linejoin="round"><path d="M5,12h14"></path><path d="M10,7l-5,5"></path><path d="M10,17l-5,-5"></path></g><path fill="none" d="M0,0h24v24h-24Z"></path></svg>
        <a :href="'https://'+project.dashboardURL" class="text-gray-600 text-sm hover:text-gray-800 transition-colors duration-200 font-medium" style="background-color: #ffffff;">Terug naar website</a>
      </div>
      <div class="w-8/12">
          <h2 class="lg:text-5xl tracking-tighter font-semibold sm:text-4xl">{{project.name}} admin</h2>
          <p class="text-gray-600">Het admin dashboard van {{project.name}}.</p>

          <input type="text" class="px-4 text-sm py-3 border border-gray-300 w-full rounded-lg mt-10" placeholder="Email adress" v-model="input.email">
          <input type="password" class="px-4 text-sm py-3 border border-gray-300 w-full rounded-lg mt-2" placeholder="Passwoord" v-model="input.password">

          <div v-if="false" class="w-full flex justify-between flex-row mt-4">
            <div class="flex flex-row items-center flex-start">
            </div>
          </div>
          <button @click="login()" class="w-full px-3 mt-8 text-gray py-4 rounded-lg shadow-lg hover:shadow-xl transition duration-200" :style="'background:'+ project.color ">Aanmelden</button>
          <div class="relative py-3">
              <p v-if="error" class="absolute text-red-500 mt-5 font-medium text-sm outline-none">{{error}}</p>
          </div>
      </div>
      <div class="w-8/12">
        <a :href="'https://'+project.dashboardURL" class="text-gray-400 text-sm">(c) {{project.name}} </a>
      </div>
    </div>
  </div>
</template>

<script>
  import auth from '@/helpers/auth';
  import API from '@/helpers/api'


  export default {
    name: 'login',
    data() {
      return {
        error: false,
        error_animation: false,
        input: {
          email: "",
          password: ""
        },
        project: {},
        user: {},
      }
    },
    methods: {
      async login() {
          const _self = this;
        const user = await auth.login(this.input.email, this.input.password);

        // Check if the user is set (which should, otherwise we should have an error)
        if(user && user._id){
            this.$router.replace({ path: "/" });
            this.user = await auth.me().catch(function(){
                if (auth.shouldRedirectToLogin()) {
                    _self.$router.replace({ name: "Login"});
                }
            });
          document.location.reload();
        } else {
          this.error = user.error;
          this.error_animation = true;
          setTimeout(() =>{
            this.error_animation = false;
          }, 2000);
        }
      },
      resetPassword() {
        this.$router.replace({ name: "reset_password"});
      },
    },
    async created() {
      this.user = await auth.me().catch(() => {});
      if (this.user && this.user._id) {
        console.log("Already loggedin... you can't do this twice");
        //this.$store.commit('setUser', this.user);
        this.$router.replace({ path: "/" });
      }


      this.project = await API.get("/settings");



      window.addEventListener('keydown', (e) => {
        if (e.key == 'Enter') {
          this.login();
        }
      });
    },
  }
</script>
