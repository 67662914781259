<template>
  <div>
    <div class="w-full flex flex-row items-center justify-between">
      <div class="w-8/12">
        <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer" @click="goback()">Ga terug</span>
        <h1 class="text-3xl font-semibold" v-if="userInfo">
          {{ userInfo.firstname }} {{ userInfo.lastname }}
        </h1>
      </div>
      <div class="w-4/12">
        <button @click="save()" class="text-white py-2 px-4 rounded float-right ml-5" style="background-color: rgb(32, 23, 71)">
          Opslaan
        </button>

        <button @click="loginAs()" class="text-white py-2 px-4 rounded float-right ml-5 bg-green-600">
          Login als {{userInfo.firstname}}
        </button>

      </div>
    </div>
    <div class="w-full items-start justify-start flex flex-row">
      <div class="w-full flex flex-col border-b border-gray-300 pb-10" v-if="userInfo">
        <div class="w-full mt-5 flex flex-row">
          <div class="w-6/12 pt-5 pr-10">
            <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center">
              1. Basis informatie
            </h3>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Voornaam</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Voornaam" v-model="userInfo.firstname" @change="change()" />
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Achternaam</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Achternaam" v-model="userInfo.lastname" @change="change()" />
                </div>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Email</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input v-if="userInfo.owner" class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email" v-model="userInfo.owner" />
                  <input v-else class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email" v-model="userInfo.email" />
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Telefoon</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Telefoon" v-model="userInfo.phone" @change="change()" />
                </div>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5" v-if="userInfo.birthYear">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Geboortejaar</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Geboortejaar" v-model="userInfo.birthYear" @change="change()" />
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Zip</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Zip" v-model="userInfo.zip" @change="change()" />
                </div>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Geslacht</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <select v-model="userInfo.gender" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="België" @change="change()">
                    <option value="male">Man</option>
                    <option value="female">Vrouw</option>
                    <option value="">Anders</option>
                  </select>
                  <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none">
                      <path d="M0,0h24v24h-24Z"></path>
                      <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Beperking</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <select v-model="userInfo.beperking" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="België" @change="change()">
                    <option value="true">Ja</option>
                    <option value="false">Nee</option>
                  </select>
                  <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none">
                      <path d="M0,0h24v24h-24Z"></path>
                      <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Image URL</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Image URL" v-model="userInfo.image" @change="change()" />
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Lid</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <select v-model="userInfo.lid" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" @change="change()">
                    <option value="neos">Neos</option>
                    <option value="cera">Cera</option>
                    <option value="geen">Geen</option>
                  </select>
                  <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none">
                      <path d="M0,0h24v24h-24Z"></path>
                      <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                    </g>
                  </svg>
                </div>
              </div>
            </div>

          </div>

          <div class="w-6/12 pt-5 pr-10">
            <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center">
              2. Afbeelding
            </h3>
            <img class="p-5" :src="userInfo.image" style="max-height: 500px" />
          </div>

        </div>

        <div class="w-full">

          <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center mt-10">
            3. Begeleider
          </h3>

          <div class="flex flex-row">
            <div class="w-6/12 pr-5">
              <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Voornaam begeleider</label>
              <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Voornaam begeleider" v-model="userInfo.firstname_begeleider" @change="change()" />
              </div>
            </div>
            <div class="w-6/12">
              <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Achternaam begeleider</label>
              <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Achternaam begeleider" v-model="userInfo.lastname_begeleider" @change="change()" />
              </div>
            </div>
          </div>

          <div class="flex flex-row">
            <div class="w-6/12 pr-5">
              <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Email begeleider</label>
              <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email begeleider" v-model="userInfo.email_begeleider" @change="change()" />
              </div>
            </div>
            <div class="w-6/12">
              <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Telefoon begeleider</label>
              <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Telefoon begeleider" v-model="userInfo.phone_begeleider" @change="change()" />
              </div>
            </div>
          </div>
          <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center mt-10">
            4. Beperking
          </h3>
          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Soort beperking</label>
          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
            <textarea rows="1" class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Soort beperking" v-model="userInfo.beperking_type" @change="change()"></textarea>
          </div>
          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Soort hulp</label>
          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
            <textarea rows="3" class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Soort hulp" v-model="userInfo.hulp" @change="change()"></textarea>
          </div>

          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Hulp notes</label>
          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
            <textarea rows="3" class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Hulp notities" v-model="userInfo.hulpNotes" @change="change()"></textarea>
          </div>

          <h3 v-if="object.plekjes" class="mt-10 text-2xl font-semibold leading-5 flex flex-row items-center">
            5. Plekjes ({{ object.plekjes.length }})
          </h3>
          <p class="text-sm leading-5 text-gray-500">
            Hier vind je een overzicht van zijn of haar plekjes en of deze al
            dan niet een match hebben.
          </p>
          <br />
          <table v-if="object.plekjes" class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-100">
              <tr>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                  Waar
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                  Gematcht
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                  Goedgekeurd
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                  Opties
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(plekje, index) in object.plekjes" v-bind:key="index">

                <td v-if="plekje && plekje != null" class="px-6 py-4 whitespace-no-wrap">
                  <div class="flex items-start space-x-4">
                    <div class="ml-4 flex flex-col">
                      <div class="flex">
                        <div v-if="(plekje.where || '').toString().length <= 25" class="text-sm leading-5 font-medium text-gray-900">
                          {{ plekje.where }}
                        </div>
                        <div v-else class="text-sm leading-5 font-medium text-gray-900">
                          {{ (plekje.where || '').toString().substr(0, 25) }}...
                        </div>
                        <p class="px-2">-</p>
                        <div v-if="(plekje.what || '').toString().length <= 25" class="text-sm leading-5 font-medium text-gray-900">
                          {{ plekje.what }}
                        </div>
                        <div v-else class="text-sm leading-5 font-medium text-gray-900">
                          {{ (plekje.what || '').toString().substr(0, 25) }}...
                        </div>
                      </div>

                      <div class="text-sm leading-5 font-medium text-gray-600">
                        <p v-if="plekje.hasMatch && plekje.matchId && plekje.match">
                          {{plekje.match.firstname}} {{plekje.match.lastname}}
                        </p>

                      </div>
                    </div>
                  </div>
                </td>
                <td v-if="plekje && plekje != null" class="px-6 py-4 whitespace-no-wrap items-end justify-center">
                  {{ plekje.hasMatch && plekje.match ? 'Ja' : 'Nee' }}
                </td>
                <td v-if="plekje && plekje != null" class="px-6 py-4 whitespace-no-wrap items-end justify-center">
                  {{ plekje.active ? 'Ja' : 'Nee' }}
                </td>

                <td v-if="plekje && plekje != null" class="px-6 py-4 whitespace-no-wrap flex space-x-2">

                  <router-link :to="`/plekjes/${plekje._id}`" class="bg-blue-500 hover:bg-blue-700 text-white py-1 px-3 rounded">
                    Bekijk Plekje
                  </router-link>

                  <router-link v-if="plekje.hasMatch && plekje.match" :to="'/matches/' + plekje.match._id" class="cursor-pointer bg-blue-500 hover:bg-blue-700 text-white py-1 px-3 rounded">
                    Bekijk Match
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <h3 v-if="object.matches" class="mt-10 text-2xl font-semibold leading-5 flex flex-row items-center">
            6. Matches ({{
                object.matches.length
              }})
          </h3>
          <p class="text-sm leading-5 text-gray-500">
            Hier vind je een overzicht van de plekjes waarop deze persoon is
            ingegaan.
          </p>
          <br />
          <table v-if="object.matches" class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-100">
              <tr>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                  Match
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                  Contact opgenomen
                </th>
                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                  Goedgekeurd
                </th>

                <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                  Opties
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="match in object.matches" v-bind:key="match._id">
                <td v-if="match.plekje" class="px-6 py-4 whitespace-no-wrap">
                  <div class="ml-4 flex flex-col">
                    <div class="flex">
                      <div v-if="(match.plekje.where || '').toString().length <= 25" class="text-sm leading-5 font-medium text-gray-900">
                        {{ match.plekje.where }}
                      </div>
                      <div v-else class="text-sm leading-5 font-medium text-gray-900">
                        {{ (match.plekje.where || '').toString().substr(0, 25) }}...
                      </div>
                      <p class="px-2">-</p>
                      <div v-if="(match.plekje.what || '').toString().length <= 25" class="text-sm leading-5 font-medium text-gray-900">
                        {{ match.plekje.what }}
                      </div>
                      <div v-else class="text-sm leading-5 font-medium text-gray-900">
                        {{ (match.plekje.what || '').toString().substr(0, 25) }}...
                      </div>
                    </div>

                    <div class="text-sm leading-5 font-medium text-gray-600">
                      <p>
                        {{match.plekje.firstname}} {{match.plekje.lastname}}
                      </p>

                    </div>
                  </div>

                </td>
                <td v-else>
                  Plekje verwijderd
                </td>

                <td class="px-6 py-4 whitespace-no-wrap items-end justify-center">
                  {{ match.chatting ? "Ja" : "Nee" }}
                </td>
                <td class="px-6 py-4 whitespace-no-wrap items-end justify-center">
                  {{ match.matchAllowed ? "Ja" : "Nee" }}
                </td>

                <td class="px-6 py-4 whitespace-no-wrap flex space-x-2 items-end justify-center">
                  <router-link v-if="match.plekje" :to="'/plekjes/' + match.plekje._id" class="cursor-pointer bg-blue-500 hover:bg-blue-700 text-white py-1 px-3 rounded">
                    Bekijk Plekje
                  </router-link>

                  <router-link :to="'/matches/' + match._id" class="cursor-pointer bg-blue-500 hover:bg-blue-700 text-white py-1 px-3 rounded">
                    Bekijk Match
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
import env from "@/../env.js";
import Cookies from "@/helpers/cookies";
import API from '@/helpers/api'

const axios = require("axios");

export default {
  name: "home",
  data: function() {
    return {
      data: [],
      userInfo: [],
      object: {
        plekjes: []
      },
      env,
      loaded: false
    };
  },
  methods: {
    allowedCheck(object, plekje) {
      if (!object || !plekje) return false;
      const match =
        object[
          object.findIndex(match => {
            return match && match.plekjeId === plekje.id;
          })
        ];
      if (match) {
        return match.matchAllowed;
      }
      return false;
    },
    add: function() {
      this.object = {};
      this.popup = true;
    },
    load: async function() {
      this.loaded = false;
      // og req:
      // env.apiURL + "/users/" + _self.$route.params.id + "?firstname=" + _self.$route.query.firstname + "&lastname=" + _self.$route.query.lastname,

      const { data } = await axios.get(
        `${env.apiURL}/users/${this.$route.params.id}?firstname=${this.$route.query.firstname}&lastname=${this.$route.query.lastname}`,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        }
      );

      this.object = data;
      console.log("got data:", data);
      console.log("plekjes", this.object.plekjes);

      /*
// get rid of invalid matches
      for (let i = 0; i < this.object[2].length; i++) {
        if (this.object[2][i] === null) {
          this.object[2].splice(i, 1);
        }
      }
      */
      /*
      if (data[0][0]) {
        this.userInfo = data[0][0]; // eerste plekje
      } else {
        this.userInfo = data[1][0]; // eerste match
      }
      */
      this.userInfo = this.object;
      console.log("userinfo", this.userInfo);
      if (!this.userInfo.lid || this.userInfo.lid === "") {
        this.userInfo.lid = "geen";
      }
      this.loaded = true;
    },
    loginAs: async function(){
      const session = await API.post('/users/'+this.object.email+'/generate-token');
      if(session.session._id){
        alert(env.baseUrl+'/magic-link/'+session.session._id)
      }
      console.log(session)
    },
    save: function() {
      const _self = this;
      console.log(_self.userInfo);
      _self.changed = false;
      axios
        .put(env.apiURL + "/users/" + _self.$route.params.id + `?lastname=${this.$route.query.lastname}`, _self.userInfo, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function() {
          alert("Saved");
        });
    },
    open: function() {
      const url = env.apiURL + "/matches/" + this.object._id;
      document.location = url;
    },
    remove: function() {
      if (confirm("Are you sure? This action cannot be undone!!")) {
        axios
          .delete(env.apiURL + "/matches/" + this.object._id, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session")
            }
          })
          .then(function() {
            document.location = "/";
          });
      }
    },
    formatBirthYear: function(date) {
      const newDate = new Date(date);
      return newDate.getUTCFullYear();
    },
    goback: function() {
      const _self = this;
      console.log(_self.changed);
      if (!_self.changed) {
        this.$router.go(-1);
      } else {
        const c = confirm(
          "Weet u zeker dat u de pagina wilt verlaten? Uw wijzigingen zijn nog niet opgeslagen"
        );
        if (c === true) {
          this.$router.go(-1);
        }
      }
    },
    change: function() {
      const _self = this;
      _self.changed = true;
      console.log(_self.changed);
    }
  },
  async mounted() {
    await this.load();
  }
};
</script>

<style>
.form-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-width: 1px;
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
  line-height: 1.5;
}
.form-input:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(164, 202, 254, 0.45);
  border-color: #a4cafe;
}
</style>
