<template>
  <div>
    <div class="w-full items-start justify-between flex flex-row mt-3">
      <div class="w-full flex flex-col items-start justify-start gap-8">
        <div class="w-full flex flex-row items-center justify-between">
          <div class="w-10/12">
            <span
              class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer"
              @click="$router.go(-1)"
              >Ga terug</span
            >
            <h1 v-if="isPrivate" class="text-3xl font-semibold">
              {{ privateTotal }} van {{ total }} Registraties
            </h1>
            <h1 v-else class="text-3xl font-semibold">
              {{ orgTotal }} van {{ total }} Registraties
            </h1>
          </div>
          <div class="bg-white">
            <nav class="flex flex-col sm:flex-row">
              <button
                id="btnPrivate"
                @click="setPrivate(true)"
                class="text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none text-blue-500 border-b-2 font-medium border-blue-500"
              >
                Privé</button
              ><button
                id="btnOrg"
                @click="setPrivate(false)"
                class="text-gray-600 py-4 px-6 block hover:text-blue-500 focus:outline-none"
              >
                Organisaties
              </button>
            </nav>
          </div>
        </div>
        <div class="w-full flex flex-col items-stretch justify-start">
          <!--transaction-->
          <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div
                class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
              >
                <div
                  class="overflow-hidden border border-gray-200 sm:rounded-lg shadow-md mb-8"
                >
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-100">
                      <tr>
                        <th
                          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500"
                        >
                          Wie/wat
                        </th>
                        <th
                          v-if="!isPrivate"
                          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500"
                        >
                          Verantwoordelijke
                        </th>
                        <th
                          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500"
                        >
                          Gemeente
                        </th>
                        <th
                          class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500"
                        >
                          Charter/nieuwsbrief
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      class="bg-white divide-y divide-gray-200"
                      v-if="isPrivate"
                    >
                      <!--LOOP THIS-->
                      <tr v-for="reg in privateData" v-bind:key="reg._id">
                        <td class="px-6 py-4 whitespace-no-wrap">
                          <div class="flex items-center">
                            <div class="ml-4">
                              <div
                                class="text-sm leading-5 font-medium text-gray-900"
                              >
                                {{ reg.firstname }} {{ reg.lastname }}
                              </div>
                              <div class="text-sm leading-5 text-gray-500">
                                {{ reg.email }}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td
                          class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 capitalize"
                        >
                          {{ reg.zip }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 capitalize"
                        >
                          <span v-if="reg.charter">{{ reg.charter }} </span>
                          <span v-else>False</span><br />
                          <span v-if="reg.uptodate">{{ reg.uptodate }} </span>
                          <span v-else>False</span><br />
                        </td>
                        <router-link
                          :to="'/hvhregistraties/' + reg._id"
                          class="bg-blue-500 hover:bg-blue-700 text-white py-1 px-3 rounded"
                        >
                          Bekijken
                        </router-link>
                      </tr>
                    </tbody>
                    <tbody
                      class="bg-white divide-y divide-gray-200"
                      v-if="!isPrivate"
                    >
                      <tr v-for="reg in orgData" v-bind:key="reg._id">
                        <td class="px-6 py-4 whitespace-no-wrap">
                          <div class="flex items-center">
                            <div class="ml-4">
                              <div
                                class="text-sm leading-5 font-medium text-gray-900"
                              >
                                {{ reg.organisationname }}
                              </div>
                              <div class="text-sm leading-5 text-gray-500">
                                Type: {{ reg.companytype }}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap">
                          <div class="flex items-center">
                            <div class="ml-4">
                              <div
                                class="text-sm leading-5 font-medium text-gray-900"
                              >
                                {{ reg.firstnameResponsible }}
                                {{ reg.lastnameResponsible }}
                              </div>
                              <div class="text-sm leading-5 text-gray-500">
                                {{ reg.email }}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td
                          class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 capitalize"
                        >
                          {{ reg.zip }}
                        </td>
                        <td
                          class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 capitalize"
                        >
                          <span v-if="reg.charter">{{ reg.charter }} </span>
                          <span v-else>False</span><br />
                          <span v-if="reg.uptodate">{{ reg.uptodate }} </span>
                          <span v-else>False</span><br />
                        </td>
                        <router-link
                          :to="'/hvhregistraties/' + reg._id"
                          class="bg-blue-500 hover:bg-blue-700 text-white py-1 px-3 rounded"
                        >
                          Bekijken
                        </router-link>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import env from "@/../env.js";
import Cookies from "@/helpers/cookies";
const axios = require("axios");

export default {
  name: "hvhregistrations",
  data: function () {
    return {
      isPrivate: true,
      privateData: [],
      privateTotal: 0,
      orgData: [],
      orgTotal: 0,
      total: 0,
    };
  },
  methods: {
    getPrivateRegistrations: async function () {
      const _self = this;
      /* axios.get(env.apiURL + '/registrations/private', {
              headers: {
                  'Authorization': 'Bearer ' + Cookies.get('session')
              }
          }).then(function(res) {
              
              _self.privateData = res.data.results;
              console.log('PRIVATE',_self.privateData);
              _self.privateTotal = res.data.metrics.count;
              _self.total += _self.privateTotal;
          }); */

      const { data: results } = await axios.get(
        env.apiURL + "/registrations/private",
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        }
      );
      console.log("PriveResults: ", results);
      _self.privateData = results.results;
      /*  console.log('PRIVATE',_self.privateData); */
      _self.privateTotal = results.metrics.count;
      _self.total += _self.privateTotal;
    },
    getOrgRegistrations: async function () {
      const _self = this;
      /*  axios.get(env.apiURL + '/registrations/orgs', {
                headers: {
                    'Authorization': 'Bearer ' + Cookies.get('session')
                }
            }).then(function(res) {
                _self.orgData = res.data.results;
                console.log('ORG: ' ,_self.orgData);
                _self.orgTotal = res.data.metrics.count;
                _self.total += _self.orgTotal
            }); */
      const { data: results } = await axios.get(
        env.apiURL + "/registrations/orgs",
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("session"),
          },
        }
      );
      console.log("ORGResults: ", results);
      _self.orgData = results.results;
      /* console.log('ORG: ' ,_self.orgData); */
      _self.orgTotal = results.metrics.count;
      _self.total += _self.orgTotal;
    },
    setPrivate: function (bool) {
      this.isPrivate = bool;
      if (this.isPrivate) {
        document.getElementById("btnOrg").classList.remove("text-blue-500");
        document.getElementById("btnOrg").classList.remove("border-blue-500");
        document.getElementById("btnOrg").classList.remove("border-b-2");
        document.getElementById("btnPrivate").classList.add("text-blue-500");
        document.getElementById("btnPrivate").classList.add("border-blue-500");
        document.getElementById("btnPrivate").classList.add("border-b-2");
      } else {
        document.getElementById("btnPrivate").classList.remove("text-blue-500");
        document
          .getElementById("btnPrivate")
          .classList.remove("border-blue-500");
        document.getElementById("btnPrivate").classList.remove("border-b-2");
        document.getElementById("btnOrg").classList.add("text-blue-500");
        document.getElementById("btnOrg").classList.add("border-blue-500");
        document.getElementById("btnOrg").classList.add("border-b-2");
      }
    },
  },
  mounted() {
    this.getPrivateRegistrations();
    this.getOrgRegistrations();
  },
};
</script>

<style scoped></style>
