<template>
  <div class="w-full items-start justify-start flex flex-row">
    <div class="w-full flex flex-col pb-10">
      <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer" @click="$router.go(-1)">Ga terug</span>
      <h1 class="text-3xl font-semibold">{{object.name}}</h1>

      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Tekst</label>
      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
        <textarea rows="3" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Tekst" v-model="object.text" style="height: 100px; width: 100px"></textarea>
      </div>


      <div class="flex flex-row mt-4">
        <button @click="save()" class="text-white py-2 rounded float-right cursor-pointer" style="background-color: rgb(32, 23, 71); width: 250px;">Opslaan</button>
        <button @click="del()" class="text-white py-2 px-4 rounded float-right ml-5 cursor-pointer" style="background-color: red; width: 250px;">Verwijder</button>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/helpers/api'


export default {
  name: "event",
  data: function() {
    return {
      object: {},
    };
  },
  methods: {
    load: async function() {
      this.object = await API.get("/texts/" + this.$route.params.id);
    },
    save: async function() {
      await API.put("/texts/" + this.object._id, this.object);
      alert("Saved")
    },
    del: async function() {
      if(confirm('Are you sure you want to remove this item?')){
        await API.delete("/texts/" + this.object._id);
        this.$router.push({ path: '/texts'})
      }
    },
  },
  async mounted() {
    await this.load();
  }
};
</script>

<style scoped>
</style>
