<template>
    <div>
        <div class="w-full flex flex-row items-center justify-between">
            <div class="w-8/12">
                <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer"
                      @click="goback()">Ga terug</span>
                <h1 class="text-3xl font-semibold" v-if="object.firstname">{{ object.firstname }}
                    {{object.lastname}} </h1>
                <h1 class="text-3xl font-semibold" v-else>{{ object.email }} </h1>
            </div>
        </div>
        <div class="w-full items-start justify-start flex flex-row">
            <div class="w-full flex flex-col border-b border-gray-300 pb-10">
                <div class="w-full mt-5 flex flex-row">
                    <div class="w-6/12 pt-5 pr-10">
                        <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center">1. Basis informatie</h3>
                        <div class="flex flex-row">
                            <div class="w-6/12 pr-5">
                                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Voornaam</label>
                                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                    <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                           placeholder="Voornaam" v-model="object.firstname" @change="change()">
                                </div>
                            </div>
                            <div class="w-6/12">
                                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Achternaam</label>
                                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                    <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                           placeholder="Achternaam" v-model="object.lastname" @change="change()">
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-row">
                            <div class="w-6/12 pr-5">
                                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Email</label>
                                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                    <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                           placeholder="Email" v-model="object.email" @change="change()">
                                </div>
                            </div>
                            <div class="w-6/12">
                                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Telefoon</label>
                                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                    <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                           placeholder="Telefoon" v-model="object.phone" @change="change()">
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-row">
                            <div class="w-6/12 pr-5">
                                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Geboortejaar</label>
                                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                    <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                           placeholder="Geboortejaar" :value="formatBirthYear(object.birthYear)" @change="change()">
                                </div>
                            </div>
                            <div class="w-6/12">
                                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Zip</label>
                                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                    <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                           placeholder="Zip" v-model="object.zip" @change="change()">
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-row">
                            <div class="w-6/12 pr-5">
                                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Geslacht</label>
                                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                    <select v-model="object.gender"
                                            class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                            placeholder="" @change="change()">
                                        <option value="male">Man</option>
                                        <option value="female">Vrouw</option>
                                        <option value="other">Anders</option>
                                    </select>
                                    <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24"
                                         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <g fill="none">
                                            <path d="M0,0h24v24h-24Z"></path>
                                            <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div class="w-6/12">
                                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Beperking</label>
                                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                    <select v-model="object.beperking"
                                            class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                            placeholder="België" @change="change()">
                                        <option value="true">Ja</option>
                                        <option value="false">Nee</option>
                                    </select>
                                    <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24"
                                         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <g fill="none">
                                            <path d="M0,0h24v24h-24Z"></path>
                                            <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-row">
                            <div class="w-6/12 pr-5">
                                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">NewsGen</label>
                                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                    <select v-model="object.newsGen"
                                            class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                            placeholder="België" @change="change()">
                                        <option :value="true">Ja</option>
                                        <option :value="undefined">Nee</option>
                                    </select>
                                    <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24"
                                         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <g fill="none">
                                            <path d="M0,0h24v24h-24Z"></path>
                                            <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div class="w-6/12">
                                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Image URL</label>
                                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                    <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                           placeholder="Image URL" v-model="object.image" @change="change()">
                                </div>
                            </div>
                        </div>
                        <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center mt-10">2. Begeleider</h3>
                        <div class="flex flex-row">
                            <div class="w-6/12 pr-5">
                                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Voornaam
                                    begeleider</label>
                                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                    <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                           placeholder="Voornaam begeleider" v-model="object.firstname_begeleider" @change="change()">
                                </div>
                            </div>
                            <div class="w-6/12">
                                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Achternaam
                                    begeleider</label>
                                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                    <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                           placeholder="Achternaam begeleider" v-model="object.lastname_begeleider" @change="change()">
                                </div>
                            </div>
                        </div>


                        <div class="flex flex-row">
                            <div class="w-6/12 pr-5">
                                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Email
                                    begeleider</label>
                                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                    <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                           placeholder="Email begeleider" v-model="object.email_begeleider" @change="change()">
                                </div>
                            </div>
                            <div class="w-6/12">
                                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Telefoon
                                    begeleider</label>
                                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                    <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                           placeholder="Telefoon begeleider" v-model="object.phone_begeleider" @change="change()">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-6/12 pt-5 pr-10">
                        <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center">4. Bijhorende
                            afbeelding</h3>
                        <div class="m-5 p-5 h-96 w-96"
                             :style="`background-image: url('${object.image}'); background-position: center; background-size: cover; background-repeat: no-repeat;`">
                        </div>
                        <h3 class="mt-10 text-2xl font-semibold leading-5 flex flex-row items-center">5. Beperking</h3>
                        <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Hulp</label>
                        <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                      <textarea rows="3"
                                                class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                                placeholder="Hulp" v-model="object.hulp" @change="change()"></textarea>
                        </div>
                        <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Hulp notes</label>
                        <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                      <textarea rows="3"
                                                class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                                placeholder="Hulp notities" v-model="object.hulpNotes" @change="change()"></textarea>
                        </div>
                        <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center mt-10">6. Notities</h3>
                        <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Opmerkingen</label>
                        <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                                      <textarea rows="3"
                                                class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300"
                                                placeholder="Extra opmerkingen" v-model="object.other" @change="change()"></textarea>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import env from '@/../env.js';
    import Cookies from '@/helpers/cookies';

    const axios = require('axios');
    export default {
        name: "blijfopdehoogte",
        data: function () {
            return {
                object: {},
                env,
                nrOfMessages: 0
            }
        },
        methods: {
            load: function () {
                const _self = this;
                axios.get(env.apiURL + '/newsletter/blijfopdehoogte/' + _self.$route.params.id, {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('session')
                    }
                }).then(function (res) {
                    console.log('Data:', res.data.result[0]);
                    _self.object = res.data.result[0];
                    const sendBody = {candidateMail: res.data.email, ownerMail: res.data.plekje.owner};
                    _self.getTotalMessages(sendBody);
                });
            },
            formatBirthYear: function (date) {
                const newDate = new Date(date);
                return newDate.getUTCFullYear();
            },
            goback: function () {
                const _self = this;
                console.log(_self.changed);
                if (!_self.changed) {
                    this.$router.go(-1);
                } else {
                    const c = confirm('Weet u zeker dat u de pagina wilt verlaten? Uw wijzigingen zijn nog niet opgeslagen');
                    if (c === true) {
                        this.$router.go(-1);
                    }
                }
            },
            change: function () {
                const _self = this;
                _self.changed = true;
                console.log(_self.changed);
            },
        },
        mounted() {
            this.load();
        }
    }
</script>

<style scoped>

</style>
