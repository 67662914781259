<template>
  <div class="w-full items-start justify-start flex flex-row">
    <div class="w-full flex flex-col border-b border-gray-300 pb-10">
      <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer" @click="$router.go(-1)">Ga terug</span>
      <h1 class="text-3xl font-semibold">Evenement: <input class="text-3xl font-semibold" placeholder="Titel" v-model="object.name"></h1>
      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Intro</label>
      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
        <textarea rows="3" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Soort beperking" v-model="object.intro" style="height: 100px; width: 100px"></textarea>
      </div>
      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Beschrijving</label>
      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
        <textarea rows="5" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Soort beperking" v-model="object.description" style="height: 100px; width: 100px"></textarea>
      </div>
      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Prijs</label>
      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
        <textarea rows="5" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Soort beperking" v-model="object.price" style="height: 100px; width: 100px"></textarea>
      </div>
      <div class="flex flex-row">
        <div class="w-6/12 pr-5">
          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Adres</label>
          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
            <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Adres" v-model="object.address">
          </div>
        </div>
        <div class="w-6/12">
          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Datum</label>
          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
            <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Datum" v-model="object.date">
          </div>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="w-6/12 pr-5">
          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Van</label>
          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
            <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email" v-model="object.startDate">
          </div>
        </div>
        <div class="w-6/12">
          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Tot</label>
          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
            <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Telefoon" v-model="object.endDate">
          </div>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="w-6/12 pr-5">
          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Waar</label>
          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
            <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email" v-model="object.location">
          </div>
        </div>
        <div class="w-6/12">
          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Categorie</label>
          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
            <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email" v-model="object.category">
          </div>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="w-6/12 pr-5">
          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Website</label>
          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
            <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email" v-model="object.website">
          </div>
        </div>
        <div class="w-6/12">
          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Actief</label>
          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
            <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email" v-model="object.active">
          </div>
        </div>
      </div>
      <div class="flex flex-row">
        <div class="w-6/12 pr-5">
          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Avatar</label>
          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
            <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Avatar" v-model="object.avatar">
          </div>
          <div id="imgAvatar">
            <img class="p-5" :src="object.avatar || 'https://cdn.circuitsortie.be/fotos/150.png'" width="200px" style="cursor: pointer;" onclick="document.getElementById('upload_image_input_avatar').click();" />
            <p class="text-gray-700 text-sm">klik op afbeelding om te wijzigen</p>
            <div class="col-lg-4">
              <input type="file" accept="image/*" @change="uploadFiles('avatars')" id="upload_image_input_avatar" name="file" style="display: none;" multiple>
              <p v-if="uploadedAvatar">Bestand is geüpload</p>
            </div>
          </div>
        </div>
        <div class="w-6/12">
          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Banner</label>
          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
            <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Banner" v-model="object.banner">
          </div>
          <div id="imgBanner">
            <img class="p-5" :src="object.banner || 'https://cdn.circuitsortie.be/fotos/150.png'" width="200px" style="cursor: pointer;" onclick="document.getElementById('upload_image_input_banner').click();" />
            <p class="text-gray-700 text-sm">klik op afbeelding om te wijzigen</p>
            <div class="col-lg-4" id="grgQHJgpY">
              <input type="file" accept="image/*" @change="uploadFiles('banners')" id="upload_image_input_banner" name="file" style="display: none;" multiple>
              <p v-if="uploadedBanner">Bestand is geüpload</p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row">
        <button @click="save()" class="text-white py-2 px-4 rounded float-right ml-5 cursor-pointer" style="background-color: rgb(32, 23, 71); width: 250px;">Opslaan</button>
        <button @click="del()" class="text-white py-2 px-4 rounded float-right ml-5 cursor-pointer" style="background-color: red; width: 250px;">Verwijder</button>
      </div>
    </div>
  </div>
</template>

<script>
import env from "@/../env.js";
import Cookies from "@/helpers/cookies";
const axios = require("axios");
import moment from "moment";

export default {
  name: "event",
  data: function() {
    return {
      object: {},
      env,
      uploaded: false,
      uploadedAvatar: false,
      uploadedBanner: false
    };
  },
  methods: {
    getEvent: function() {
      const _self = this;
      axios
        .get(env.apiURL + "/events/" + _self.$route.params.id, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function(res) {
          console.log("Data:", res.data);
          _self.object = res.data;
          _self.object.startDate = _self.formatDate(_self.object.startDate);
          _self.object.endDate = _self.formatDate(_self.object.endDate);
        });
    },
    save: function() {
      axios
        .put(env.apiURL + "/events/" + this.object._id, this.object, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function() {
          alert("Saved");
        });
    },
    del: function() {
      axios
        .delete(env.apiURL + "/events/" + this.object._id, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function() {
          location.replace("/#/evenementen");
        });
    },
    formatDate: function(date) {
      if (date) {
        const newDate = new Date(date);
        return moment(newDate).format("DD/MM/YYYY HH:mm");
      } else {
        return "Geen datum gevonden";
      }
    },
    sendFile: function(formData, imgType) {
      const _self = this;
      return new Promise(function(resolve) {
        fetch(env.apiURL + "/files/upload/" + imgType, {
          method: "POST",
          body: formData
        })
          .then(res => res.json())
          .then(res => {
            console.log("Done uploading", res);
            resolve(res.file);
            if (imgType === "avatars") _self.object.avatar = res.file;
            if (imgType === "banners") _self.object.banner = res.file;
          })
          .catch(e => {
            console.error(JSON.stringify(e.message));
            resolve(false);
          });
      });
    },
    uploadFiles: async function(imgType) {
      let droppedFiles = event.target.files[0];
      console.log(droppedFiles);
      if (!droppedFiles) return;
      if (droppedFiles.length < 1) return;
      let formData = new FormData();
      formData.append("file", droppedFiles);
      await this.sendFile(formData, imgType);
      if (imgType === "avatars") this.uploadedAvatar = true;
      if (imgType === "banners") this.uploadedBanner = true;
      this.change();
    }
  },
  mounted() {
    this.getEvent();
  }
};
</script>

<style scoped>
</style>
