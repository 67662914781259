export default [
  "1000 BRUSSEL",
  "1020 Laken",
  "1030 SCHAARBEEK",
  "1040 ETTERBEEK",
  "1050 ELSENE",
  "1060 SINT-GILLIS",
  "1070 ANDERLECHT",
  "1080 SINT-JANS-MOLENBEEK",
  "1081 KOEKELBERG",
  "1082 SINT-AGATHA-BERCHEM",
  "1083 GANSHOREN",
  "1090 JETTE",
  "1120 Neder-Over-Heembeek",
  "1130 Haren",
  "1140 EVERE",
  "1150 SINT-PIETERS-WOLUWE",
  "1160 OUDERGEM",
  "1170 WATERMAAL-BOSVOORDE",
  "1180 UKKEL",
  "1190 VORST",
  "1200 SINT-LAMBRECHTS-WOLUWE",
  "1210 SINT-JOOST-TEN-NOODE",
  "1300 Limal",
  "1300 WAVRE",
  "1301 Bierges",
  "1310 LA HULPE",
  "1315 Glimes",
  "1315 INCOURT",
  "1315 Opprebais",
  "1315 Piètrebais",
  "1315 Roux-Miroir",
  "1320 BEAUVECHAIN",
  "1320 Hamme-Mille",
  "1320 L'Ecluse",
  "1320 Nodebais",
  "1320 Tourinnes-La-Grosse",
  "1325 Bonlez",
  "1325 CHAUMONT-GISTOUX",
  "1325 Corroy-Le-Grand",
  "1325 Dion-Valmont",
  "1325 Longueville",
  "1330 RIXENSART",
  "1331 Rosières",
  "1332 Genval",
  "1340 Ottignies",
  "1340 OTTIGNIES-LOUVAIN-LA-NEUVE",
  "1341 Céroux-Mousty",
  "1342 Limelette",
  "1348 Louvain-La-Neuve",
  "1350 Enines",
  "1350 Folx-Les-Caves",
  "1350 Jandrain-Jandrenouille",
  "1350 Jauche",
  "1350 Marilles",
  "1350 Noduwez",
  "1350 ORP-JAUCHE",
  "1350 Orp-Le-Grand",
  "1357 HÉLÉCINE",
  "1357 Linsmeau",
  "1357 Neerheylissem",
  "1357 Opheylissem",
  "1360 Malèves-Sainte-Marie-Wastines",
  "1360 Orbais",
  "1360 PERWEZ",
  "1360 Thorembais-Les-Béguines",
  "1360 Thorembais-Saint-Trond",
  "1367 Autre-Eglise",
  "1367 Bomal",
  "1367 Geest-Gérompont-Petit-Rosière",
  "1367 Gérompont",
  "1367 Grand-Rosière-Hottomont",
  "1367 Huppaye",
  "1367 Mont-Saint-André",
  "1367 RAMILLIES",
  "1370 Dongelberg",
  "1370 Jauchelette",
  "1370 JODOIGNE",
  "1370 Jodoigne-Souveraine",
  "1370 Lathuy",
  "1370 Mélin",
  "1370 Piétrain",
  "1370 Saint-Jean-Geest",
  "1370 Saint-Remy-Geest",
  "1370 Zétrud-Lumay",
  "1380 Couture-Saint-Germain",
  "1380 LASNE",
  "1380 Lasne-Chapelle-Saint-Lambert",
  "1380 Maransart",
  "1380 Ohain",
  "1380 Plancenoit",
  "1390 Archennes",
  "1390 Biez",
  "1390 Bossut-Gottechain",
  "1390 GREZ-DOICEAU",
  "1390 Nethen",
  "1400 Monstreux",
  "1400 NIVELLES",
  "1401 Baulers",
  "1402 Thines",
  "1404 Bornival",
  "1410 WATERLOO",
  "1420 BRAINE-L'ALLEUD",
  "1421 Ophain-Bois-Seigneur-Isaac",
  "1428 Lillois-Witterzée",
  "1430 Bierghes",
  "1430 Quenast",
  "1430 REBECQ",
  "1430 Rebecq-Rognon",
  "1435 Corbais",
  "1435 Hévillers",
  "1435 MONT-SAINT-GUIBERT",
  "1440 BRAINE-LE-CHÂTEAU",
  "1440 Wauthier-Braine",
  "1450 CHASTRE",
  "1450 Chastre-Villeroux-Blanmont",
  "1450 Cortil-Noirmont",
  "1450 Gentinnes",
  "1450 Saint-Géry",
  "1457 Nil-Saint-Vincent-Saint-Martin",
  "1457 Tourinnes-Saint-Lambert",
  "1457 WALHAIN",
  "1457 Walhain-Saint-Paul",
  "1460 ITTRE",
  "1460 Virginal-Samme",
  "1461 Haut-Ittre",
  "1470 Baisy-Thy",
  "1470 Bousval",
  "1470 GENAPPE",
  "1471 Loupoigne",
  "1472 Vieux-Genappe",
  "1473 Glabais",
  "1474 Ways",
  "1476 Houtain-Le-Val",
  "1480 Clabecq",
  "1480 Oisquercq",
  "1480 Saintes",
  "1480 TUBIZE",
  "1490 COURT-SAINT-ETIENNE",
  "1495 Marbais",
  "1495 Mellery",
  "1495 Sart-Dames-Avelines",
  "1495 Tilly",
  "1495 VILLERS-LA-VILLE",
  "1500 HALLE",
  "1501 Buizingen",
  "1502 Lembeek",
  "1540 Herfelingen",
  "1540 HERNE",
  "1541 Sint-Pieters-Kapelle",
  "1547 BEVER",
  "1560 HOEILAART",
  "1570 GALMAARDEN",
  "1570 Tollembeek",
  "1570 Vollezele",
  "1600 Oudenaken",
  "1600 Sint-Laureins-Berchem",
  "1600 SINT-PIETERS-LEEUW",
  "1601 Ruisbroek",
  "1602 Vlezenbeek",
  "1620 DROGENBOS",
  "1630 LINKEBEEK",
  "1640 SINT-GENESIUS-RODE",
  "1650 BEERSEL",
  "1651 Lot",
  "1652 Alsemberg",
  "1653 Dworp",
  "1654 Huizingen",
  "1670 Bogaarden",
  "1670 Heikruis",
  "1670 PEPINGEN",
  "1671 Elingen",
  "1673 Beert",
  "1674 Bellingen",
  "1700 DILBEEK",
  "1700 Sint-Martens-Bodegem",
  "1700 Sint-Ulriks-Kapelle",
  "1701 Itterbeek",
  "1702 Groot-Bijgaarden",
  "1703 Schepdaal",
  "1730 ASSE",
  "1730 Bekkerzeel",
  "1730 Kobbegem",
  "1730 Mollem",
  "1731 Relegem",
  "1731 Zellik",
  "1733 HighCo DATA",
  "1740 TERNAT",
  "1741 Wambeek",
  "1742 Sint-Katherina-Lombeek",
  "1745 Mazenzele",
  "1745 OPWIJK",
  "1750 Gaasbeek",
  "1750 LENNIK",
  "1750 Sint-Kwintens-Lennik",
  "1750 Sint-Martens-Lennik",
  "1755 GOOIK",
  "1755 Kester",
  "1755 Leerbeek",
  "1755 Oetingen",
  "1760 Onze-Lieve-Vrouw-Lombeek",
  "1760 Pamel",
  "1760 ROOSDAAL",
  "1760 Strijtem",
  "1761 Borchtlombeek",
  "1770 LIEDEKERKE",
  "1780 WEMMEL",
  "1785 Brussegem",
  "1785 Hamme",
  "1785 MERCHTEM",
  "1790 AFFLIGEM",
  "1790 Essene",
  "1790 Hekelgem",
  "1790 Teralfene",
  "1800 Peutie",
  "1800 VILVOORDE",
  "1804 Cargovil",
  "1818 VTM",
  "1820 Melsbroek",
  "1820 Perk",
  "1820 STEENOKKERZEEL",
  "1830 MACHELEN",
  "1831 Diegem",
  "1840 LONDERZEEL",
  "1840 Malderen",
  "1840 Steenhuffel",
  "1850 GRIMBERGEN",
  "1851 Humbeek",
  "1852 Beigem",
  "1853 Strombeek-Bever",
  "1860 MEISE",
  "1861 Wolvertem",
  "1880 KAPELLE-OP-DEN-BOS",
  "1880 Nieuwenrode",
  "1880 Ramsdonk",
  "1910 Berg",
  "1910 Buken",
  "1910 KAMPENHOUT",
  "1910 Nederokkerzeel",
  "1930 Nossegem",
  "1930 ZAVENTEM",
  "1931 Brucargo",
  "1932 Sint-Stevens-Woluwe",
  "1933 Sterrebeek",
  "1935 Corporate Village",
  "1950 KRAAINEM",
  "1970 WEZEMBEEK-OPPEM",
  "1980 Eppegem",
  "1980 ZEMST",
  "1981 Hofstade",
  "1982 Elewijt",
  "1982 Weerde",
  "2000 ANTWERPEN",
  "2018 ANTWERPEN",
  "2020 ANTWERPEN",
  "2030 ANTWERPEN",
  "2040 ANTWERPEN",
  "2040 Berendrecht",
  "2040 Lillo",
  "2040 Zandvliet",
  "2050 ANTWERPEN",
  "2060 ANTWERPEN",
  "2070 Burcht",
  "2070 ZWIJNDRECHT",
  "2075 CSM Antwerpen X",
  "2099 Antwerpen X",
  "2100 Deurne",
  "2110 WIJNEGEM",
  "2140 Borgerhout",
  "2150 BORSBEEK",
  "2160 WOMMELGEM",
  "2170 Merksem",
  "2180 Ekeren",
  "2200 HERENTALS",
  "2200 Morkhoven",
  "2200 Noorderwijk",
  "2220 Hallaar",
  "2220 HEIST-OP-DEN-BERG",
  "2221 Booischot",
  "2222 Itegem",
  "2222 Wiekevorst",
  "2223 Schriek",
  "2230 HERSELT",
  "2230 Ramsel",
  "2235 Houtvenne",
  "2235 HULSHOUT",
  "2235 Westmeerbeek",
  "2240 Massenhoven",
  "2240 Viersel",
  "2240 ZANDHOVEN",
  "2242 Pulderbos",
  "2243 Pulle",
  "2250 OLEN",
  "2260 Oevel",
  "2260 Tongerlo",
  "2260 WESTERLO",
  "2260 Zoerle-Parwijs",
  "2270 HERENTHOUT",
  "2275 Gierle",
  "2275 LILLE",
  "2275 Poederlee",
  "2275 Wechelderzande",
  "2280 GROBBENDONK",
  "2288 Bouwel",
  "2290 VORSELAAR",
  "2300 TURNHOUT",
  "2310 RIJKEVORSEL",
  "2320 HOOGSTRATEN",
  "2321 Meer",
  "2322 Minderhout",
  "2323 Wortel",
  "2328 Meerle",
  "2330 MERKSPLAS",
  "2340 BEERSE",
  "2340 Vlimmeren",
  "2350 VOSSELAAR",
  "2360 OUD-TURNHOUT",
  "2370 ARENDONK",
  "2380 RAVELS",
  "2381 Weelde",
  "2382 Poppel",
  "2387 BAARLE-HERTOG",
  "2390 MALLE",
  "2390 Oostmalle",
  "2390 Westmalle",
  "2400 MOL",
  "2430 Eindhout",
  "2430 LAAKDAL",
  "2430 Vorst",
  "2431 Varendonk",
  "2431 Veerle",
  "2440 GEEL",
  "2450 MEERHOUT",
  "2460 KASTERLEE",
  "2460 Lichtaart",
  "2460 Tielen",
  "2470 RETIE",
  "2480 DESSEL",
  "2490 BALEN",
  "2491 Olmen",
  "2500 Koningshooikt",
  "2500 LIER",
  "2520 Broechem",
  "2520 Emblem",
  "2520 Oelegem",
  "2520 RANST",
  "2530 BOECHOUT",
  "2531 Vremde",
  "2540 HOVE",
  "2547 LINT",
  "2550 KONTICH",
  "2550 Waarloos",
  "2560 Bevel",
  "2560 Kessel",
  "2560 NIJLEN",
  "2570 DUFFEL",
  "2580 Beerzel",
  "2580 PUTTE",
  "2590 BERLAAR",
  "2590 Gestel",
  "2600 Berchem",
  "2610 Wilrijk",
  "2620 HEMIKSEM",
  "2627 SCHELLE",
  "2630 AARTSELAAR",
  "2640 MORTSEL",
  "2650 EDEGEM",
  "2660 Hoboken",
  "2800 MECHELEN",
  "2800 Walem",
  "2801 Heffen",
  "2811 Hombeek",
  "2811 Leest",
  "2812 Muizen",
  "2820 BONHEIDEN",
  "2820 Rijmenam",
  "2830 Blaasveld",
  "2830 Heindonk",
  "2830 Tisselt",
  "2830 WILLEBROEK",
  "2840 Reet",
  "2840 RUMST",
  "2840 Terhagen",
  "2845 NIEL",
  "2850 BOOM",
  "2860 SINT-KATELIJNE-WAVER",
  "2861 Onze-Lieve-Vrouw-Waver",
  "2870 Breendonk",
  "2870 Liezele",
  "2870 PUURS",
  "2870 Ruisbroek",
  "2880 BORNEM",
  "2880 Hingene",
  "2880 Mariekerke",
  "2880 Weert",
  "2890 Lippelo",
  "2890 Oppuurs",
  "2890 SINT-AMANDS",
  "2900 SCHOTEN",
  "2910 ESSEN",
  "2920 KALMTHOUT",
  "2930 BRASSCHAAT",
  "2940 Hoevenen",
  "2940 STABROEK",
  "2950 KAPELLEN",
  "2960 BRECHT",
  "2960 Sint-Job-In-'T-Goor",
  "2960 Sint-Lenaarts",
  "2970 'S Gravenwezel",
  "2970 SCHILDE",
  "2980 Halle",
  "2980 ZOERSEL",
  "2990 Loenhout",
  "2990 WUUSTWEZEL",
  "3000 LEUVEN",
  "3001 Heverlee",
  "3010 Kessel Lo",
  "3012 Wilsele",
  "3018 Wijgmaal",
  "3020 HERENT",
  "3020 Veltem-Beisem",
  "3020 Winksele",
  "3040 HULDENBERG",
  "3040 Loonbeek",
  "3040 Neerijse",
  "3040 Ottenburg",
  "3040 Sint-Agatha-Rode",
  "3050 OUD-HEVERLEE",
  "3051 Sint-Joris-Weert",
  "3052 Blanden",
  "3053 Haasrode",
  "3054 Vaalbeek",
  "3060 BERTEM",
  "3060 Korbeek-Dijle",
  "3061 Leefdaal",
  "3070 KORTENBERG",
  "3071 Erps-Kwerps",
  "3078 Everberg",
  "3078 Meerbeek",
  "3080 Duisburg",
  "3080 TERVUREN",
  "3080 Vossem",
  "3090 OVERIJSE",
  "3110 ROTSELAAR",
  "3111 Wezemaal",
  "3118 Werchter",
  "3120 TREMELO",
  "3128 Baal",
  "3130 BEGIJNENDIJK",
  "3130 Betekom",
  "3140 KEERBERGEN",
  "3150 HAACHT",
  "3150 Tildonk",
  "3150 Wespelaar",
  "3190 BOORTMEERBEEK",
  "3191 Hever",
  "3200 AARSCHOT",
  "3200 Gelrode",
  "3201 Langdorp",
  "3202 Rillaar",
  "3210 Linden",
  "3210 LUBBEEK",
  "3211 Binkom",
  "3212 Pellenberg",
  "3220 HOLSBEEK",
  "3220 Kortrijk-Dutsel",
  "3220 Sint-Pieters-Rode",
  "3221 Nieuwrode",
  "3270 Scherpenheuvel",
  "3270 SCHERPENHEUVEL-ZICHEM",
  "3271 Averbode",
  "3271 Zichem",
  "3272 Messelbroek",
  "3272 Testelt",
  "3290 Deurne",
  "3290 DIEST",
  "3290 Schaffen",
  "3290 Webbekom",
  "3293 Kaggevinne",
  "3294 Molenstede",
  "3300 Bost",
  "3300 Goetsenhoven",
  "3300 Hakendover",
  "3300 Kumtich",
  "3300 Oorbeek",
  "3300 Oplinter",
  "3300 Sint-Margriete-Houtem",
  "3300 TIENEN",
  "3300 Vissenaken",
  "3320 HOEGAARDEN",
  "3320 Meldert",
  "3321 Outgaarden",
  "3350 Drieslinter",
  "3350 LINTER",
  "3350 Melkwezer",
  "3350 Neerhespen",
  "3350 Neerlinter",
  "3350 Orsmaal-Gussenhoven",
  "3350 Overhespen",
  "3350 Wommersom",
  "3360 BIERBEEK",
  "3360 Korbeek-Lo",
  "3360 Lovenjoel",
  "3360 Opvelp",
  "3370 BOUTERSEM",
  "3370 Kerkom",
  "3370 Neervelp",
  "3370 Roosbeek",
  "3370 Vertrijk",
  "3370 Willebringen",
  "3380 Bunsbeek",
  "3380 GLABBEEK",
  "3381 Kapellen",
  "3384 Attenrode",
  "3390 Houwaart",
  "3390 Sint-Joris-Winge",
  "3390 Tielt",
  "3390 TIELT-WINGE",
  "3391 Meensel-Kiezegem",
  "3400 Eliksem",
  "3400 Ezemaal",
  "3400 Laar",
  "3400 LANDEN",
  "3400 Neerwinden",
  "3400 Overwinden",
  "3400 Rumsdorp",
  "3400 Wange",
  "3401 Waasmont",
  "3401 Walsbets",
  "3401 Walshoutem",
  "3401 Wezeren",
  "3404 Attenhoven",
  "3404 Neerlanden",
  "3440 Budingen",
  "3440 Dormaal",
  "3440 Halle-Booienhoven",
  "3440 Helen-Bos",
  "3440 ZOUTLEEUW",
  "3450 GEETBETS",
  "3450 Grazen",
  "3454 Rummen",
  "3460 Assent",
  "3460 BEKKEVOORT",
  "3461 Molenbeek-Wersbeek",
  "3470 KORTENAKEN",
  "3470 Ransberg",
  "3470 Sint-Margriete-Houtem",
  "3471 Hoeleden",
  "3472 Kersbeek-Miskom",
  "3473 Waanrode",
  "3500 HASSELT",
  "3500 Sint-Lambrechts-Herk",
  "3501 Wimmertingen",
  "3510 Kermt",
  "3510 Spalbeek",
  "3511 Kuringen",
  "3511 Stokrooie",
  "3512 Stevoort",
  "3520 ZONHOVEN",
  "3530 Helchteren",
  "3530 Houthalen",
  "3530 HOUTHALEN-HELCHTEREN",
  "3540 Berbroek",
  "3540 Donk",
  "3540 HERK-DE-STAD",
  "3540 Schulen",
  "3545 HALEN",
  "3545 Loksbergen",
  "3545 Zelem",
  "3550 Heusden",
  "3550 HEUSDEN-ZOLDER",
  "3550 Zolder",
  "3560 Linkhout",
  "3560 LUMMEN",
  "3560 Meldert",
  "3570 ALKEN",
  "3580 BERINGEN",
  "3581 Beverlo",
  "3582 Koersel",
  "3583 Paal",
  "3590 DIEPENBEEK",
  "3600 GENK",
  "3620 Gellik",
  "3620 LANAKEN",
  "3620 Neerharen",
  "3620 Veldwezelt",
  "3621 Rekem",
  "3630 Eisden",
  "3630 Leut",
  "3630 MAASMECHELEN",
  "3630 Mechelen-Aan-De-Maas",
  "3630 Meeswijk",
  "3630 Opgrimbie",
  "3630 Vucht",
  "3631 Boorsem",
  "3631 Uikhoven",
  "3640 Kessenich",
  "3640 KINROOI",
  "3640 Molenbeersel",
  "3640 Ophoven",
  "3650 Dilsen",
  "3650 DILSEN-STOKKEM",
  "3650 Elen",
  "3650 Lanklaar",
  "3650 Rotem",
  "3650 Stokkem",
  "3660 OPGLABBEEK",
  "3665 AS",
  "3668 Niel-Bij-As",
  "3670 Ellikom",
  "3670 Gruitrode",
  "3670 Meeuwen",
  "3670 MEEUWEN-GRUITRODE",
  "3670 Neerglabbeek",
  "3670 Wijshagen",
  "3680 MAASEIK",
  "3680 Neeroeteren",
  "3680 Opoeteren",
  "3690 ZUTENDAAL",
  "3700 'S Herenelderen",
  "3700 Berg",
  "3700 Diets-Heur",
  "3700 Haren",
  "3700 Henis",
  "3700 Kolmont",
  "3700 Koninksem",
  "3700 Lauw",
  "3700 Mal",
  "3700 Neerrepen",
  "3700 Nerem",
  "3700 Overrepen",
  "3700 Piringen",
  "3700 Riksingen",
  "3700 Rutten",
  "3700 Sluizen",
  "3700 TONGEREN",
  "3700 Vreren",
  "3700 Widooie",
  "3717 HERSTAPPE",
  "3720 KORTESSEM",
  "3721 Vliermaalroot",
  "3722 Wintershoven",
  "3723 Guigoven",
  "3724 Vliermaal",
  "3730 HOESELT",
  "3730 Romershoven",
  "3730 Sint-Huibrechts-Hern",
  "3730 Werm",
  "3732 Schalkhoven",
  "3740 Beverst",
  "3740 BILZEN",
  "3740 Eigenbilzen",
  "3740 Grote-Spouwen",
  "3740 Hees",
  "3740 Kleine-Spouwen",
  "3740 Mopertingen",
  "3740 Munsterbilzen",
  "3740 Rijkhoven",
  "3740 Rosmeer",
  "3740 Waltwilder",
  "3742 Martenslinde",
  "3746 Hoelbeek",
  "3770 Genoelselderen",
  "3770 Herderen",
  "3770 Kanne",
  "3770 Membruggen",
  "3770 Millen",
  "3770 RIEMST",
  "3770 Val-Meer",
  "3770 Vlijtingen",
  "3770 Vroenhoven",
  "3770 Zichen-Zussen-Bolder",
  "3790 Moelingen",
  "3790 Sint-Martens-Voeren",
  "3790 VOEREN",
  "3791 Remersdaal",
  "3792 Sint-Pieters-Voeren",
  "3793 Teuven",
  "3798 S Gravenvoeren",
  "3800 Aalst",
  "3800 Brustem",
  "3800 Engelmanshoven",
  "3800 Gelinden",
  "3800 Groot-Gelmen",
  "3800 Halmaal",
  "3800 Kerkom-Bij-Sint-Truiden",
  "3800 Ordingen",
  "3800 SINT-TRUIDEN",
  "3800 Zepperen",
  "3803 Duras",
  "3803 Gorsem",
  "3803 Runkelen",
  "3803 Wilderen",
  "3806 Velm",
  "3830 Berlingen",
  "3830 WELLEN",
  "3831 Herten",
  "3832 Ulbeek",
  "3840 Bommershoven",
  "3840 BORGLOON",
  "3840 Broekom",
  "3840 Gors-Opleeuw",
  "3840 Gotem",
  "3840 Groot-Loon",
  "3840 Haren",
  "3840 Hendrieken",
  "3840 Hoepertingen",
  "3840 Jesseren",
  "3840 Kerniel",
  "3840 Kolmont",
  "3840 Kuttekoven",
  "3840 Rijkel",
  "3840 Voort",
  "3850 Binderveld",
  "3850 Kozen",
  "3850 NIEUWERKERKEN",
  "3850 Wijer",
  "3870 Batsheers",
  "3870 Bovelingen",
  "3870 Gutshoven",
  "3870 HEERS",
  "3870 Heks",
  "3870 Horpmaal",
  "3870 Klein-Gelmen",
  "3870 Mechelen-Bovelingen",
  "3870 Mettekoven",
  "3870 Opheers",
  "3870 Rukkelingen-Loon",
  "3870 Vechmaal",
  "3870 Veulen",
  "3890 Boekhout",
  "3890 GINGELOM",
  "3890 Jeuk",
  "3890 Kortijs",
  "3890 Montenaken",
  "3890 Niel-Bij-Sint-Truiden",
  "3890 Vorsen",
  "3891 Borlo",
  "3891 Buvingen",
  "3891 Mielen-Boven-Aalst",
  "3891 Muizen",
  "3900 OVERPELT",
  "3910 NEERPELT",
  "3910 Sint-Huibrechts-Lille",
  "3920 LOMMEL",
  "3930 Achel",
  "3930 Hamont",
  "3930 HAMONT-ACHEL",
  "3940 Hechtel",
  "3940 HECHTEL-EKSEL",
  "3941 Eksel",
  "3945 HAM",
  "3945 Kwaadmechelen",
  "3945 Oostham",
  "3950 BOCHOLT",
  "3950 Kaulille",
  "3950 Reppel",
  "3960 Beek",
  "3960 BREE",
  "3960 Gerdingen",
  "3960 Opitter",
  "3960 Tongerlo",
  "3970 LEOPOLDSBURG",
  "3971 Heppen",
  "3980 TESSENDERLO",
  "3990 Grote-Brogel",
  "3990 Kleine-Brogel",
  "3990 PEER",
  "3990 Wijchmaal",
  "4000 Glain",
  "4000 LIÈGE",
  "4000 Rocourt",
  "4020 Bressoux",
  "4020 Jupille-Sur-Meuse",
  "4020 LIÈGE",
  "4020 Wandre",
  "4030 Grivegnee",
  "4031 Angleur",
  "4032 Chênee",
  "4040 HERSTAL",
  "4041 Milmort",
  "4041 Vottem",
  "4042 Liers",
  "4050 CHAUDFONTAINE",
  "4051 Vaux-Sous-Chèvremont",
  "4052 Beaufays",
  "4053 Embourg",
  "4075 CSM Liege X",
  "4099 Liège X",
  "4100 Boncelles",
  "4100 SERAING",
  "4101 Jemeppe-Sur-Meuse",
  "4102 Ougrée",
  "4120 Ehein",
  "4120 NEUPRÉ",
  "4120 Rotheux-Rimière",
  "4121 Neuville-En-Condroz",
  "4122 Plainevaux",
  "4130 ESNEUX",
  "4130 Tilff",
  "4140 Dolembreux",
  "4140 Gomzé-Andoumont",
  "4140 Rouvreux",
  "4140 SPRIMONT",
  "4141 Louveigné",
  "4160 ANTHISNES",
  "4161 Villers-Aux-Tours",
  "4162 Hody",
  "4163 Tavier",
  "4170 COMBLAIN-AU-PONT",
  "4171 Poulseur",
  "4180 Comblain-Fairon",
  "4180 Comblain-La-Tour",
  "4180 HAMOIR",
  "4181 Filot",
  "4190 FERRIÈRES",
  "4190 My",
  "4190 Vieuxville",
  "4190 Werbomont",
  "4190 Xhoris",
  "4210 BURDINNE",
  "4210 Hannêche",
  "4210 Lamontzée",
  "4210 Marneffe",
  "4210 Oteppe",
  "4217 HÉRON",
  "4217 Lavoir",
  "4217 Waret-L'Evêque",
  "4218 Couthuin",
  "4219 Acosse",
  "4219 Ambresin",
  "4219 Meeffe",
  "4219 WASSEIGES",
  "4250 Boëlhe",
  "4250 GEER",
  "4250 Hollogne-Sur-Geer",
  "4250 Lens-Saint-Servais",
  "4252 Omal",
  "4253 Darion",
  "4254 Ligney",
  "4257 BERLOZ",
  "4257 Corswarem",
  "4257 Rosoux-Crenwick",
  "4260 Avennes",
  "4260 BRAIVES",
  "4260 Ciplet",
  "4260 Fallais",
  "4260 Fumal",
  "4260 Ville-En-Hesbaye",
  "4261 Latinne",
  "4263 Tourinne",
  "4280 Abolens",
  "4280 Avernas-Le-Bauduin",
  "4280 Avin",
  "4280 Bertrée",
  "4280 Blehen",
  "4280 Cras-Avernas",
  "4280 Crehen",
  "4280 Grand-Hallet",
  "4280 HANNUT",
  "4280 Lens-Saint-Remy",
  "4280 Merdorp",
  "4280 Moxhe",
  "4280 Petit-Hallet",
  "4280 Poucet",
  "4280 Thisnes",
  "4280 Trognée",
  "4280 Villers-Le-Peuplier",
  "4280 Wansin",
  "4287 LINCENT",
  "4287 Pellaines",
  "4287 Racour",
  "4300 Bettincourt",
  "4300 Bleret",
  "4300 Bovenistier",
  "4300 Grand-Axhe",
  "4300 Lantremange",
  "4300 Oleye",
  "4300 WAREMME",
  "4317 Aineffe",
  "4317 Borlez",
  "4317 Celles",
  "4317 FAIMES",
  "4317 Les Waleffes",
  "4317 Viemme",
  "4340 AWANS",
  "4340 Fooz",
  "4340 Othée",
  "4340 Villers-L'Evêque",
  "4342 Hognoul",
  "4347 FEXHE-LE-HAUT-CLOCHER",
  "4347 Freloux",
  "4347 Noville",
  "4347 Roloux",
  "4347 Voroux-Goreux",
  "4350 Lamine",
  "4350 Momalle",
  "4350 Pousset",
  "4350 REMICOURT",
  "4351 Hodeige",
  "4357 DONCEEL",
  "4357 Haneffe",
  "4357 Jeneffe",
  "4357 Limont",
  "4360 Bergilers",
  "4360 Grandville",
  "4360 Lens-Sur-Geer",
  "4360 OREYE",
  "4360 Otrange",
  "4367 CRISNÉE",
  "4367 Fize-Le-Marsal",
  "4367 Kemexhe",
  "4367 Odeur",
  "4367 Thys",
  "4400 Awirs",
  "4400 Chokier",
  "4400 FLÉMALLE",
  "4400 Flémalle-Grande",
  "4400 Flémalle-Haute",
  "4400 Gleixhe",
  "4400 Ivoz-Ramet",
  "4400 Mons-Lez-Liège",
  "4420 Montegnée",
  "4420 SAINT-NICOLAS",
  "4420 Tilleur",
  "4430 ANS",
  "4431 Loncin",
  "4432 Alleur",
  "4432 Xhendremael",
  "4450 JUPRELLE",
  "4450 Lantin",
  "4450 Slins",
  "4451 Voroux-Lez-Liers",
  "4452 Paifve",
  "4452 Wihogne",
  "4453 Villers-Saint-Siméon",
  "4458 Fexhe-Slins",
  "4460 Bierset",
  "4460 Grâce-Berleur",
  "4460 GRÂCE-HOLLOGNE",
  "4460 Hollogne-Aux-Pierres",
  "4460 Horion-Hozémont",
  "4460 Velroux",
  "4470 SAINT-GEORGES-SUR-MEUSE",
  "4480 Clermont-Sous-Huy",
  "4480 Ehein",
  "4480 ENGIS",
  "4480 Hermalle-Sous-Huy",
  "4500 Ben-Ahin",
  "4500 HUY",
  "4500 Tihange",
  "4520 Antheit",
  "4520 Bas-Oha",
  "4520 Huccorgne",
  "4520 Moha",
  "4520 Vinalmont",
  "4520 WANZE",
  "4530 Fize-Fontaine",
  "4530 Vaux-Et-Borset",
  "4530 Vieux-Waleffe",
  "4530 VILLERS-LE-BOUILLET",
  "4530 Warnant-Dreye",
  "4537 Bodegnée",
  "4537 Chapon-Seraing",
  "4537 Seraing-Le-Château",
  "4537 VERLAINE",
  "4540 AMAY",
  "4540 Ampsin",
  "4540 Flône",
  "4540 Jehay",
  "4540 Ombret",
  "4550 NANDRIN",
  "4550 Saint-Séverin",
  "4550 Villers-Le-Temple",
  "4550 Yernée-Fraineux",
  "4557 Abée",
  "4557 Fraiture",
  "4557 Ramelot",
  "4557 Seny",
  "4557 Soheit-Tinlot",
  "4557 TINLOT",
  "4560 Bois-Et-Borsu",
  "4560 CLAVIER",
  "4560 Les Avins",
  "4560 Ocquier",
  "4560 Pailhe",
  "4560 Terwagne",
  "4570 MARCHIN",
  "4570 Vyle-Et-Tharoul",
  "4577 MODAVE",
  "4577 Outrelouxhe",
  "4577 Strée-Lez-Huy",
  "4577 Vierset-Barse",
  "4590 Ellemelle",
  "4590 OUFFET",
  "4590 Warzée",
  "4600 Lanaye",
  "4600 Lixhe",
  "4600 Richelle",
  "4600 VISÉ",
  "4601 Argenteau",
  "4602 Cheratte",
  "4606 Saint-André",
  "4607 Berneau",
  "4607 Bombaye",
  "4607 DALHEM",
  "4607 Feneur",
  "4607 Mortroux",
  "4608 Neufchâteau",
  "4608 Warsage",
  "4610 Bellaire",
  "4610 BEYNE-HEUSAY",
  "4610 Queue-Du-Bois",
  "4620 FLÉRON",
  "4621 Retinne",
  "4623 Magnée",
  "4624 Romsée",
  "4630 Ayeneux",
  "4630 Micheroux",
  "4630 SOUMAGNE",
  "4630 Tignée",
  "4631 Evegnée",
  "4632 Cérexhe-Heuseux",
  "4633 Melen",
  "4650 Chaineux",
  "4650 Grand-Rechain",
  "4650 HERVE",
  "4650 Julémont",
  "4651 Battice",
  "4652 Xhendelesse",
  "4653 Bolland",
  "4654 Charneux",
  "4670 BLÉGNY",
  "4670 Mortier",
  "4670 Trembleur",
  "4671 Barchon",
  "4671 Housse",
  "4671 Saive",
  "4672 Saint-Remy",
  "4680 Hermée",
  "4680 OUPEYE",
  "4681 Hermalle-Sous-Argenteau",
  "4682 Heure-Le-Romain",
  "4682 Houtain-Saint-Siméon",
  "4683 Vivegnis",
  "4684 Haccourt",
  "4690 BASSENGE",
  "4690 Boirs",
  "4690 Eben-Emael",
  "4690 Glons",
  "4690 Roclenge-Sur-Geer",
  "4690 Wonck",
  "4700 EUPEN",
  "4701 Kettenis",
  "4710 LONTZEN",
  "4711 Walhorn",
  "4720 KELMIS",
  "4721 Neu-Moresnet",
  "4728 Hergenrath",
  "4730 Hauset",
  "4730 RAEREN",
  "4731 Eynatten",
  "4750 BUTGENBACH",
  "4750 Elsenborn",
  "4760 BÜLLINGEN",
  "4760 Manderfeld",
  "4761 Rocherath",
  "4770 AMEL",
  "4770 Meyerode",
  "4771 Heppenbach",
  "4780 Recht",
  "4780 SANKT-VITH",
  "4782 Schönberg",
  "4783 Lommersweiler",
  "4784 Crombach",
  "4790 BURG-REULAND",
  "4790 Reuland",
  "4791 Thommen",
  "4800 Ensival",
  "4800 Lambermont",
  "4800 Petit-Rechain",
  "4800 Polleur",
  "4800 VERVIERS",
  "4801 Stembert",
  "4802 Heusy",
  "4820 DISON",
  "4821 Andrimont",
  "4830 LIMBOURG",
  "4831 Bilstain",
  "4834 Goé",
  "4837 BAELEN",
  "4837 Membach",
  "4840 WELKENRAEDT",
  "4841 Henri-Chapelle",
  "4845 JALHAY",
  "4845 Sart-Lez-Spa",
  "4850 Montzen",
  "4850 Moresnet",
  "4850 PLOMBIÈRES",
  "4851 Gemmenich",
  "4851 Sippenaeken",
  "4852 Hombourg",
  "4860 Cornesse",
  "4860 PEPINSTER",
  "4860 Wegnez",
  "4861 Soiron",
  "4870 Forêt",
  "4870 Fraipont",
  "4870 Nessonvaux",
  "4870 TROOZ",
  "4877 OLNE",
  "4880 AUBEL",
  "4890 Clermont",
  "4890 Thimister",
  "4890 THIMISTER-CLERMONT",
  "4900 SPA",
  "4910 La Reid",
  "4910 Polleur",
  "4910 THEUX",
  "4920 AYWAILLE",
  "4920 Ernonheid",
  "4920 Harzé",
  "4920 Louveigné",
  "4920 Sougné-Remouchamps",
  "4950 Faymonville",
  "4950 Robertville",
  "4950 Sourbrodt",
  "4950 WEISMES",
  "4960 Bellevaux-Ligneuville",
  "4960 Bevercé",
  "4960 MALMEDY",
  "4970 Francorchamps",
  "4970 STAVELOT",
  "4980 Fosse",
  "4980 TROIS-PONTS",
  "4980 Wanne",
  "4983 Basse-Bodeux",
  "4987 Chevron",
  "4987 La Gleize",
  "4987 Lorcé",
  "4987 Rahier",
  "4987 STOUMONT",
  "4990 Arbrefontaine",
  "4990 Bra",
  "4990 LIERNEUX",
  "5000 Beez",
  "5000 NAMUR",
  "5001 Belgrade",
  "5002 Saint-Servais",
  "5003 Saint-Marc",
  "5004 Bouge",
  "5010 SA SudPresse",
  "5012 Parlement Wallon",
  "5020 Champion",
  "5020 Daussoulx",
  "5020 Flawinne",
  "5020 Malonne",
  "5020 Suarlée",
  "5020 Temploux",
  "5020 Vedrin",
  "5021 Boninne",
  "5022 Cognelée",
  "5024 Gelbressée",
  "5024 Marche-Les-Dames",
  "5030 Beuzet",
  "5030 Ernage",
  "5030 GEMBLOUX",
  "5030 Grand-Manil",
  "5030 Lonzée",
  "5030 Sauvenière",
  "5031 Grand-Leez",
  "5032 Bossière",
  "5032 Bothey",
  "5032 Corroy-Le-Château",
  "5032 Isnes",
  "5032 Mazy",
  "5060 Arsimont",
  "5060 Auvelais",
  "5060 Falisolle",
  "5060 Keumiée",
  "5060 Moignelée",
  "5060 SAMBREVILLE",
  "5060 Tamines",
  "5060 Velaine-Sur-Sambre",
  "5070 Aisemont",
  "5070 FOSSES-LA-VILLE",
  "5070 Le Roux",
  "5070 Sart-Eustache",
  "5070 Sart-Saint-Laurent",
  "5070 Vitrival",
  "5080 Emines",
  "5080 LA BRUYÈRE",
  "5080 Rhisnes",
  "5080 Villers-Lez-Heest",
  "5080 Warisoulx",
  "5081 Bovesse",
  "5081 Meux",
  "5081 Saint-Denis-Bovesse",
  "5100 Dave",
  "5100 Jambes",
  "5100 Naninne",
  "5100 Wépion",
  "5100 Wierde",
  "5101 Erpent",
  "5101 Lives-Sur-Meuse",
  "5101 Loyers",
  "5140 Boignée",
  "5140 Ligny",
  "5140 SOMBREFFE",
  "5140 Tongrinne",
  "5150 FLOREFFE",
  "5150 Floriffoux",
  "5150 Franière",
  "5150 Soye",
  "5170 Arbre",
  "5170 Bois-De-Villers",
  "5170 Lesve",
  "5170 Lustin",
  "5170 PROFONDEVILLE",
  "5170 Rivière",
  "5190 Balâtre",
  "5190 Ham-Sur-Sambre",
  "5190 JEMEPPE-SUR-SAMBRE",
  "5190 Mornimont",
  "5190 Moustier-Sur-Sambre",
  "5190 Onoz",
  "5190 Saint-Martin",
  "5190 Spy",
  "5300 ANDENNE",
  "5300 Bonneville",
  "5300 Coutisse",
  "5300 Landenne",
  "5300 Maizeret",
  "5300 Namêche",
  "5300 Sclayn",
  "5300 Seilles",
  "5300 Thon",
  "5300 Vezin",
  "5310 Aische-En-Refail",
  "5310 Bolinne",
  "5310 Boneffe",
  "5310 Branchon",
  "5310 Dhuy",
  "5310 EGHEZÉE",
  "5310 Hanret",
  "5310 Leuze",
  "5310 Liernu",
  "5310 Longchamps",
  "5310 Mehaigne",
  "5310 Noville-Sur-Méhaigne",
  "5310 Saint-Germain",
  "5310 Taviers",
  "5310 Upigny",
  "5310 Waret-La-Chaussée",
  "5330 ASSESSE",
  "5330 Maillen",
  "5330 Sart-Bernard",
  "5332 Crupet",
  "5333 Sorinne-La-Longue",
  "5334 Florée",
  "5336 Courrière",
  "5340 Faulx-Les-Tombes",
  "5340 GESVES",
  "5340 Haltinne",
  "5340 Mozet",
  "5340 Sorée",
  "5350 Evelette",
  "5350 OHEY",
  "5351 Haillot",
  "5352 Perwez-Haillot",
  "5353 Goesnes",
  "5354 Jallet",
  "5360 HAMOIS",
  "5360 Natoye",
  "5361 Mohiville",
  "5361 Scy",
  "5362 Achet",
  "5363 Emptinne",
  "5364 Schaltin",
  "5370 Barvaux-Condroz",
  "5370 Flostoy",
  "5370 HAVELANGE",
  "5370 Jeneffe",
  "5370 Porcheresse",
  "5370 Verlée",
  "5372 Méan",
  "5374 Maffe",
  "5376 Miécret",
  "5377 Baillonville",
  "5377 Bonsin",
  "5377 Heure",
  "5377 Hogne",
  "5377 Nettinne",
  "5377 Noiseux",
  "5377 Sinsin",
  "5377 SOMME-LEUZE",
  "5377 Waillet",
  "5380 Bierwart",
  "5380 Cortil-Wodon",
  "5380 FERNELMONT",
  "5380 Forville",
  "5380 Franc-Waret",
  "5380 Hemptinne",
  "5380 Hingeon",
  "5380 Marchovelette",
  "5380 Noville-Les-Bois",
  "5380 Pontillas",
  "5380 Tillier",
  "5500 Anseremme",
  "5500 Bouvignes-Sur-Meuse",
  "5500 DINANT",
  "5500 Dréhance",
  "5500 Falmagne",
  "5500 Falmignoul",
  "5500 Furfooz",
  "5501 Lisogne",
  "5502 Thynes",
  "5503 Sorinnes",
  "5504 Foy-Notre-Dame",
  "5520 Anthée",
  "5520 ONHAYE",
  "5521 Serville",
  "5522 Falaen",
  "5523 Sommière",
  "5523 Weillen",
  "5524 Gerin",
  "5530 Dorinne",
  "5530 Durnal",
  "5530 Evrehailles",
  "5530 Godinne",
  "5530 Houx",
  "5530 Mont",
  "5530 Purnode",
  "5530 Spontin",
  "5530 YVOIR",
  "5537 ANHÉE",
  "5537 Annevoie-Rouillon",
  "5537 Bioul",
  "5537 Denée",
  "5537 Haut-Le-Wastia",
  "5537 Sosoye",
  "5537 Warnant",
  "5540 HASTIÈRE",
  "5540 Hastière-Lavaux",
  "5540 Hermeton-Sur-Meuse",
  "5540 Waulsort",
  "5541 Hastière-Par-Delà",
  "5542 Blaimont",
  "5543 Heer",
  "5544 Agimont",
  "5550 Alle",
  "5550 Bagimont",
  "5550 Bohan",
  "5550 Chairière",
  "5550 Laforet",
  "5550 Membre",
  "5550 Mouzaive",
  "5550 Nafraiture",
  "5550 Orchimont",
  "5550 Pussemange",
  "5550 Sugny",
  "5550 VRESSE-SUR-SEMOIS",
  "5555 Baillamont",
  "5555 Bellefontaine",
  "5555 BIÈVRE",
  "5555 Cornimont",
  "5555 Graide",
  "5555 Gros-Fays",
  "5555 Monceau-En-Ardenne",
  "5555 Naomé",
  "5555 Oizy",
  "5555 Petit-Fays",
  "5560 Ciergnon",
  "5560 Finnevaux",
  "5560 HOUYET",
  "5560 Hulsonniaux",
  "5560 Mesnil-Eglise",
  "5560 Mesnil-Saint-Blaise",
  "5561 Celles",
  "5562 Custinne",
  "5563 Hour",
  "5564 Wanlin",
  "5570 Baronville",
  "5570 BEAURAING",
  "5570 Dion",
  "5570 Felenne",
  "5570 Feschaux",
  "5570 Honnay",
  "5570 Javingue",
  "5570 Vonêche",
  "5570 Wancennes",
  "5570 Winenne",
  "5571 Wiesme",
  "5572 Focant",
  "5573 Martouzin-Neuville",
  "5574 Pondrôme",
  "5575 Bourseigne-Neuve",
  "5575 Bourseigne-Vieille",
  "5575 GEDINNE",
  "5575 Houdremont",
  "5575 Louette-Saint-Denis",
  "5575 Louette-Saint-Pierre",
  "5575 Malvoisin",
  "5575 Patignies",
  "5575 Rienne",
  "5575 Sart-Custinne",
  "5575 Vencimont",
  "5575 Willerzie",
  "5576 Froidfontaine",
  "5580 Ave-Et-Auffe",
  "5580 Buissonville",
  "5580 Eprave",
  "5580 Han-Sur-Lesse",
  "5580 Jemelle",
  "5580 Lavaux-Saint-Anne",
  "5580 Lessive",
  "5580 Mont-Gauthier",
  "5580 ROCHEFORT",
  "5580 Villers-Sur-Lesse",
  "5580 Wavreille",
  "5589 Jemelle",
  "5590 Achêne",
  "5590 Braibant",
  "5590 Chevetogne",
  "5590 CINEY",
  "5590 Conneux",
  "5590 Haversin",
  "5590 Leignon",
  "5590 Pessoux",
  "5590 Serinchamps",
  "5590 Sovet",
  "5600 Fagnolle",
  "5600 Franchimont",
  "5600 Jamagne",
  "5600 Jamiolle",
  "5600 Merlemont",
  "5600 Neuville",
  "5600 Omezée",
  "5600 PHILIPPEVILLE",
  "5600 Roly",
  "5600 Romedenne",
  "5600 Samart",
  "5600 Sart-En-Fagne",
  "5600 Sautour",
  "5600 Surice",
  "5600 Villers-En-Fagne",
  "5600 Villers-Le-Gambon",
  "5600 Vodecée",
  "5620 Corenne",
  "5620 Flavion",
  "5620 FLORENNES",
  "5620 Hemptinne-Lez-Florennes",
  "5620 Morville",
  "5620 Rosée",
  "5620 Saint-Aubin",
  "5621 Hanzinelle",
  "5621 Hanzinne",
  "5621 Morialmé",
  "5621 Thy-Le-Baudouin",
  "5630 CERFONTAINE",
  "5630 Daussois",
  "5630 Senzeille",
  "5630 Silenrieux",
  "5630 Soumoy",
  "5630 Villers-Deux-Eglises",
  "5640 Biesme",
  "5640 Biesmerée",
  "5640 Graux",
  "5640 METTET",
  "5640 Oret",
  "5640 Saint-Gérard",
  "5641 Furnaux",
  "5644 Ermeton-Sur-Biert",
  "5646 Stave",
  "5650 Castillon",
  "5650 Chastrès",
  "5650 Clermont",
  "5650 Fontenelle",
  "5650 Fraire",
  "5650 Pry",
  "5650 Vogenée",
  "5650 WALCOURT",
  "5650 Yves-Gomezée",
  "5651 Berzée",
  "5651 Gourdinne",
  "5651 Laneffe",
  "5651 Rognée",
  "5651 Somzée",
  "5651 Tarcienne",
  "5651 Thy-Le-Château",
  "5660 Aublain",
  "5660 Boussu-En-Fagne",
  "5660 Brûly",
  "5660 Brûly-De-Pesche",
  "5660 COUVIN",
  "5660 Cul-Des-Sarts",
  "5660 Dailly",
  "5660 Frasnes",
  "5660 Gonrieux",
  "5660 Mariembourg",
  "5660 Pesche",
  "5660 Petigny",
  "5660 Petite-Chapelle",
  "5660 Presgaux",
  "5670 Dourbes",
  "5670 Le Mesnil",
  "5670 Mazée",
  "5670 Nismes",
  "5670 Oignies-En-Thiérache",
  "5670 Olloy-Sur-Viroin",
  "5670 Treignes",
  "5670 Vierves-Sur-Viroin",
  "5670 VIROINVAL",
  "5680 DOISCHE",
  "5680 Gimnée",
  "5680 Gochenée",
  "5680 Matagne-La-Grande",
  "5680 Matagne-La-Petite",
  "5680 Niverlée",
  "5680 Romerée",
  "5680 Soulme",
  "5680 Vaucelles",
  "5680 Vodelée",
  "6000 CHARLEROI",
  "6001 Marcinelle",
  "6010 Couillet",
  "6020 Dampremy",
  "6030 Goutroux",
  "6030 Marchienne-Au-Pont",
  "6031 Monceau-Sur-Sambre",
  "6032 Mont-Sur-Marchienne",
  "6040 Jumet",
  "6041 Gosselies",
  "6042 Lodelinsart",
  "6043 Ransart",
  "6044 Roux",
  "6060 Gilly",
  "6061 Montignies-Sur-Sambre",
  "6075 CSM Charleroi X",
  "6099 Charleroi X",
  "6110 MONTIGNY-LE-TILLEUL",
  "6111 Landelies",
  "6120 Cour-Sur-Heure",
  "6120 HAM-SUR-HEURE",
  "6120 Jamioulx",
  "6120 Marbaix",
  "6120 Nalinnes",
  "6140 FONTAINE-L'EVÊQUE",
  "6141 Forchies-La-Marche",
  "6142 Leernes",
  "6150 ANDERLUES",
  "6180 COURCELLES",
  "6181 Gouy-Lez-Piéton",
  "6182 Souvret",
  "6183 Trazegnies",
  "6200 Bouffioulx",
  "6200 CHÂTELET",
  "6200 Châtelineau",
  "6210 Frasnes-Lez-Gosselies",
  "6210 LES BONS VILLERS",
  "6210 Rèves",
  "6210 Villers-Perwin",
  "6210 Wayaux",
  "6211 Mellet",
  "6220 FLEURUS",
  "6220 Heppignies",
  "6220 Lambusart",
  "6220 Wangenies",
  "6221 Saint-Amand",
  "6222 Brye",
  "6223 Wagnelée",
  "6224 Wanfercée-Baulet",
  "6230 Buzet",
  "6230 Obaix",
  "6230 PONT-À-CELLES",
  "6230 Thiméon",
  "6230 Viesville",
  "6238 Liberchies",
  "6238 Luttre",
  "6240 FARCIENNES",
  "6240 Pironchamps",
  "6250 Aiseau",
  "6250 AISEAU-PRESLES",
  "6250 Pont-De-Loup",
  "6250 Presles",
  "6250 Roselies",
  "6280 Acoz",
  "6280 GERPINNES",
  "6280 Gougnies",
  "6280 Joncret",
  "6280 Loverval",
  "6280 Villers-Poterie",
  "6440 Boussu-Lez-Walcourt",
  "6440 Fourbechies",
  "6440 FROIDCHAPELLE",
  "6440 Vergnies",
  "6441 Erpion",
  "6460 Bailièvre",
  "6460 CHIMAY",
  "6460 Robechies",
  "6460 Saint-Remy",
  "6460 Salles",
  "6460 Villers-La-Tour",
  "6461 Virelles",
  "6462 Vaulx-Lez-Chimay",
  "6463 Lompret",
  "6464 Baileux",
  "6464 Bourlers",
  "6464 Forges",
  "6464 L'Escaillère",
  "6464 Rièzes",
  "6470 Grandrieu",
  "6470 Montbliart",
  "6470 Rance",
  "6470 Sautin",
  "6470 Sivry",
  "6470 SIVRY-RANCE",
  "6500 Barbençon",
  "6500 BEAUMONT",
  "6500 Leugnies",
  "6500 Leval-Chaudeville",
  "6500 Renlies",
  "6500 Solre-Saint-Géry",
  "6500 Thirimont",
  "6511 Strée",
  "6530 Leers-Et-Fosteau",
  "6530 THUIN",
  "6531 Biesme-Sous-Thuin",
  "6532 Ragnies",
  "6533 Biercée",
  "6534 Gozée",
  "6536 Donstiennes",
  "6536 Thuillies",
  "6540 LOBBES",
  "6540 Mont-Sainte-Geneviève",
  "6542 Sars-La-Buissière",
  "6543 Bienne-Lez-Happart",
  "6560 Bersillies-L'Abbaye",
  "6560 ERQUELINNES",
  "6560 Grand-Reng",
  "6560 Hantes-Wihéries",
  "6560 Montignies-Saint-Christophe",
  "6560 Solre-Sur-Sambre",
  "6567 Fontaine-Valmont",
  "6567 Labuissière",
  "6567 MERBES-LE-CHÂTEAU",
  "6567 Merbes-Sainte-Marie",
  "6590 MOMIGNIES",
  "6591 Macon",
  "6592 Monceau-Imbrechies",
  "6593 Macquenoise",
  "6594 Beauwelz",
  "6596 Forge-Philippe",
  "6596 Seloignes",
  "6600 BASTOGNE",
  "6600 Longvilly",
  "6600 Noville",
  "6600 Villers-La-Bonne-Eau",
  "6600 Wardin",
  "6630 MARTELANGE",
  "6637 FAUVILLERS",
  "6637 Hollange",
  "6637 Tintange",
  "6640 Hompré",
  "6640 Morhet",
  "6640 Nives",
  "6640 Sibret",
  "6640 Vaux-Lez-Rosières",
  "6640 VAUX-SUR-SÛRE",
  "6642 Juseret",
  "6660 HOUFFALIZE",
  "6660 Nadrin",
  "6661 Mont",
  "6661 Tailles",
  "6662 Tavigny",
  "6663 Mabompré",
  "6666 Wibrin",
  "6670 GOUVY",
  "6670 Limerlé",
  "6671 Bovigny",
  "6672 Beho",
  "6673 Cherain",
  "6674 Montleban",
  "6680 Amberloup",
  "6680 SAINTE-ODE",
  "6680 Tillet",
  "6681 Lavacherie",
  "6686 Flamierge",
  "6687 BERTOGNE",
  "6688 Longchamps",
  "6690 Bihain",
  "6690 VIELSALM",
  "6692 Petit-Thier",
  "6698 Grand-Halleux",
  "6700 ARLON",
  "6700 Bonnert",
  "6700 Heinsch",
  "6700 Toernich",
  "6704 Guirsch",
  "6706 Autelbas",
  "6717 ATTERT",
  "6717 Nobressart",
  "6717 Nothomb",
  "6717 Thiaumont",
  "6717 Tontelange",
  "6720 HABAY",
  "6720 Habay-La-Neuve",
  "6720 Hachy",
  "6721 Anlier",
  "6723 Habay-La-Vieille",
  "6724 Houdemont",
  "6724 Rulles",
  "6730 Bellefontaine",
  "6730 Rossignol",
  "6730 Saint-Vincent",
  "6730 TINTIGNY",
  "6740 ETALLE",
  "6740 Sainte-Marie-Sur-Semois",
  "6740 Villers-Sur-Semois",
  "6741 Vance",
  "6742 Chantemelle",
  "6743 Buzenol",
  "6747 Châtillon",
  "6747 Meix-Le-Tige",
  "6747 SAINT-LÉGER",
  "6750 MUSSON",
  "6750 Mussy-La-Ville",
  "6750 Signeulx",
  "6760 Bleid",
  "6760 Ethe",
  "6760 Ruette",
  "6760 VIRTON",
  "6761 Latour",
  "6762 Saint-Mard",
  "6767 Dampicourt",
  "6767 Harnoncourt",
  "6767 Lamorteau",
  "6767 ROUVROY",
  "6767 Torgny",
  "6769 Gérouville",
  "6769 MEIX-DEVANT-VIRTON",
  "6769 Robelmont",
  "6769 Sommethonne",
  "6769 Villers-La-Loue",
  "6780 Hondelange",
  "6780 MESSANCY",
  "6780 Wolkrange",
  "6781 Sélange",
  "6782 Habergy",
  "6790 AUBANGE",
  "6791 Athus",
  "6792 Halanzy",
  "6792 Rachecourt",
  "6800 Bras",
  "6800 Freux",
  "6800 LIBRAMONT-CHEVIGNY",
  "6800 Moircy",
  "6800 Recogne",
  "6800 Remagne",
  "6800 Saint-Pierre",
  "6800 Sainte-Marie-Chevigny",
  "6810 CHINY",
  "6810 Izel",
  "6810 Jamoigne",
  "6811 Les Bulles",
  "6812 Suxy",
  "6813 Termes",
  "6820 FLORENVILLE",
  "6820 Fontenoille",
  "6820 Muno",
  "6820 Sainte-Cécile",
  "6821 Lacuisine",
  "6823 Villers-Devant-Orval",
  "6824 Chassepierre",
  "6830 BOUILLON",
  "6830 Les Hayons",
  "6830 Poupehan",
  "6830 Rochehaut",
  "6831 Noirfontaine",
  "6832 Sensenruth",
  "6833 Ucimont",
  "6833 Vivy",
  "6834 Bellevaux",
  "6836 Dohan",
  "6838 Corbion",
  "6840 Grandvoir",
  "6840 Grapfontaine",
  "6840 Hamipré",
  "6840 Longlier",
  "6840 NEUFCHÂTEAU",
  "6840 Tournay",
  "6850 Carlsbourg",
  "6850 Offagne",
  "6850 PALISEUL",
  "6851 Nollevaux",
  "6852 Maissin",
  "6852 Opont",
  "6853 Framont",
  "6856 Fays-Les-Veneurs",
  "6860 Assenois",
  "6860 Ebly",
  "6860 LÉGLISE",
  "6860 Mellier",
  "6860 Witry",
  "6870 Arville",
  "6870 Awenne",
  "6870 Hatrival",
  "6870 Mirwart",
  "6870 SAINT-HUBERT",
  "6870 Vesqueville",
  "6880 Auby-Sur-Semois",
  "6880 BERTRIX",
  "6880 Cugnon",
  "6880 Jehonville",
  "6880 Orgeo",
  "6887 HERBEUMONT",
  "6887 Saint-Médard",
  "6887 Straimont",
  "6890 Anloy",
  "6890 LIBIN",
  "6890 Ochamps",
  "6890 Redu",
  "6890 Smuid",
  "6890 Transinne",
  "6890 Villance",
  "6900 Aye",
  "6900 Hargimont",
  "6900 Humain",
  "6900 MARCHE-EN-FAMENNE",
  "6900 On",
  "6900 Roy",
  "6900 Waha",
  "6920 Sohier",
  "6920 WELLIN",
  "6921 Chanly",
  "6922 Halma",
  "6924 Lomprez",
  "6927 Bure",
  "6927 Grupont",
  "6927 Resteigne",
  "6927 TELLIN",
  "6929 DAVERDISSE",
  "6929 Gembes",
  "6929 Haut-Fays",
  "6929 Porcheresse",
  "6940 Barvaux-Sur-Ourthe",
  "6940 DURBUY",
  "6940 Grandhan",
  "6940 Septon",
  "6940 Wéris",
  "6941 Bende",
  "6941 Bomal-Sur-Ourthe",
  "6941 Borlon",
  "6941 Heyd",
  "6941 Izier",
  "6941 Tohogne",
  "6941 Villers-Sainte-Gertrude",
  "6950 Harsin",
  "6950 NASSOGNE",
  "6951 Bande",
  "6952 Grune",
  "6953 Ambly",
  "6953 Forrières",
  "6953 Lesterny",
  "6953 Masbourg",
  "6960 Dochamps",
  "6960 Grandmenil",
  "6960 Harre",
  "6960 Malempré",
  "6960 MANHAY",
  "6960 Odeigne",
  "6960 Vaux-Chavanne",
  "6970 TENNEVILLE",
  "6971 Champlon",
  "6972 Erneuville",
  "6980 Beausaint",
  "6980 LA ROCHE-EN-ARDENNE",
  "6982 Samrée",
  "6983 Ortho",
  "6984 Hives",
  "6986 Halleux",
  "6987 Beffe",
  "6987 Hodister",
  "6987 Marcourt",
  "6987 RENDEUX",
  "6990 Fronville",
  "6990 Hampteau",
  "6990 HOTTON",
  "6990 Marenne",
  "6997 Amonines",
  "6997 EREZÉE",
  "6997 Mormont",
  "6997 Soy",
  "7000 MONS",
  "7010 SHAPE",
  "7011 Ghlin",
  "7012 Flénu",
  "7012 Jemappes",
  "7020 Maisières",
  "7020 Nimy",
  "7021 Havre",
  "7022 Harmignies",
  "7022 Harveng",
  "7022 Hyon",
  "7022 Mesvin",
  "7022 Nouvelles",
  "7024 Ciply",
  "7030 Saint-Symphorien",
  "7031 Villers-Saint-Ghislain",
  "7032 Spiennes",
  "7033 Cuesmes",
  "7034 Obourg",
  "7034 Saint-Denis",
  "7040 Asquillies",
  "7040 Aulnois",
  "7040 Blaregnies",
  "7040 Bougnies",
  "7040 Genly",
  "7040 Goegnies-Chaussée",
  "7040 QUÉVY",
  "7040 Quévy-Le-Grand",
  "7040 Quévy-Le-Petit",
  "7041 Givry",
  "7041 Havay",
  "7050 Erbaut",
  "7050 Erbisoeul",
  "7050 Herchies",
  "7050 JURBISE",
  "7050 Masnuy-Saint-Jean",
  "7050 Masnuy-Saint-Pierre",
  "7060 Horrues",
  "7060 SOIGNIES",
  "7061 Casteau",
  "7061 Thieusies",
  "7062 Naast",
  "7063 Chaussée-Notre-Dame-Louvignies",
  "7063 Neufvilles",
  "7070 Gottignies",
  "7070 LE ROEULX",
  "7070 Mignault",
  "7070 Thieu",
  "7070 Ville-Sur-Haine",
  "7080 Eugies",
  "7080 FRAMERIES",
  "7080 La Bouverie",
  "7080 Noirchain",
  "7080 Sars-La-Bruyère",
  "7090 BRAINE-LE-COMTE",
  "7090 Hennuyères",
  "7090 Henripont",
  "7090 Petit-Roeulx-Lez-Braine",
  "7090 Ronquières",
  "7090 Steenkerque",
  "7100 Haine-Saint-Paul",
  "7100 Haine-Saint-Pierre",
  "7100 LA LOUVIÈRE",
  "7100 Saint-Vaast",
  "7100 Trivières",
  "7110 Boussoit",
  "7110 Houdeng-Aimeries",
  "7110 Houdeng-Goegnies",
  "7110 Maurage",
  "7110 Strépy-Bracquegnies",
  "7120 Croix-Lez-Rouveroy",
  "7120 ESTINNES",
  "7120 Estinnes-Au-Mont",
  "7120 Estinnes-Au-Val",
  "7120 Fauroeulx",
  "7120 Haulchin",
  "7120 Peissant",
  "7120 Rouveroy",
  "7120 Vellereille-Le-Sec",
  "7120 Vellereille-Les-Brayeux",
  "7130 Battignies",
  "7130 BINCHE",
  "7130 Bray",
  "7131 Waudrez",
  "7133 Buvrinnes",
  "7134 Epinois",
  "7134 Leval-Trahegnies",
  "7134 Péronnes-Lez-Binche",
  "7134 Ressaix",
  "7140 MORLANWELZ",
  "7140 Morlanwelz-Mariemont",
  "7141 Carnières",
  "7141 Mont-Sainte-Aldegonde",
  "7160 CHAPELLE-LEZ-HERLAIMONT",
  "7160 Godarville",
  "7160 Piéton",
  "7170 Bellecourt",
  "7170 Bois-D'Haine",
  "7170 Fayt-Lez-Manage",
  "7170 La Hestre",
  "7170 MANAGE",
  "7180 SENEFFE",
  "7181 Arquennes",
  "7181 Familleureux",
  "7181 Feluy",
  "7181 Petit-Roeulx-Lez-Nivelles",
  "7190 ECAUSSINNES",
  "7190 Ecaussinnes-D'Enghien",
  "7190 Marche-Lez-Ecaussinnes",
  "7191 Ecaussinnes-Lalaing",
  "7300 BOUSSU",
  "7301 Hornu",
  "7320 BERNISSART",
  "7321 Blaton",
  "7321 Harchies",
  "7322 Pommeroeul",
  "7322 Ville-Pommeroeul",
  "7330 SAINT-GHISLAIN",
  "7331 Baudour",
  "7332 Neufmaison",
  "7332 Sirault",
  "7333 Tertre",
  "7334 Hautrage",
  "7334 Villerot",
  "7340 COLFONTAINE",
  "7340 Paturages",
  "7340 Warquignies",
  "7340 Wasmes",
  "7350 Hainin",
  "7350 HENSIES",
  "7350 Montroeul-Sur-Haine",
  "7350 Thulin",
  "7370 Blaugies",
  "7370 DOUR",
  "7370 Elouges",
  "7370 Wihéries",
  "7380 Baisieux",
  "7380 QUIÉVRAIN",
  "7382 Audregnies",
  "7387 Angre",
  "7387 Angreau",
  "7387 Athis",
  "7387 Autreppe",
  "7387 Erquennes",
  "7387 Fayt-Le-Franc",
  "7387 HONNELLES",
  "7387 Marchipont",
  "7387 Montignies-Sur-Roc",
  "7387 Onnezies",
  "7387 Roisin",
  "7390 QUAREGNON",
  "7390 Wasmuel",
  "7500 Ere",
  "7500 Saint-Maur",
  "7500 TOURNAI",
  "7501 Orcq",
  "7502 Esplechin",
  "7503 Froyennes",
  "7504 Froidmont",
  "7506 Willemeau",
  "7510 3 Suisses",
  "7511 Vitrine Magique",
  "7512 Yves Rocher",
  "7513 Yves Rocher",
  "7520 Ramegnies-Chin",
  "7520 Templeuve",
  "7521 Chercq",
  "7522 Blandain",
  "7522 Hertain",
  "7522 Lamain",
  "7522 Marquain",
  "7530 Gaurain-Ramecroix",
  "7531 Havinnes",
  "7532 Beclers",
  "7533 Thimougies",
  "7534 Barry",
  "7534 Maulde",
  "7536 Vaulx",
  "7538 Vezon",
  "7540 Kain",
  "7540 Melles",
  "7540 Quartes",
  "7540 Rumillies",
  "7542 Mont-Saint-Aubert",
  "7543 Mourcourt",
  "7548 Warchin",
  "7600 PÉRUWELZ",
  "7601 Roucourt",
  "7602 Bury",
  "7603 Bon-Secours",
  "7604 Baugnies",
  "7604 Braffe",
  "7604 Brasmenil",
  "7604 Callenelle",
  "7604 Wasmes-Audemez-Briffoeil",
  "7608 Wiers",
  "7610 RUMES",
  "7611 La Glanerie",
  "7618 Taintignies",
  "7620 Bléharies",
  "7620 BRUNEHAUT",
  "7620 Guignies",
  "7620 Hollain",
  "7620 Jollain-Merlin",
  "7620 Wez-Velvain",
  "7621 Lesdain",
  "7622 Laplaigne",
  "7623 Rongy",
  "7624 Howardries",
  "7640 ANTOING",
  "7640 Maubray",
  "7640 Péronnes-Lez-Antoing",
  "7641 Bruyelle",
  "7642 Calonne",
  "7643 Fontenoy",
  "7700 Luingne",
  "7700 MOESKROEN",
  "7711 Dottenijs",
  "7712 Herseaux",
  "7730 Bailleul",
  "7730 Estaimbourg",
  "7730 ESTAIMPUIS",
  "7730 Evregnies",
  "7730 Leers-Nord",
  "7730 Néchin",
  "7730 Saint-Léger",
  "7740 PECQ",
  "7740 Warcoing",
  "7742 Hérinnes-Lez-Pecq",
  "7743 Esquelmes",
  "7743 Obigies",
  "7750 Amougies",
  "7750 Anseroeul",
  "7750 MONT-DE-L'ENCLUS",
  "7750 Orroir",
  "7750 Russeignies",
  "7760 CELLES",
  "7760 Escanaffles",
  "7760 Molenbaix",
  "7760 Popuelles",
  "7760 Pottes",
  "7760 Velaines",
  "7780 Komen",
  "7780 KOMEN-WAASTEN",
  "7781 Houthem",
  "7782 Ploegsteert",
  "7783 Bizet",
  "7784 Neerwaasten",
  "7784 Waasten",
  "7800 ATH",
  "7800 Lanquesaint",
  "7801 Irchonwelz",
  "7802 Ormeignies",
  "7803 Bouvignies",
  "7804 Ostiches",
  "7804 Rebaix",
  "7810 Maffle",
  "7811 Arbre",
  "7812 Houtaing",
  "7812 Ligne",
  "7812 Mainvault",
  "7812 Moulbaix",
  "7812 Villers-Notre-Dame",
  "7812 Villers-Saint-Amand",
  "7822 Ghislenghien",
  "7822 Isières",
  "7822 Meslin-L'Evêque",
  "7823 Gibecq",
  "7830 Bassilly",
  "7830 Fouleng",
  "7830 Gondregnies",
  "7830 Graty",
  "7830 Hellebecq",
  "7830 Hoves",
  "7830 SILLY",
  "7830 Thoricourt",
  "7850 EDINGEN",
  "7850 Lettelingen",
  "7850 Mark",
  "7860 LESSINES",
  "7861 Papignies",
  "7861 Wannebecq",
  "7862 Ogy",
  "7863 Ghoy",
  "7864 Deux-Acren",
  "7866 Bois-De-Lessines",
  "7866 Ollignies",
  "7870 Bauffe",
  "7870 Cambron-Saint-Vincent",
  "7870 LENS",
  "7870 Lombise",
  "7870 Montignies-Lez-Lens",
  "7880 VLOESBERG",
  "7890 ELLEZELLES",
  "7890 Lahamaide",
  "7890 Wodecq",
  "7900 Grandmetz",
  "7900 LEUZE-EN-HAINAUT",
  "7901 Thieulain",
  "7903 Blicquy",
  "7903 Chapelle-À-Oie",
  "7903 Chapelle-À-Wattines",
  "7904 Pipaix",
  "7904 Tourpes",
  "7904 Willaupuis",
  "7906 Gallaix",
  "7910 Anvaing",
  "7910 Arc-Ainières",
  "7910 Arc-Wattripont",
  "7910 Cordes",
  "7910 Ellignies-Lez-Frasnes",
  "7910 Forest",
  "7910 FRASNES-LEZ-ANVAING",
  "7910 Wattripont",
  "7911 Buissenal",
  "7911 Frasnes-Lez-Buissenal",
  "7911 Hacquegnies",
  "7911 Herquegies",
  "7911 Montroeul-Au-Bois",
  "7911 Moustier",
  "7911 Oeudeghien",
  "7912 Dergneau",
  "7912 Saint-Sauveur",
  "7940 BRUGELETTE",
  "7940 Cambron-Casteau",
  "7941 Attre",
  "7942 Mévergnies-Lez-Lens",
  "7943 Gages",
  "7950 CHIÈVRES",
  "7950 Grosage",
  "7950 Huissignies",
  "7950 Ladeuze",
  "7950 Tongre-Saint-Martin",
  "7951 Tongre-Notre-Dame",
  "7970 BELOEIL",
  "7971 Basècles",
  "7971 Ramegnies",
  "7971 Thumaide",
  "7971 Wadelincourt",
  "7972 Aubechies",
  "7972 Ellignies-Saint-Anne",
  "7972 Quevaucamps",
  "7973 Grandglise",
  "7973 Stambruges",
  "8000 BRUGGE",
  "8000 Koolkerke",
  "8020 Hertsberge",
  "8020 OOSTKAMP",
  "8020 Ruddervoorde",
  "8020 Waardamme",
  "8200 Sint-Andries",
  "8200 Sint-Michiels",
  "8210 Loppem",
  "8210 Veldegem",
  "8210 ZEDELGEM",
  "8211 Aartrijke",
  "8300 Knokke",
  "8300 KNOKKE-HEIST",
  "8300 Westkapelle",
  "8301 Heist-Aan-Zee",
  "8301 Ramskapelle",
  "8310 Assebroek",
  "8310 Sint-Kruis",
  "8340 DAMME",
  "8340 Hoeke",
  "8340 Lapscheure",
  "8340 Moerkerke",
  "8340 Oostkerke",
  "8340 Sijsele",
  "8370 BLANKENBERGE",
  "8370 Uitkerke",
  "8377 Houtave",
  "8377 Meetkerke",
  "8377 Nieuwmunster",
  "8377 ZUIENKERKE",
  "8380 Dudzele",
  "8380 Lissewege",
  "8380 Zeebrugge",
  "8400 OOSTENDE",
  "8400 Stene",
  "8400 Zandvoorde",
  "8420 DE HAAN",
  "8420 Klemskerke",
  "8420 Wenduine",
  "8421 Vlissegem",
  "8430 MIDDELKERKE",
  "8431 Wilskerke",
  "8432 Leffinge",
  "8433 Mannekensvere",
  "8433 Schore",
  "8433 Sint-Pieters-Kapelle",
  "8433 Slijpe",
  "8434 Lombardsijde",
  "8434 Westende",
  "8450 BREDENE",
  "8460 Ettelgem",
  "8460 OUDENBURG",
  "8460 Roksem",
  "8460 Westkerke",
  "8470 GISTEL",
  "8470 Moere",
  "8470 Snaaskerke",
  "8470 Zevekote",
  "8480 Bekegem",
  "8480 Eernegem",
  "8480 ICHTEGEM",
  "8490 JABBEKE",
  "8490 Snellegem",
  "8490 Stalhille",
  "8490 Varsenare",
  "8490 Zerkegem",
  "8500 KORTRIJK",
  "8501 Bissegem",
  "8501 Heule",
  "8510 Bellegem",
  "8510 Kooigem",
  "8510 Marke",
  "8510 Rollegem",
  "8511 Aalbeke",
  "8520 KUURNE",
  "8530 HARELBEKE",
  "8531 Bavikhove",
  "8531 Hulste",
  "8540 DEERLIJK",
  "8550 ZWEVEGEM",
  "8551 Heestert",
  "8552 Moen",
  "8553 Otegem",
  "8554 Sint-Denijs",
  "8560 Gullegem",
  "8560 Moorsele",
  "8560 WEVELGEM",
  "8570 ANZEGEM",
  "8570 Gijzelbrechtegem",
  "8570 Ingooigem",
  "8570 Vichte",
  "8572 Kaster",
  "8573 Tiegem",
  "8580 AVELGEM",
  "8581 Kerkhove",
  "8581 Waarmaarde",
  "8582 Outrijve",
  "8583 Bossuit",
  "8587 Helkijn",
  "8587 Spiere",
  "8587 SPIERE-HELKIJN",
  "8600 Beerst",
  "8600 DIKSMUIDE",
  "8600 Driekapellen",
  "8600 Esen",
  "8600 Kaaskerke",
  "8600 Keiem",
  "8600 Lampernisse",
  "8600 Leke",
  "8600 Nieuwkapelle",
  "8600 Oostkerke",
  "8600 Oudekapelle",
  "8600 Pervijze",
  "8600 Sint-Jacobs-Kapelle",
  "8600 Stuivekenskerke",
  "8600 Vladslo",
  "8600 Woumen",
  "8610 Handzame",
  "8610 KORTEMARK",
  "8610 Werken",
  "8610 Zarren",
  "8620 NIEUWPOORT",
  "8620 Ramskapelle",
  "8620 Sint-Joris",
  "8630 Avekapelle",
  "8630 Booitshoeke",
  "8630 Bulskamp",
  "8630 De Moeren",
  "8630 Eggewaartskapelle",
  "8630 Houtem",
  "8630 Steenkerke",
  "8630 VEURNE",
  "8630 Vinkem",
  "8630 Wulveringem",
  "8630 Zoutenaaie",
  "8640 Oostvleteren",
  "8640 VLETEREN",
  "8640 Westvleteren",
  "8640 Woesten",
  "8647 Lo",
  "8647 LO-RENINGE",
  "8647 Noordschote",
  "8647 Pollinkhove",
  "8647 Reninge",
  "8650 HOUTHULST",
  "8650 Klerken",
  "8650 Merkem",
  "8660 Adinkerke",
  "8660 DE PANNE",
  "8670 KOKSIJDE",
  "8670 Oostduinkerke",
  "8670 Wulpen",
  "8680 Bovekerke",
  "8680 KOEKELARE",
  "8680 Zande",
  "8690 ALVERINGEM",
  "8690 Hoogstade",
  "8690 Oeren",
  "8690 Sint-Rijkers",
  "8691 Beveren-Aan-De-Ijzer",
  "8691 Gijverinkhove",
  "8691 Izenberge",
  "8691 Leisele",
  "8691 Stavele",
  "8700 Aarsele",
  "8700 Kanegem",
  "8700 Schuiferskapelle",
  "8700 TIELT",
  "8710 Ooigem",
  "8710 Sint-Baafs-Vijve",
  "8710 WIELSBEKE",
  "8720 DENTERGEM",
  "8720 Markegem",
  "8720 Oeselgem",
  "8720 Wakken",
  "8730 BEERNEM",
  "8730 Oedelem",
  "8730 Sint-Joris",
  "8740 Egem",
  "8740 PITTEM",
  "8750 WINGENE",
  "8750 Zwevezele",
  "8755 RUISELEDE",
  "8760 MEULEBEKE",
  "8770 INGELMUNSTER",
  "8780 OOSTROZEBEKE",
  "8790 WAREGEM",
  "8791 Beveren",
  "8792 Desselgem",
  "8793 Sint-Eloois-Vijve",
  "8800 Beveren",
  "8800 Oekene",
  "8800 ROESELARE",
  "8800 Rumbeke",
  "8810 LICHTERVELDE",
  "8820 TORHOUT",
  "8830 Gits",
  "8830 HOOGLEDE",
  "8840 Oostnieuwkerke",
  "8840 STADEN",
  "8840 Westrozebeke",
  "8850 ARDOOIE",
  "8851 Koolskamp",
  "8860 LENDELEDE",
  "8870 Emelgem",
  "8870 IZEGEM",
  "8870 Kachtem",
  "8880 LEDEGEM",
  "8880 Rollegem-Kapelle",
  "8880 Sint-Eloois-Winkel",
  "8890 Dadizele",
  "8890 MOORSLEDE",
  "8900 Brielen",
  "8900 Dikkebus",
  "8900 IEPER",
  "8900 Sint-Jan",
  "8902 Hollebeke",
  "8902 Voormezele",
  "8902 Zillebeke",
  "8904 Boezinge",
  "8904 Zuidschote",
  "8906 Elverdinge",
  "8908 Vlamertinge",
  "8920 Bikschote",
  "8920 Langemark",
  "8920 LANGEMARK-POELKAPELLE",
  "8920 Poelkapelle",
  "8930 Lauwe",
  "8930 MENEN",
  "8930 Rekkem",
  "8940 Geluwe",
  "8940 WERVIK",
  "8950 HEUVELLAND",
  "8950 Nieuwkerke",
  "8951 Dranouter",
  "8952 Wulvergem",
  "8953 Wijtschate",
  "8954 Westouter",
  "8956 Kemmel",
  "8957 MESEN",
  "8958 Loker",
  "8970 POPERINGE",
  "8970 Reningelst",
  "8972 Krombeke",
  "8972 Proven",
  "8972 Roesbrugge-Haringe",
  "8978 Watou",
  "8980 Beselare",
  "8980 Geluveld",
  "8980 Passendale",
  "8980 Zandvoorde",
  "8980 ZONNEBEKE",
  "9000 GENT",
  "9030 Mariakerke",
  "9031 Drongen",
  "9032 Wondelgem",
  "9040 Sint-Amandsberg",
  "9041 Oostakker",
  "9042 Desteldonk",
  "9042 Mendonk",
  "9042 Sint-Kruis-Winkel",
  "9050 Gentbrugge",
  "9050 Ledeberg",
  "9051 Afsnee",
  "9051 Sint-Denijs-Westrem",
  "9052 Zwijnaarde",
  "9060 ZELZATE",
  "9070 DESTELBERGEN",
  "9070 Heusden",
  "9075 CSM Gent X",
  "9080 Beervelde",
  "9080 LOCHRISTI",
  "9080 Zaffelare",
  "9080 Zeveneken",
  "9090 Gontrode",
  "9090 MELLE",
  "9099 Gent X",
  "9100 Nieuwkerken-Waas",
  "9100 SINT-NIKLAAS",
  "9111 Belsele",
  "9112 Sinaai-Waas",
  "9120 BEVEREN-WAAS",
  "9120 Haasdonk",
  "9120 Kallo",
  "9120 Melsele",
  "9120 Vrasene",
  "9130 Doel",
  "9130 Kallo",
  "9130 Kieldrecht",
  "9130 Verrebroek",
  "9140 Elversele",
  "9140 Steendorp",
  "9140 TEMSE",
  "9140 Tielrode",
  "9150 Bazel",
  "9150 KRUIBEKE",
  "9150 Rupelmonde",
  "9160 Daknam",
  "9160 Eksaarde",
  "9160 LOKEREN",
  "9170 De Klinge",
  "9170 Meerdonk",
  "9170 SINT-GILLIS-WAAS",
  "9170 Sint-Pauwels",
  "9180 MOERBEKE-WAAS",
  "9185 WACHTEBEKE",
  "9190 Kemzeke",
  "9190 STEKENE",
  "9200 Appels",
  "9200 Baasrode",
  "9200 DENDERMONDE",
  "9200 Grembergen",
  "9200 Mespelare",
  "9200 Oudegem",
  "9200 Schoonaarde",
  "9200 Sint-Gillis-Dendermonde",
  "9220 HAMME",
  "9220 Moerzeke",
  "9230 Massemen",
  "9230 Westrem",
  "9230 WETTEREN",
  "9240 ZELE",
  "9250 WAASMUNSTER",
  "9255 BUGGENHOUT",
  "9255 Opdorp",
  "9260 Schellebelle",
  "9260 Serskamp",
  "9260 WICHELEN",
  "9270 Kalken",
  "9270 LAARNE",
  "9280 Denderbelle",
  "9280 LEBBEKE",
  "9280 Wieze",
  "9290 BERLARE",
  "9290 Overmere",
  "9290 Uitbergen",
  "9300 AALST",
  "9308 Gijzegem",
  "9308 Hofstade",
  "9310 Baardegem",
  "9310 Herdersem",
  "9310 Meldert",
  "9310 Moorsel",
  "9320 Erembodegem",
  "9320 Nieuwerkerken",
  "9340 Impe",
  "9340 LEDE",
  "9340 Oordegem",
  "9340 Smetlede",
  "9340 Wanzele",
  "9400 Appelterre-Eichem",
  "9400 Denderwindeke",
  "9400 Lieferinge",
  "9400 Nederhasselt",
  "9400 NINOVE",
  "9400 Okegem",
  "9400 Voorde",
  "9401 Pollare",
  "9402 Meerbeke",
  "9403 Neigem",
  "9404 Aspelare",
  "9406 Outer",
  "9420 Aaigem",
  "9420 Bambrugge",
  "9420 Burst",
  "9420 Erondegem",
  "9420 Erpe",
  "9420 ERPE-MERE",
  "9420 Mere",
  "9420 Ottergem",
  "9420 Vlekkem",
  "9450 Denderhoutem",
  "9450 HAALTERT",
  "9450 Heldergem",
  "9451 Kerksken",
  "9470 DENDERLEEUW",
  "9472 Iddergem",
  "9473 Welle",
  "9500 GERAARDSBERGEN",
  "9500 Goeferdinge",
  "9500 Moerbeke",
  "9500 Nederboelare",
  "9500 Onkerzele",
  "9500 Ophasselt",
  "9500 Overboelare",
  "9500 Viane",
  "9500 Zarlardinge",
  "9506 Grimminge",
  "9506 Idegem",
  "9506 Nieuwenhove",
  "9506 Schendelbeke",
  "9506 Smeerebbe-Vloerzegem",
  "9506 Waarbeke",
  "9506 Zandbergen",
  "9520 Bavegem",
  "9520 Oombergen",
  "9520 SINT-LIEVENS-HOUTEM",
  "9520 Vlierzele",
  "9520 Zonnegem",
  "9521 Letterhoutem",
  "9550 HERZELE",
  "9550 Hillegem",
  "9550 Sint-Antelinks",
  "9550 Sint-Lievens-Esse",
  "9550 Steenhuize-Wijnhuize",
  "9550 Woubrechtegem",
  "9551 Ressegem",
  "9552 Borsbeke",
  "9570 Deftinge",
  "9570 LIERDE",
  "9570 Sint-Maria-Lierde",
  "9571 Hemelveerdegem",
  "9572 Sint-Martens-Lierde",
  "9600 RONSE",
  "9620 Elene",
  "9620 Erwetegem",
  "9620 Godveerdegem",
  "9620 Grotenberge",
  "9620 Leeuwergem",
  "9620 Oombergen",
  "9620 Sint-Goriks-Oudenhove",
  "9620 Sint-Maria-Oudenhove",
  "9620 Strijpen",
  "9620 Velzeke-Ruddershove",
  "9620 ZOTTEGEM",
  "9630 Beerlegem",
  "9630 Dikkele",
  "9630 Hundelgem",
  "9630 Meilegem",
  "9630 Munkzwalm",
  "9630 Paulatem",
  "9630 Roborst",
  "9630 Rozebeke",
  "9630 Sint-Blasius-Boekel",
  "9630 Sint-Denijs-Boekel",
  "9630 Sint-Maria-Latem",
  "9630 ZWALM",
  "9636 Nederzwalm-Hermelgem",
  "9660 BRAKEL",
  "9660 Elst",
  "9660 Everbeek",
  "9660 Michelbeke",
  "9660 Nederbrakel",
  "9660 Opbrakel",
  "9660 Sint-Maria-Oudenhove",
  "9660 Zegelsem",
  "9661 Parike",
  "9667 HOREBEKE",
  "9667 Sint-Kornelis-Horebeke",
  "9667 Sint-Maria-Horebeke",
  "9680 Etikhove",
  "9680 Maarke-Kerkem",
  "9680 MAARKEDAL",
  "9681 Nukerke",
  "9688 Schorisse",
  "9690 Berchem",
  "9690 KLUISBERGEN",
  "9690 Kwaremont",
  "9690 Ruien",
  "9690 Zulzeke",
  "9700 Bevere",
  "9700 Edelare",
  "9700 Eine",
  "9700 Ename",
  "9700 Heurne",
  "9700 Leupegem",
  "9700 Mater",
  "9700 Melden",
  "9700 Mullem",
  "9700 Nederename",
  "9700 Ooike",
  "9700 OUDENAARDE",
  "9700 Volkegem",
  "9700 Welden",
  "9750 Huise",
  "9750 Ouwegem",
  "9750 ZINGEM",
  "9770 KRUISHOUTEM",
  "9771 Nokere",
  "9772 Wannegem-Lede",
  "9790 Elsegem",
  "9790 Moregem",
  "9790 Ooike",
  "9790 Petegem-Aan-De-Schelde",
  "9790 Wortegem",
  "9790 WORTEGEM-PETEGEM",
  "9800 Astene",
  "9800 Bachte-Maria-Leerne",
  "9800 DEINZE",
  "9800 Gottem",
  "9800 Grammene",
  "9800 Meigem",
  "9800 Petegem-Aan-De-Leie",
  "9800 Sint-Martens-Leerne",
  "9800 Vinkt",
  "9800 Wontergem",
  "9800 Zeveren",
  "9810 Eke",
  "9810 NAZARETH",
  "9820 Bottelare",
  "9820 Lemberge",
  "9820 Melsen",
  "9820 MERELBEKE",
  "9820 Munte",
  "9820 Schelderode",
  "9830 SINT-MARTENS-LATEM",
  "9831 Deurle",
  "9840 DE PINTE",
  "9840 Zevergem",
  "9850 Hansbeke",
  "9850 Landegem",
  "9850 Merendree",
  "9850 NEVELE",
  "9850 Poesele",
  "9850 Vosselare",
  "9860 Balegem",
  "9860 Gijzenzele",
  "9860 Landskouter",
  "9860 Moortsele",
  "9860 OOSTERZELE",
  "9860 Scheldewindeke",
  "9870 Machelen",
  "9870 Olsene",
  "9870 ZULTE",
  "9880 AALTER",
  "9880 Lotenhulle",
  "9880 Poeke",
  "9881 Bellem",
  "9890 Asper",
  "9890 Baaigem",
  "9890 Dikkelvenne",
  "9890 GAVERE",
  "9890 Semmerzake",
  "9890 Vurste",
  "9900 EEKLO",
  "9910 KNESSELARE",
  "9910 Ursel",
  "9920 LOVENDEGEM",
  "9921 Vinderhoute",
  "9930 ZOMERGEM",
  "9931 Oostwinkel",
  "9932 Ronsele",
  "9940 Ertvelde",
  "9940 EVERGEM",
  "9940 Kluizen",
  "9940 Sleidinge",
  "9950 WAARSCHOOT",
  "9960 ASSENEDE",
  "9961 Boekhoute",
  "9968 Bassevelde",
  "9968 Oosteeklo",
  "9970 KAPRIJKE",
  "9971 Lembeke",
  "9980 SINT-LAUREINS",
  "9981 Sint-Margriete",
  "9982 Sint-Jan-In-Eremo",
  "9988 Waterland-Oudeman",
  "9988 Watervliet",
  "9990 MALDEGEM",
  "9991 Adegem",
]