/*

  This is a file created by AuthManager.be
  Copyright Timothy Verhaeghe <me@timothyverhaeghe.com>

  Version 1.0

*/

// Configure
// const apiURL = "http://localhost:3000/v1"
import env from '@/../env.js';
// let apiURL = "https://auth.tablefixr.dev/v1"

import axios from 'axios'

const cookies = {};

cookies.set = (name, value, days) => {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    expires = `; expires=${date.toUTCString()}`
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`
}

cookies.get = (name) => {
  const nameEQ = `${name}=`
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

cookies.remove = (name) => {
  document.cookie = `${name}=; Max-Age=-99999999;`
}


const client = axios.create({
    baseURL: `${env.apiURL}`, // contains the version
    json: true,
    headers: {
      'authorization': 'Bearer '+cookies.get('session')
    }
})

const execute = async (method, resource, data) => {
  return new Promise(function(resolve, reject){
    console.log(resource);
    client.defaults.headers['authorization'] = 'Bearer ' + cookies.get('session');
    return client({ method, url: resource, data,}).then(req => {
      console.log(req.data);
      return resolve(req.data);
    }).catch(function(err){
      // console.log(err)
      return reject(err);
    })
  })
}

const login = (email, password) => new Promise((resolve) => {

  if(!email || !password){
    resolve({ error: 'Not all fields are set'});
  }

  axios.post(`${env.apiURL}/admins/login`, {
    email,
    password
  }).then((response) => {
      console.log(response.data);
      cookies.set('session', response.data._id);
      resolve(response.data);
    })
    .catch(() => {
      resolve({error: "The username and/or password is incorrect."})
    })
})

/*
const getSession = sessionId => new Promise((resolve, reject) => {
  axios
    .get(`${apiURL}/sessions/${sessionId}`, {
      headers: {
        session: sessionId
      }
    })
    .then((response) => {
      resolve(response.data)
    }).catch((e) => {
      reject(e)
    })
})


const getUsersByMail = email => new Promise((resolve, reject) => {
  axios
    .get(`${apiURL}/users/search/${email}`, {
    })
    .then((response) => {
      resolve(response.data)
    }).catch((e) => {
      reject(e)
    })
  })


const requestNewPassword = (email) => new Promise((resolve,reject) => {
    axios.post(`${apiURL}/users/request-new-password`, {
      email
    }).then((response) => {
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
})

const resetPassword = (resetCode, password) => new Promise((resolve, reject) => {
  axios.post(`${apiURL}/users/request-new-password/${resetCode}`, {
    password
  })
  .then((response) => {
    resolve(response)
  })
  .catch((e) => {
    reject(e)
  })
})


 const checkUpperCase = (string) => {
  for(const char of string){
      if (char.toUpperCase() == char){
          return true;
      }
  }
  return false;
}

const checkLowerCase = (string) => {
  for(const char of string){
      if (char.toLowerCase() == char){
          return true;
      }
  }
  return false;
}

const checkSpecialChars = (string) => {
  var special_chars = [ '&','!', '$', '-', '_',
                      '?', '@', '>', '<', '*',
                      '�'
                      ];
  return special_chars.map(special_char => string.toLowerCase().includes(special_char)).includes(true);
}

const checkPasswordRequirements = (string) => {
  return checkSpecialChars(string) && checkUpperCase(string) && checkLowerCase(string);
}
*/

/*
e.g.
  const auth = require('@helpers/auth');

  const account = await auth.login(email, password); // Login
  const account = await auth.me(); // Get your account
  await auth.logout(); // Logout


  const auth = require('@helpers/auth');
  const account = await auth.me().catch(function(e){}); // Get your account


*/
const me = async () => {
  return new Promise((resolve, reject) => {
    if(cookies.get('session')){
      execute('get', '/admins/me').then(function(user){
        return resolve(user);
      }).catch(function(e){
        return reject(e);
      });
    } else {
      return reject('Missing session cookie.');
    }
  })
}


const updateProfile = async (data) => {
  return new Promise((resolve, reject) => {
    if(cookies.get('session')){
      execute('put', '/users/me', data).then(function(user){
        return resolve(user);
      }).catch(function(e){
        return reject(e);
      });
    } else {
      return reject('Missing session cookie.');
    }
  })
}

const logout = async () => {
  return new Promise((resolve) => {
    if(cookies.get('session')){
      execute('delete', '/sessions/'+cookies.get('session')).then(function(user){
        resolve(user);
      }).catch(function(e){
        resolve({error: e});
      });
    } else {
      return resolve({error: 'Missing session cookie.'});
    }
  })
}



// Return a Boolean (false) if you are not on a login page or password reset page.
const shouldRedirectToLogin = () => {
  if(
    document.location.href.indexOf('password') > -1 ||
    document.location.href.indexOf('login') > -1
  ) {
    return false;
  } else {
    return true;
  }
}


const put = (url, body) => {
  return new Promise((resolve) => {
    if(cookies.get('session')){
      execute('put', url, body).then(function(user){
        resolve(user);
      }).catch(function(e){
        resolve({error: e});
      });
    } else {
      return resolve({error: 'Missing session cookie.'});
    }
  })
}

const post = (url, body) => {
  return new Promise((resolve) => {
    if(cookies.get('session')){
      execute('post', url, body).then(function(res){
        resolve(res._id);
      }).catch(function(e){
        resolve(e);
      });
    } else {
      return resolve('Missing session cookie.');
    }
  })
}


const get = (url) => {
  return new Promise((resolve) => {
    if(cookies.get('session')){
      execute('get', url).then(function(user){
        resolve(user);
      }).catch(function(e){
        resolve({error: e});
      });
    } else {
      return resolve({error: 'Missing session cookie.'});
    }
  })
}


export default {
  me,
  login,
  shouldRedirectToLogin,
  logout,
  updateProfile,

  put,
  post,
  get,

  //getSession,
  //destroySession,
  //getUsersByMail,
  //requestNewPassword,
  //resetPassword,
  //checkPasswordRequirements
}
