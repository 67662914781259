<template>
  <div class="w-full items-start justify-start flex flex-row">
    <div class="w-full flex flex-col pb-10">
      <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer" @click="$router.go(-1)">Ga terug</span>
      <h1 class="text-3xl font-semibold">{{object.name}}</h1>

      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Naam</label>
      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
        <input type="text" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Festival naam" v-model="object.name" />
      </div>

      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Ranking (standaard 99, 1 is eerste, 99 laatste, als 2 festivals met dezelfde ranking, dan worden ze op aanmaak datum gesorteerd)</label>
      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
        <input type="number" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="99" v-model="object.order" />
      </div>

      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">ID (op welke URL komt dit? De naam maar zonder spaties + enkel lowercase)</label>
      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
        <input type="text" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Festival-ID" v-model="object.id" @input="object.id = ($event.target.value || '').toLowerCase().split(' ').join('-')" />
      </div>

      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Actief (tonen op de website)</label>
      <select class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" v-model="object.active">
        <option :value="true">Actief</option>
        <option :value="false">Niet actief (wordt niet getoond)</option>
      </select>

      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Locatie</label>
      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
        <input type="text" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="e.g. Herental" v-model="object.location" />
      </div>

      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Prijs</label>
      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
        <input type="text" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="e.g. Gratis" v-model="object.price" />
      </div>

      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Website / Meer informatie</label>
      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
        <input type="text" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="e.g. https://rockwerchter.be" v-model="object.website" />
      </div>


      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">CERA Link (moet starten met https)</label>
      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
        <input type="text" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="e.g. https://rockwerchter.be" v-model="object.ceraLink" />
      </div>

      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Cera event (toon extra link naar Cera pagina)</label>
      <select class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" v-model="object.isCera">
        <option :value="true">Cera event</option>
        <option :value="false">Geen Cera event</option>
      </select>

      <div>
        <p class="block text-sm font-medium leading-5 text-gray-700 mt-5">
          Banner afbeelding (op de detail pagina, rechthoek => Facebook banner picture)
        </p>
      </div>
      <div class="col-lg-4">
        <img ref="banner" width="150" height="150" id="output" alt="upload image" class="" onclick="document.getElementById('upload_image_input').click();" style="cursor: pointer" :src="object.banner" />
        <input type="file" accept="image/*" @change="uploadFiles('banner')" id="upload_image_input" name="file" style="display: none" multiple />
        <p v-if="uploaded">Bestand is geüpload</p>
      </div>


      <div>
        <p class="block text-sm font-medium leading-5 text-gray-700 mt-5">
          Avatar afbeelding (op de overzicht pagina, vierkant => Facebook profile picture)
        </p>
      </div>
      <div class="col-lg-4">
        <img ref="banner" width="150" height="150" id="output2" alt="upload image" class="" onclick="document.getElementById('upload_image_input2').click();" style="cursor: pointer" :src="object.avatar" />
        <input type="file" accept="image/*" @change="uploadFiles('avatar')" id="upload_image_input2" name="file2" style="display: none" multiple />
        <p v-if="uploaded2">Bestand is geüpload</p>
      </div>

      <!--
      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">End datum event ()</label>
      <t-datepicker />
    -->

    <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Festival datums (elke datum is een optie om een plekje aan te maken)</label>
      <div class="flex flex-col border w-1/2 mt-2">

        <!-- Headers -->
        <div class="flex flex-row w-full">
          <div class="p-2 bg-white border-r w-5/12">Naam</div>
          <div class="p-2 bg-white border-r w-5/12">Datum</div>
          <div class="p-2 bg-white w-2/12">Opties</div>
        </div>

        <div v-for="(date, index) in object.dates" v-bind:key="index" class="flex flex-row w-full border-t">
          <div class="p-1 bg-white border-r w-5/12">
            <input v-model="date.label" class="p-2 w-full" placeholder="3 oktober" type="text" />
          </div>
          <div class="bg-white border-r w-5/12">
            <t-datepicker v-model="date.date" class="p-1 w-full" />
          </div>
          <div class="flex flex-row items-center justify-center bg-white w-2/12">
            <div>
              <svg @click="object.dates.splice(index, 1)" class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </div>
          </div>
        </div>

      </div>

      <div class="flex flex-row mt-3">
        <div @click="object.dates.push({ date: new Date(), label: ''})" class="px-2 py-1 border bg-green-500 rounded text-white cursor-pointer">Voeg datum toe</div>
      </div>

      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Introductie tekst</label>
      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
        <textarea rows="3" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Festival introductie" v-model="object.intro" style="height: 100px; width: 100px"></textarea>
      </div>

      <div class="flex flex-row mt-4">
        <button @click="save()" class="text-white py-2 rounded float-right cursor-pointer" style="background-color: rgb(32, 23, 71); width: 250px;">Opslaan</button>
        <button @click="del()" class="text-white py-2 px-4 rounded float-right ml-5 cursor-pointer" style="background-color: red; width: 250px;">Verwijder</button>
      </div>
    </div>
  </div>
</template>

<script>
import API from '@/helpers/api'
import env from "@/../env.js";


export default {
  name: "event",
  data: function() {
    return {
      object: {},
      uploaded: false,
    };
  },
  methods: {
    sendFile: function(formData) {
      const _self = this;
      return new Promise(function(resolve) {
        fetch(env.apiURL + "/files/upload/banners", {
          method: "POST",
          body: formData
        })
          .then(res => res.json())
          .then(res => {
            console.log("Done uploading", res);
            resolve(res.file);
            _self.$refs.banner.src = res.file;
          })
          .catch(e => {
            console.error(JSON.stringify(e.message));
            resolve(false);
          });
      });
    },
    uploadFiles: async function(type) {
      let droppedFiles = event.target.files[0];
      if (!droppedFiles) return;
      if (droppedFiles.length < 1) return;
      let formData = new FormData();
      formData.append("file", droppedFiles);
      var f = await this.sendFile(formData, type);
      this.object[type] = f;
      this.uploaded = true;
    },
    load: async function() {
      this.object = await API.get("/festivals/" + this.$route.params.id);
    },
    save: async function() {
      await API.put("/festivals/" + this.object._id, this.object);
      alert("Opgeslagen");
      this.$router.push({ path: '/festivals'})
    },
    del: async function() {
      if(confirm('Ben je zeker dat je dit festival wilt verwijderen?')){
        await API.delete("/festivals/" + this.object._id);
        this.$router.push({ path: '/festivals'})
      }
    },
  },
  async mounted() {
    await this.load();
  }
};
</script>

<style scoped>
</style>
