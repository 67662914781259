<template>
    <div class="w-full items-start justify-start flex flex-row" v-if="object">
        <div v-if="orgType === 'Private'" class="w-full flex flex-col border-b border-gray-300 pb-10">
                                                <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer"
                                                      @click="$router.go(-1)">Ga terug</span>
            <h1 v-if="orgType === 'Private'" class="text-3xl font-semibold">{{ object.firstname }} {{object.lastname}}</h1>
            <div class="flex flex-row">
                <div class="w-6/12 pr-5">
                    <label  class="block text-sm font-medium leading-5 text-gray-700 mt-5">Voornaam</label>
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Voornaam" v-model="object.firstname">
                    </div>
                </div>
                <div class="w-6/12">
                    <label  class="block text-sm font-medium leading-5 text-gray-700 mt-5">Achternaam</label>
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Achternaam" v-model="object.lastname">
                    </div>
                </div>
            </div>
            <div class="flex flex-row">
                <div class="w-6/12 pr-5">
                    <label  class="block text-sm font-medium leading-5 text-gray-700 mt-5">Email</label>
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Voornaam" v-model="object.email">
                    </div>
                </div>
                <div class="w-6/12">
                    <label  class="block text-sm font-medium leading-5 text-gray-700 mt-5">Geslacht</label>
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Achternaam" v-model="object.gender">
                    </div>
                </div>
            </div>
            <div class="flex flex-row">
                <div class="w-6/12 pr-5">
                    <label  class="block text-sm font-medium leading-5 text-gray-700 mt-5">Geboortejaar</label>
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Voornaam" v-model="object.birthYear">
                    </div>
                </div>
                <div class="w-6/12">
                    <label  class="block text-sm font-medium leading-5 text-gray-700 mt-5">Woonplaats</label>
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Achternaam" v-model="object.zip">
                    </div>
                </div>
            </div>
            <div class="flex flex-row">
                <div class="w-6/12 pr-5">   
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center mt-5 gap-2">
                        <div class="w-full ">
                            <label  class="block text-sm font-medium leading-5 text-gray-700 ">Iedereen erbij-charter:</label>
                        </div>
                        <input type="checkbox" class="form-checkbox" v-model="object.charter">
                    </div>
                    <div class="mt-2  rounded-md shadow-sm relative flex flex-row items-center mt-5 gap-2">
                        <div class="w-full">
                            <label class="block text-sm font-medium leading-5 text-gray-700 " for="uptodate">Nieuwsbrief:</label>
                        </div>
                        <input name="uptodate" type="checkbox" class="form-checkbox" v-model="object.uptodate">
                    </div>
                </div>
                <div class="w-6/12">
                    <label  class="block text-sm font-medium leading-5 text-gray-700 mt-5">Opmerking</label>
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <textarea v-model="object.extra" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Achternaam" />
                    </div>
                </div>
            </div>
            <br/><br/><br/>
            <div class="flex flex-row">
                <button @click="savePrivate()" class="text-white py-2 px-4 rounded float-right ml-5 cursor-pointer" style="background-color: rgb(32, 23, 71); width: 250px;">Opslaan</button>
                <button @click="delPrivate()" class="text-white py-2 px-4 rounded float-right ml-5 cursor-pointer" style="background-color: red; width: 250px;">Verwijder</button>
            </div>
        </div>
        <div v-else-if="orgType === 'Org'" class="w-full flex flex-col border-b border-gray-300 pb-10">
                                                <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer"
                                                      @click="$router.go(-1)">Ga terug</span>
            <h1 class="text-3xl font-semibold">{{ object.organisationname }}</h1>
            <label  class="block text-sm font-medium leading-5 text-gray-700 mt-5">Organisatie</label>
            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Voornaam" v-model="object.organisationname">
            </div>
            <div class="flex flex-row">
                <div class="w-6/12 pr-5">
                    <label  class="block text-sm font-medium leading-5 text-gray-700 mt-5">Voornaam Verantwoordelijke</label>
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Voornaam" v-model="object.firstnameResponsible">
                    </div>
                </div>
                <div class="w-6/12">
                    <label  class="block text-sm font-medium leading-5 text-gray-700 mt-5">Achternaam Verantwoordelijke</label>
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Achternaam" v-model="object.lastnameResponsible">
                    </div>
                </div>
            </div>
            <div class="flex flex-row">
                <div class="w-6/12 pr-5">
                    <label  class="block text-sm font-medium leading-5 text-gray-700 mt-5">Email</label>
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="email" v-model="object.email">
                    </div>
                </div>
                <div class="w-6/12">
                    <label  class="block text-sm font-medium leading-5 text-gray-700 mt-5">Type organisatie</label>
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="type" v-model="object.companytype">
                    </div>
                </div>
            </div>
            <div class="flex flex-row">
                <div class="w-6/12 pr-5">
                    <label  class="block text-sm font-medium leading-5 text-gray-700 mt-5">Straat</label>
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="straat" v-model="object.street">
                    </div>
                </div>
                <div class="w-6/12">
                    <label  class="block text-sm font-medium leading-5 text-gray-700 mt-5">Huisnummer</label>
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Huisnr" v-model="object.houseNr">
                    </div>
                </div>
            </div>
            <div class="flex flex-row">
                <div class="w-6/12 pr-5">
                    <label  class="block text-sm font-medium leading-5 text-gray-700 mt-5">Busnummer</label>
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Busnr" v-model="object.boxNr">
                    </div>
                </div>
                <div class="w-6/12">
                    <label  class="block text-sm font-medium leading-5 text-gray-700 mt-5">Postcode & Gemeente</label>
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="zip" v-model="object.zip">
                    </div>
                </div>
            </div>
            <div class="flex flex-row">
                <div class="w-6/12 pr-5">   
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center mt-5 gap-2">
                        <div class="w-full ">
                            <label  class="block text-sm font-medium leading-5 text-gray-700 ">Iedereen erbij-charter:</label>
                        </div>
                        <input type="checkbox" class="form-checkbox" v-model="object.charter">
                    </div>
                    <div class="mt-2  rounded-md shadow-sm relative flex flex-row items-center mt-5 gap-2">
                        <div class="w-full">
                            <label class="block text-sm font-medium leading-5 text-gray-700 " for="uptodate">Nieuwsbrief:</label>
                        </div>
                        <input name="uptodate" type="checkbox" class="form-checkbox" v-model="object.uptodate">
                    </div>
                </div>
                <div class="w-6/12">
                    <label  class="block text-sm font-medium leading-5 text-gray-700 mt-5">Opmerking</label>
                    <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                        <textarea v-model="object.extra" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Achternaam" />
                    </div>
                </div>
            </div>
            <br/><br/><br/>
            <div class="flex flex-row">
                <button @click="saveOrg()" class="text-white py-2 px-4 rounded float-right ml-5 cursor-pointer" style="background-color: rgb(32, 23, 71); width: 250px;">Opslaan</button>
                <button @click="delOrg()" class="text-white py-2 px-4 rounded float-right ml-5 cursor-pointer" style="background-color: red; width: 250px;">Verwijder</button>
            </div>
        </div>
    </div>
</template>

<script>
    import env from '@/../env.js';
    import Cookies from '@/helpers/cookies'
    const axios = require('axios');
    export default {
        name: "hvhregistration",
        data: function () {
            return {
                object: {},
                orgType: '',
                env,
            }
        },
        methods: {
            getRegistration: function () {
                const _self = this;
                axios.get(env.apiURL + '/registrations/' + _self.$route.params.id, {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('session')
                    }
                }).then(function (res) {
                    _self.object = res.data.reg;
                    _self.orgType = res.data.orgType;
                });
            },
            savePrivate: function () {
                console.log(env.apiURL + '/registrations/' + this.object._id, this.object);
                axios.put(env.apiURL + '/registrations/private/' + this.object._id, this.object, {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('session')
                    }
                }).then(function () {
                    alert('Saved');
                });
            },
            saveOrg: function () {
                console.log(env.apiURL + '/registrations/' + this.object._id, this.object);
                axios.put(env.apiURL + '/registrations/org/' + this.object._id, this.object, {
                    headers: {
                        'Authorization': 'Bearer ' + Cookies.get('session')
                    }
                }).then(function () {
                    alert('Saved');
                });
            },
            delPrivate: function () {
                if(confirm('Bent u zeker dat u dit wilt verwijderen?')) {
                    axios.delete(env.apiURL + '/registrations/private/' + this.object._id, {
                        headers: {
                            'Authorization': 'Bearer ' + Cookies.get('session')
                        }
                    }).then(function () {
                        location.replace('/#/hvhregistraties')
                    })
                }
            },
            delOrg: function () {
                if(confirm('Bent u zeker dat u dit wilt verwijderen?')) {
                    console.log('go delete it');
                    axios.delete(env.apiURL + '/registrations/org/' + this.object._id, {
                        headers: {
                            'Authorization': 'Bearer ' + Cookies.get('session')
                        }
                    }).then(function () {
                        location.replace('/#/hvhregistraties')
                    })
                }
            },
        },
        mounted() {
            this.getRegistration();
        }
    }
</script>

<style scoped>

</style>
