<template>
  <div class="w-full items-start justify-start flex flex-row">
    <div class="w-full flex flex-col border-b border-gray-300 pb-10">
      <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer" @click="$router.go(-1)">Ga terug</span>
      <h1 class="text-3xl font-semibold">Project: <input class="text-3xl font-semibold" placeholder="Titel" v-model="object.title"></h1>
      <div id="img">
        <img class="p-5" :src="object.image || 'https://cdn.circuitsortie.be/fotos/150.png'" width="200px" style="cursor: pointer;" onclick="document.getElementById('upload_image_input').click();" />
        <p class="text-gray-700 text-sm">Afbeelding URL:
          <input style="width: 750px" class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Image" v-model="object.image">
        <p class="text-gray-700 text-sm">klik op afbeelding om te wijzigen</p>
        <div class="col-lg-4" id="grgQHJgpY">
          <input type="file" accept="image/*" @change="uploadFiles" id="upload_image_input" name="file" style="display: none;" multiple>
          <p v-if="uploaded">Bestand is geüpload</p>
        </div>
      </div>
      <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Beschrijving</label>
      <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
        <textarea rows="1" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Soort beperking" v-model="object.description" style="height: 100px; width: 100px"></textarea>
      </div>
      <div class="w-full mt-5 flex flex-row">
        <div class="flex flex-row">
          <div class="w-6/12">
            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">URL naar project</label>
            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
              <input style="width: 750px" class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Achternaam" v-model="object.url">
            </div><br />
          </div>
        </div>
      </div>
      <div class="flex flex-row">
        <button @click="save()" class="text-white py-2 px-4 rounded float-right ml-5 cursor-pointer" style="background-color: rgb(32, 23, 71); width: 250px;">Opslaan</button>
        <button @click="del()" class="text-white py-2 px-4 rounded float-right ml-5 cursor-pointer" style="background-color: red; width: 250px;">Verwijder</button>
      </div>
    </div>
  </div>
</template>

<script>
import env from "@/../env.js";
import Cookies from "@/helpers/cookies";
const axios = require("axios");

export default {
  name: "project",
  data: function() {
    return {
      object: {},
      env,
      uploaded: false
    };
  },
  methods: {
    getProject: function() {
      const _self = this;
      axios
        .get(env.apiURL + "/projects/" + _self.$route.params.id, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function(res) {
          console.log("Data:", res.data);
          _self.object = res.data;
        });
    },
    save: function() {
      axios
        .put(env.apiURL + "/projects/" + this.object._id, this.object, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function() {
          alert("Saved");
        });
    },
    del: function() {
      axios
        .delete(env.apiURL + "/projects/" + this.object._id, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function() {
          location.replace("/#/projecten");
        });
    },
    sendFile: function(formData) {
      const _self = this;
      return new Promise(function(resolve) {
        fetch(env.apiURL + "/files/upload/project", {
          method: "POST",
          body: formData
        })
          .then(res => res.json())
          .then(res => {
            console.log("Done uploading", res);
            resolve(res.file);
            _self.object.image = res.file;
            console.log(_self.object.image);
          })
          .catch(e => {
            console.error(JSON.stringify(e.message));
            resolve(false);
          });
      });
    },
    uploadFiles: async function(e) {
      let droppedFiles = e.target.files[0];
      console.log(droppedFiles);
      if (!droppedFiles) return;
      if (droppedFiles.length < 1) return;
      let formData = new FormData();
      formData.append("file", droppedFiles);
      await this.sendFile(formData);
      this.uploaded = true;
      this.change();
    }
  },
  mounted() {
    this.getProject();
  }
};
</script>

<style scoped>
</style>
