<template>
  <div id="app" >
    <div v-if="user && user.firstname" class="flex flex-row items-top justify-top relative">
      <Navigation :user="user" class="w-72 h-screen bg-gray-200 flex-grow-0 flex-shrink-0 text-gray-900 fixed left-0 top-0 z-50" />
      <transition name="fade-down" mode="out-in">
        <router-view class="w-full min-h-screen bg-gray-100 flex-grow-0 flex-shrink-0 p-24 pl-96 pt-16"></router-view>
      </transition>
    </div>


    <div v-else>
      <router-view class="w-full min-h-screen bg-gray-100 flex-grow-0 flex-shrink-0"></router-view>

    </div>

  </div>
</template>

<script>
import Navigation from './components/Navigation.vue'
import auth from '@/helpers/auth';


export default {
  name: 'App',
  data: function () {
    return {
      user: {},
    }
  },
  components: {
    Navigation
  },
  async created() {
    // check if the user is loggedin.
    const _self = this; // because in the promise, we cannot use "this"
    this.user = await auth.me().catch(function(){
      if (auth.shouldRedirectToLogin()) {
        _self.$router.replace({ name: "Login"});
      }
    });


  }
}
</script>

<style>



.fade-down-enter-active {
  transition: all .3s cubic-bezier(.62,.01,.41,1);
}
.fade-down-leave-active {
  transition: all .3s cubic-bezier(.62,.01,.41,1);
}
.fade-down-enter, .fade-down-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(7px);
  opacity: 0;
}
</style>
