<template>
  <div>

    <div class="w-full items-start justify-between flex flex-row mt-3">
      <div class="w-full flex flex-col items-start justify-start gap-8">

        <div class="w-full flex flex-row items-center justify-between">

          <div class="w-10/12">
            <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer" @click="$router.go(-1)">Ga terug</span>
            <h1 class="text-3xl font-semibold">{{ total }} Plekjes</h1>
          </div>

          <div class="ml-auto w-7/12 flex flex-row">
            <input type="text" @keyup="load()" class="w-8/12 border-2 border-gray-200 rounded-lg px-4 py-2 text-sm mx-5" placeholder="Zoek een bepaald plekje..." v-model="q">

            <select @change="load()" v-model="category" class="form-input flex-1 block w-4/12 rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="België">
              <option value="">ALL</option>
              <option value="good">Goedgekeurd</option>
              <option value="bad">Afgewezen</option>
              <option value="matched">Gematcht</option>
              <option value="toValidate">Te valideren</option>
              <option value="open">Open (online)</option>
              <option value="removed">Verwijderd</option>
            </select>
            <!-- <button @click="add()" class="text-white py-2 px-4 rounded float-right" style="background-color: rgb(32, 23, 71); width: 25%;">Add </button> -->
          </div>
          <button @click="openPopup()" class="text-white py-2 px-4 rounded flex-2 block ml-5 bg-green-500" style="width: 25%;">Plekje Toevoegen</button>
          <button @click="downloadCSV()" class="text-white py-2 px-4 rounded flex-2 block ml-5" style="background-color: rgb(32,23,71); width: 25%;">Download CSV</button>
        </div>

        <div class="fixed z-10 inset-0 overflow-y-auto" v-if="popup">
          <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity cursor-pointer" @click="closePopup()">
              <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">Voeg plekje toe</h3>

                    <div class="mt-5">
                      <div>
                        <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Waar</p>
                        <input type="text" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%;" v-model="object.where">
                      </div>
                      <div>
                        <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Wat</p>
                        <input type="text" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%;" v-model="object.what">
                      </div>
                      <div>
                        <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Waarom</p>
                        <input type="text" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%;" v-model="object.why">
                      </div>
                      <div>
                        <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Image URL</p>
                        <input type="text" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%;" v-model="object.image">
                      </div>
                      <div class="col-lg-4" id="grgQHJgpY">
                        <img width="150" height="150" id="output" onclick="document.getElementById('upload_image_input').click();" style="cursor: pointer" src="https://cdn.circuitsortie.be/fotos/150.png"><br id="znbLToREc">
                        <input type="file" accept="image/*" @change="uploadFiles" id="upload_image_input" name="file" style="display: none;" multiple>
                        <p v-if="uploaded">Bestand is geüpload</p>
                      </div>
                      <div class="flex flex-row">
                        <div class="w-6/12 pr-5">
                          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Voornaam</label>
                          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                            <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Voornaam" v-model="object.firstname">
                            <!--"-->
                          </div>
                        </div>
                        <div class="w-6/12">
                          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Achternaam</label>
                          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                            <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Achternaam" v-model="object.lastname">
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-row">
                        <div class="w-6/12 pr-5">
                          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">E-mailadres</label>
                          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                            <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email" v-model="object.owner" required>
                          </div>
                        </div>
                        <div class="w-6/12">
                          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Postcode & Woonplaats</label>
                          <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                            <select class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" v-model="object.zip">
                              <option v-for="zip in zipCodes" :key="zip" :value="zip">{{zip}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="flex flex-row">
                          <div class="w-6/12 pr-5">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Geboortejaar</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                              <select class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%;" v-model="object.birthYear">
                                <option v-for="i in 100" v-bind:key="i">{{2016 - i}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="w-6/12">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Telefoonnummer</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                              <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Telefoonnummer" v-model="object.phone">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group text-gray-700">
                        <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Geslacht</p>
                        <input id="gender_male" type="radio" name="gender" value="male" checked="" v-model="object.gender"> <label for="gender_male" class="pointer"> Man</label><br>
                        <input id="gender_female" type="radio" name="gender" value="female" v-model="object.gender"> <label for="gender_female" class="pointer"> Vrouw</label><br>
                        <input id="gender_other" type="radio" name="gender" value="other" v-model="object.gender"> <label for="gender_other" class="pointer"> Andere</label><br>
                      </div>
                      <div class="form-group text-gray-700">
                        <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Beperking</p>
                        <input id="beperking_ja" type="radio" name="beperking" value="true" v-model="object.beperking"> <label for="beperking_ja" class="pointer" required=""> Ja</label><br>
                        <input id="beperking_nee" type="radio" name="beperking" value="false" v-model="object.beperking" checked=""> <label for="beperking_nee" class="pointer" required=""> Nee</label><br>
                      </div>
                      <div id="beperking_questions" v-if="object.beperking && object.beperking.toString() === 'true'">
                        <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Soort beperking</p>
                        <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                          <select class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%;" v-model="object.beperking_type">
                            <option value="Fysieke beperking">Fysieke beperking</option>
                            <option value="Verstandelijke beperking">Verstandelijke beperking</option>
                            <option value="Autismespectrumstoornis">Autismespectrumstoornis</option>
                            <option value="Visuele beperking">Visuele beperking</option>
                            <option value="">Auditieve beperking</option>
                          </select>
                        </div><br />
                        <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Verloopt de communicatie via een contactpersoon? Plaats dan hieronder de contactegegevens:</p>
                        <div class="flex flex-row">
                          <div class="w-6/12 pr-5">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Voornaam begeleider</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                              <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Voornaam" v-model="object.firstname_begeleider">
                              <!--"-->
                            </div>
                          </div>
                          <div class="w-6/12">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Familienaam begeleider</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                              <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Familienaam" v-model="object.lastname_begeleider">
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-row">
                          <div class="w-6/12 pr-5">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">E-mailadres begeleider</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                              <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email" v-model="object.email_begeleider">
                              <!--"-->
                            </div>
                          </div>
                          <div class="w-6/12">
                            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Telefoon begeleider</label>
                            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                              <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Telefoonnummer" v-model="object.phone_begeleider">
                            </div>
                          </div>
                        </div>

                        <div class="form-group" id="wxFeJAJyF">
                          <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">
                            Waar heb je (extra) ondersteuning bij nodig?
                          </label>
                          <div class="flex flex-col mt-2">
                            <select v-model="object.hulp" class="border-2 border-gray-200 rounded-lg px-3 py-2 text-sm">
                              <option :value="undefined">Selecteer optie</option>
                              <option value="Rolstoel duwen">Rolstoel duwen</option>
                              <option value="Bij het eten">Bij het eten</option>
                              <option value="Bij het drinken">Bij het drinken</option>
                              <option value="Naar het toilet gaan">Naar het toilet gaan</option>
                              <option value="Ik ben blind/slechtziend">Ik ben blind/slecthziend</option>
                              <option value="Ik ben doof/slechthorend">Ik ben doof/slechthorend</option>
                              <option value="Ik heb speciaal vervoer nodig">Ik heb speciaal vervoer nodig</option>
                              <option value="Andere">Andere</option>
                            </select>

                            <label for="hulpNotes" class="block text-sm font-medium leading-5 text-gray-700 mt-5">Vertel ons hieronder (indien nodig) wat meer over de ondersteuning die je nodig hebt.</label>
                            <textarea name="hulpNotes" class='form-input my-2 flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300' placeholder='hulp notities' v-model='object.hulpNotes' />
                          </div>
                        </div>

                      </div>
                      <div class="form-group text-gray-700">
                        <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Lid</p>
                        <select class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%;" v-model="object.lid">
                          <option value="neos">Neos</option>
                          <option value="cera">Cera</option>
                          <option value="geen" selected="selected">Geen</option>
                        </select>
                      </div>
                      <div>
                        <p class="text-sm leading-5 font-medium mb-1 text-gray-500">Extra</p>
                        <input type="text" class="border-2 border-gray-200 mb-4 rounded-lg px-3 py-2 text-sm" style="width: 100%;" v-model="object.other">
                      </div>
                      <div>
                        <label> Ja, hou me via e-mail op de hoogte van nieuwe, toffe acties van de Genereuzen.<br>
                          <input type="checkbox" name="newsGen" value="Acties Genereuzen" v-model="object.newsGen"> Zeker aanvinken. Onze database is ons enige instrument om de boodschap van De Genereuzen te verspreiden. <br /> Zo maken we samen de beweging groter. Dank je wel!</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <span v-if="object.owner && object.owner.indexOf('@') > -1 && object.owner.indexOf('.') > -1" class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                  <button type="button" @click="create()" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">Plekje opslaan</button>
                </span>
                <span v-else class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                  <p class="inline-flex justify-center w-full rounded-md  px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700  transition ease-in-out duration-150 sm:text-sm sm:leading-5">E-mailadres is verplicht</p>
                </span>

                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                  <button @click="popup=false;" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full flex flex-col items-stretch justify-start">
          <!--transaction-->
          <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="overflow-hidden border border-gray-200 sm:rounded-lg shadow-md mb-8">
                  <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                    <div class="flex-1 flex justify-between sm:hidden">
                      <a @click="setPage(page - 1)" v-if="page > 0" href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                        Previous
                      </a>
                      <a @click="setPage(page + 1)" href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                        Next
                      </a>
                    </div>
                    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <div>
                        <p class="text-sm text-gray-700">
                          <span class="font-medium">{{start}}</span>
                          van
                          <span class="font-medium">{{start + pageSize}}</span>
                          tot
                          <span class="font-medium">{{total}}</span>
                          resultaten getoond
                        </p>
                      </div>
                      <div>
                        <nav class="relative z-0 inline-flex shadow-sm -space-x-px" aria-label="Pagination">
                          <a @click="setPage(0)" v-if="page > 0" class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                            <span class="sr-only">First</span>
                            <!-- Heroicon name: chevron-double-left -->
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                            </svg>
                          </a>
                          <span class="cursor-pointer" v-for="n in totalPages" :key="n" @click="setPage(n-1)" :class="page === n-1 ? 'relative inline-flex items-center px-4 py-2 border border-gray-300 bg-indigo-200 text-sm font-medium text-gray-700' : 'hidden md:inline-flex relative items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50'">
                            {{n}}
                          </span>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <table class="min-w-full divide-y divide-gray-200 table-fixed">
                    <thead class="bg-gray-100">
                      <tr>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                          Foto
                        </th>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                          Wie
                        </th>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500" style="width: 800px">
                          Waar
                        </th>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                          Status
                        </th>
                        <th class="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500">
                          Aangemaakt op
                        </th>
                        <th class="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-500">
                          Options
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">

                      <!--LOOP THIS-->
                      <tr v-for="plekje in data" v-bind:key="plekje._id">
                        <td class="px-6 py-4 whitespace-no-wrap">
                          <div class="flex items-center">
                            <div>
                              <img :src="plekje.image" alt="img plekje">
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap">
                          <div class="flex items-center">
                            <div class="ml-4">
                              <div class="text-sm leading-5 font-medium text-gray-900">
                                {{plekje.nr}} {{plekje.firstname}} {{plekje.lastname}}
                              </div>
                              <div class="text-sm leading-5 text-gray-500">
                                {{plekje.owner}}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4" style="width: 500px">
                          <div class="flex items-center">
                            <div class="ml-4">
                              <div class="text-sm leading-5 font-medium text-gray-700">
                                {{plekje.what}}
                              </div>
                              <div v-if="plekje.type == 'festival'" class="text-sm leading-5 text-gray-500">
                                {{plekje.festival.name}} {{plekje.festivalDate}}
                              </div>
                              <div v-else class="text-sm leading-5 text-gray-500">
                                {{plekje.where}}
                              </div>
                            </div>
                          </div>
                        </td>

                        <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500 capitalize">
                          Match: {{plekje.hasMatch}} <br />
                          Reactie: {{plekje.hasReply}} <br />
                          Gevalideerd: {{plekje.checked}} <br />
                          Actief: {{plekje.active}} <br />
                        </td>

                        <td class="px-6 py-4 whitespace-no-wrap">
                          <div class="flex items-center">
                            <div class="ml-4">
                              <div class="text-sm leading-5 font-medium text-gray-900">
                                {{formatDate(plekje.createdAt)}}
                              </div>
                              <div class="text-sm leading-5 text-gray-500">
                                {{formatDate(plekje.createdAt)}}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap">
                          <div class="flex items-center">
                            <div class="ml-4">
                              <div v-for="platf in plekje.platforms" v-bind:key="platf" class="text-sm leading-5 text-gray-500">
                                {{platf}}
                              </div>
                            </div>
                          </div>
                        </td>


                        <td class="px-2">
                          <div class="flex flex-row justify-center items-center">

                            <div class="my-auto">
                              <router-link :to="'/plekjes/'+plekje._id" class="bg-blue-500 hover:bg-blue-700 text-white py-1 px-3 rounded">
                                Bekijken
                              </router-link>
                            </div>

                          </div>

                        </td>
                      </tr>

                    </tbody>
                  </table>

                  <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                    <div class="flex-1 flex justify-between sm:hidden">
                      <a @click="setPage(page - 1)" v-if="page > 0" href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                        Previous
                      </a>
                      <a @click="setPage(page + 1)" href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
                        Next
                      </a>
                    </div>
                    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <div>
                        <p class="text-sm text-gray-700">
                          <span class="font-medium">{{start}}</span>
                          van
                          <span class="font-medium">{{start + pageSize}}</span>
                          tot
                          <span class="font-medium">{{total}}</span>
                          resultaten getoond
                        </p>
                      </div>
                      <div>
                        <nav class="relative z-0 inline-flex shadow-sm -space-x-px" aria-label="Pagination">
                          <a @click="setPage(0)" v-if="page > 0" class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                            <span class="sr-only">First</span>
                            <!-- Heroicon name: chevron-double-left -->
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                            </svg>
                          </a>

                          <span class="cursor-pointer" v-for="n in totalPages" :key="n" @click="setPage(n-1)" :class="page === n-1 ? 'relative inline-flex items-center px-4 py-2 border border-gray-300 bg-indigo-200 text-sm font-medium text-gray-700' : 'hidden md:inline-flex relative items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50'">
                            {{n}}
                          </span>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import env from "@/../env.js";
import Cookies from "@/helpers/cookies";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import zipCodes from "@/assets/zipCodes";
import Keep from '@/helpers/keep'
import API from '@/helpers/api'

const axios = require("axios");

export default {
  name: "home",
  data: function() {
    return {
      zipCodes,
      data: [],
      popup: false,
      object: {},
      total: 0,
      totalPages: 0,
      category: "",
      q: "",
      page: 0,
      start: 0,
      pageSize: 25,
      uploaded: false,
      platforms: [],
      env
    };
  },
  watch:{
    page: function(now){ Keep.set('plekjesPage', now) },
    q: function(now){ Keep.set('plekjesQ', now) },
    category: function(now){ Keep.set('plekjesCategory', now) },
    start: function(now){ Keep.set('plekjesStart', now) },
    pageSize: function(now){ Keep.set('plekjesPageSize', now) },
  },
  methods: {
    setPage: function(page) {
      this.page = page;
      this.start = this.pageSize * this.page;
      this.load();
    },
    add: function() {
      this.object = {};
      this.popup = true;
    },
    load: async function() {
      const data = await API.get("/plekjes?q=" + this.q + "&category=" + this.category + "&start=" + this.start + "&pageSize=" + this.pageSize);
      this.data = data.results;
      for (var i = 0; i < data.length; i++) {
        this.data[i].nr = this.start + i + 1;
      }
      this.total = data.metrics.count;
      this.totalPages = Math.ceil(data.metrics.count / this.pageSize);
    },
    approvePlekje: function(plekje) {
      const _self = this;
      plekje.checked = true;
      plekje.active = true;
      axios
        .put(env.apiURL + "/plekjes/" + plekje._id, plekje, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function() {
          _self.changesButNotSaved = false;
          alert("Saved");
        });
    },
    undeletePlekje: function(plekje) {
      console.log("undelete");
      const _self = this;
      plekje.removed = false;
      axios
        .put(env.apiURL + "/plekjes/" + plekje._id, plekje, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function() {
          _self.changesButNotSaved = false;
          alert("Saved");
        });
    },
    deletePlekje: function(plekjeId) {
      const _self = this;
      axios
        .delete(env.apiURL + "/plekjes/" + plekjeId, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function() {
          _self.changesButNotSaved = false;
          _self.load();
          alert("Saved");
        });
    },
    copyPlekje: function(plekje) {
      const _self = this;
      plekje.id = uuidv4();
      axios
        .post(env.apiURL + "/plekjes", plekje, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function(res) {
          _self.data.unshift(res.data);
          _self.popup = false;
          alert("Aangemaakt");
        });
    },
    create: function() {
      this.object.platforms = [];
      if (this.platforms.opt1) this.object.platforms.push("circuitsortie.be");
      if (this.platforms.opt2) this.object.platforms.push("festivals.be");
      const _self = this;
      axios
        .post(env.apiURL + "/plekjes/admin", this.object, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function(res) {
          console.log(res.data);
          _self.data.push(res.data);
          _self.popup = false;
          location.reload();
        });
    },
    formatDate: function(date) {
      const newDate = new Date(date);
      return moment(newDate).format("DD/MM/YYYY HH:mm");
    },
    openPopup: function() {
      this.popup = true;
    },
    uploadFiles: async function(e) {
      let droppedFiles = e.target.files[0];
      console.log(droppedFiles);
      if (!droppedFiles) return;
      if (droppedFiles.length < 1) return;
      let formData = new FormData();
      formData.append("file", droppedFiles);
      await this.sendFile(formData);
      this.uploaded = true;
    },
    sendFile: function(formData) {
      const _self = this;
      return new Promise(function(resolve) {
        fetch(env.apiURL + "/files/upload/plekje", {
          method: "POST",
          body: formData
        })
          .then(res => res.json())
          .then(res => {
            console.log("Done uploading", res);
            resolve(res.file);
            _self.object.image = res.file;
            console.log(_self.object.image);
          })
          .catch(e => {
            console.error(JSON.stringify(e.message));
            resolve(false);
          });
      });
    },
    async downloadCSV() {
      const { data } = await axios.get(
        env.apiURL + "/plekjes/csv?q=" + this.q + "&category=" + this.category,
        {
          responseType: "blob",
          headers: {
            authorization: "Bearer " + Cookies.get("session")
          }
        }
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "plekjes.csv");
      document.body.appendChild(link);
      link.click();
    }
  },
  computed: {},
  async mounted() {

    this.page = Number(Keep.load('plekjesPage', 0));
    this.q = Keep.load('plekjesQ', '');
    this.category = Keep.load('plekjesCategory', '');
    this.start = Number(Keep.load('plekjesStart', 0));
    this.pageSize = Number(Keep.load('plekjesPageSize', 25));

    window.addEventListener('beforeunload', function() {
       Keep.reset('plekjesPage');
       Keep.reset('plekjesQ');
       Keep.reset('plekjesCategory');
       Keep.reset('plekjesStart');
       Keep.reset('plekjesPageSize');
    });



    await this.load();
  }
};
</script>
